import { Component } from 'inferno'
import { safeGet } from 'safe-utils'
import { i18n } from '../i18n'
import { IMediaSelectItem, IMediaSelectDetails, INotificationManager } from '../interfaces/presentation'
import { IApiClient, ISessionManager } from '../interfaces/app'
import { IObjectPrototypeFactory } from 'influence-interfaces/object'
import {
  Button,
  Container,
  Row,
  Col,
  Form
} from 'inferno-bootstrap'

import { Schema, i18n as i18nStr } from 'isomorphic-schema'
import { SearchField } from '../customFields/SearchField'
import '../formlib/SearchField'
  
import { FormRows, widgets, interfaces } from 'inferno-formlib'
import { Progress } from 'inferno-bootstrap'
import { recalcMasonryDimensions, loadMoreMasonry } from '../entities/common/masonry'

import './MediaSelector.scss'

const filterSchema = new Schema('Filter Schema', {
  search: new SearchField({
    placeholder: 'Search Unsplash...'
  })
})

export default class ListPage extends Component {
  
  // Max 29 to allow look ahead by +1 https://unsplash.com/documentation#pagination-headers
  static _limit = 20

  constructor (props) {
    super(...arguments)

    this.state = {
      search: undefined,
      items: [],
      selectedItem: props.initialSelection,
      progress: undefined,
      errMsg: undefined,
      rowGap: 10,
      rowHeight: 10
    }

    this.didGetProgress = this.didGetProgress.bind(this)
    this.doClearError = this.doClearError.bind(this)

    this.didUpdate = this.didUpdate.bind(this)
    this.doSelect = this.doSelect.bind(this)
    this.doDownloadAndChoose = this.doDownloadAndChoose.bind(this)
  }


  _query (page = 1) {
    this._lastLoadedPage = page
    this._hasMoreResults = undefined
    
    if (!this.state.search) {
      return this.setState({
        items: []
      })
    }
    
    const query = {
      search: this.state.search,
      page,
      limit: ListPage._limit + 1 // One more so we can check if there are more images to load
    }
    
    this._queryIsLoading = true
    return new IApiClient().query({
      URI: `/api/unsplash`,
      query
    }).then(({data}) => {
      if (data.length > ListPage._limit) {
        this._hasMoreResults = true
        // Only return this._limit nrof results
        this._queryLastNrofResults = ListPage._limit
        data = data.splice(0, ListPage._limit)
      }
      else {
        this._hasMoreResults = false
        this._queryLastNrofResults = data.length
      }
      
      this.setState({
        items: this._lastLoadedPage === 1 ? data : this.state.items.concat(data)
      })

      // Delay so we don't get insane amounts of reloading
      setTimeout(() => this._queryIsLoading = false, 100)
    })
  }

  doLoadMore = () => {
    if (!this._queryIsLoading && this._hasMoreResults) {
      this._query(this._lastLoadedPage + 1)
    }
  }
 
  didUpdateMasonry = (rowGap, rowHeight, el) => {
    this.setState({
      rowGap,
      rowHeight
    })

    loadMoreMasonry.call(this, el, 100, this.doLoadMore)
  }

  async componentDidMount () {
    await this._query()
    recalcMasonryDimensions.call(this, this._masonryEl, this.didUpdateMasonry)
  }

  didUpdate (propName, value) {
    const state = this.state
    state[propName] = value
    this.setState(state)

    // Throttle calls to search when typing
    if (this._searchTimer) {
      clearTimeout(this._searchTimer)
    }
    this._searchTimer = setTimeout(() => {
      const search = this.state.search
      if (typeof search === 'string' && (search.length >= 3 || search.length === 0)) {
        this._query()
      }
      delete this._searchTimer
    }, 500)
  }

  doSelect (item) {
    if (this.state.selectedItem && item.id === this.state.selectedItem.id) {
      // Toggle select off
      this.setState({
        selectedItem: undefined
      })
    } else {
      this.setState({
        selectedItem: item
      })
    }
  }

  doDownloadAndChoose () {
    const { selectedItem } = this.state
    this.setState({
      isDownloading: true
    })

    this._progressInterval = setInterval(() => {
      const progress = this.state.progress || 0
      const step = (100 - progress) / 20
      const perc = progress + step
      this.didGetProgress(perc)
    }, 250) 

    
    new IApiClient().create({
      URI: `/api/unsplash/${selectedItem.id}`,
      data: selectedItem
    }).then(async ({data}) => {
      // Create the actual Image object which references the image file
      const image = new IObjectPrototypeFactory('Image').getObject(data)
      // Also create a Media object which contains a reference to the Image
      // and also allows tagging and searching in the media library
      const media = new IObjectPrototypeFactory('Media').getObject({
        shortDescription: selectedItem.description,
        fileType: 'image/jpeg',
        image
      })
  
      const { data: mediaObj } = await new IApiClient().create({
        URI: '/content/Media',
        data: media,
        invalidate: '/content/Media'
      })
      // Delay so we don't get insane amounts of reloading
      // setTimeout(() => this._queryIsLoading = false, 100)
      clearInterval(this._progressInterval)
      this._progressInterval = undefined
      this.setState({
        isDownloading: false,
        progress: 100
      })

      // Pass the selected image to editor
      setTimeout(() => {
        this.props.onSelect(mediaObj)
      }, 250)
    })
  }

  doClearError (e) {
    e.preventDefault()
    this.setState({
      progress: undefined,
      errMsg: undefined
    })
  }

  didGetProgress (perc) {
    this.setState({
      progress: perc,
      errMsg: undefined
    })
  }

  renderThumbnailsCol () {
    return (
        <div className="masonry-container col col-md-9 MediaSelector-ThumbnailContainer" ref={(el) => this._masonryEl = el}>
          {this.state.items.map((item) => {
            const isSelected = this.state.selectedItem ? item._id === this.state.selectedItem._id : false
            const ListItem = new IMediaSelectItem(item).Component
            return <ListItem key={item._id}
              context={item}
              rowGap={this.state.rowGap}
              rowHeight={this.state.rowHeight}
              isSelected={isSelected}
              onSelect={this.doSelect} />
          })}
          {this.state.items.length === 0 && <p className="MediaSelector-Hint">Type in search box</p>}
        </div>
    )
  }

  renderDetailsCol () {
    if (!this.state.selectedItem) {
      return (
        <Col md="3" className="MediaSelector-ThumbnailDetails no-selection">
          <h2>No Image Selected</h2>
          <p>Use the search box to find images on Unsplash. Select and download the image to store it in your media library.</p>
        </Col>
      )
    } else {
      const { selectedItem } = this.state
      const MediaDetails = new IMediaSelectDetails(selectedItem).Component
      return (
        <Col md="3" className="MediaSelector-ThumbnailDetails">
          <MediaDetails context={selectedItem}>
            <Button size="sm" color="primary" onClick={this.doDownloadAndChoose}>Select and download</Button>
          </MediaDetails>
        </Col>
      )
    }
  }

  renderDownloadOverlay () {
    if (!this.state.isDownloading) {
      return null
    }

    return (
      <div className="MediaSelector-DownloadOverlay">
        <p className="MediaSelector-Hint">Downloading image to your media library</p>
      </div>
    )

  }

  render () {
    return (
      <Container fluid>
        {(this.state.progress !== undefined) && <Progress className="MediaSelector-Progress" value={this.state.progress} />}
        <Row className="MediaSelector-Filter">
          <Col>
            <Form onSubmit={(e) => e.preventDefault()}>
              <FormRows schema={filterSchema} value={this.state} onChange={this.didUpdate} />
            </Form>
          </Col>
        </Row>
        <Row className="MediaSelector-List">
          {this.renderThumbnailsCol()}
          {this.renderDetailsCol()}
        </Row>
        {this.renderDownloadOverlay()}
      </Container>
    )
  }
}