/*eslint no-template-curly-in-string: "off"*/
export const en = {
  'isomorphic-schema--field_required': 'Required',
  'isomorphic-schema--text_field_no_string': 'The field doesn\'t contain text',
  'isomorphic-schema--text_field_too_short': 'The text is too short. Min ${minLength} chars.',
  'isomorphic-schema--text_field_too_long': 'The text is too long. Max ${maxLength} chars.',
  'isomorphic-schema--text_area_field_no_string': 'The field doesn\'t contain text',
  'isomorphic-schema--integer_field_not_number': 'The field doesn\'t contain numbers',
  'isomorphic-schema--integer_field_no_decimals': 'The field may not contain decimals',
  'isomorphic-schema--integer_field_too_small': 'The value is too small. Min ${minValue}',
  'isomorphic-schema--integer_field_too_big': 'The value is too big. Max ${maxValue}',
  'isomorphic-schema--decimal_field_not_number': 'The field doesn\'t contain numbers',
  'isomorphic-schema--decimal_field_too_small': 'The value is too small. Min ${minValue}',
  'isomorphic-schema--decimal_field_too_big': 'The value is too big. Max ${maxValue}',
  'isomorphic-schema--credit_card_field_not_supported': 'Entered card type is not supported',
  'isomorphic-schema--credit_card_field_incorrect_formatting': 'The card number is incorrectly entered',
  'isomorphic-schema--date_field_incorrect_formatting': 'This doesn\'t look like a date',
  'isomorphic-schema--date_time_field_incorrect_formatting': 'This doesn\'t look like a date with time',
  'isomorphic-schema--email_field_incorrect_formatting': 'This is not a valid e-mail address',
  'isomorphic-schema--list_field_type_error': 'This is not proper list. This is a bug in the application',
  'isomorphic-schema--list_field_value_error_too_many_items': 'Too many items in list, max ${maxItems} allowed',
  'isomorphic-schema--list_field_value_error_too_few_items': 'Too few items in list, min ${minItems} allowed',
  'isomorphic-schema--list_field_value_error': 'There is an error in the content of this list',
  'isomorphic-schema--multi_select_field_value_error': 'One or more of the selected values is not allowed',
  'isomorphic-schema--object_field_value_error': 'There is an error in the content of this object',
  'isomorphic-schema--org_nr_field_incorrect_formatting': 'Malformatted',
  'isomorphic-schema--org_nr_field_too_short': 'Entered number is too short',
  'isomorphic-schema--org_nr_field_wrong_checksum': 'The entered number is incorrect (checksum error)',
  'isomorphic-schema--password_field_too_short': 'The password must contain at least 8 chars',
  'isomorphic-schema--select_field_value_error': 'The selected value is not allowed',
  
  'inferno-formlib--ListField_remove': 'Remove',
  'inferno-formlib--ListField_add': 'Add',

  'inferno-formlib--DateField': 'Date',
  'inferno-formlib--DateTimeField': 'Date and Time',
  'inferno-formlib--DateField-months': 'januari_februari_mars_april_may_june_july_august_september_october_november_december',
  'inferno-formlib--DateField-weekdays': 'su_mo_tu_we_th_fr_sa',
  'inferno-formlib--DateField-select_date': 'Select a date',
  'inferno-formlib--DateField-clear': 'clear',

  'ImageField-placeholder': 'Click or drop to upload...',

  'IUser-accounts-label': 'Connected accounts',
  'IUser-accountsEmail-label': 'E-mail account',
  'IUser-roles-label': 'System Roles',
  'IUser-roles-option-admin': 'Admin',
  'IUser-roles-option-customerService': 'Community Manager',
  'IUser-roles-option-member': 'Member',
  'IUser-avatarUrl-label': 'Profile image',
  'IUser-avatarUrl-placeholder': 'Click or drop to upload...',
  'IUser-avatarUrl-help': 'This image will be visible to all users. Only JPEG or PNG. Max 20Mb.',
  'IUser-firstName-label': 'First name',
  'IUser-lastName-label': 'Last name',
  'IUser-activeInfluencer-label': 'Active as influencer',
  'IUser-activeInfluencer-help': 'You can uncheck this box if you do not want to be approached for influencer opportunities',
  'IUser-influencer-label': 'Influencer',
  'IUser-city-label': 'City',
  'IUser-occupation-label': 'Occupation',
  'IUser-birthDate-label': 'Birthday',
  'IUser-birthDate-placeholder': 'yyyy-mm-dd',
  'IUser-gender-label': 'Gender',
  'IUser-option-male': 'Male',
  'IUser-option-female': 'Female',
  'IUser-option-other': 'Other',
  'IUser-mainBlogName-label': 'Blog Name',
  'IUser-mainBlogId-label': 'Blog ID',

  'IAccountInstagram-useForLogin-label': 'Use for login',
  'IAccountInstagram-accessToken-label': 'Instagram Access Token',
  'IAccountInstagram-user-label': 'Instagram Username',
  'IAccountInstagram-userId-label': 'Instagram User ID (Deprecated)',

  'IAccountFacebook-useForLogin-label': 'Use for login',
  'IAccountFacebook-accessToken-label': 'Facebook Access Token',
  'IAccountFacebook-user-label': 'Basic Profile',
  'IAccountFacebook-userId-label': 'Facebook User ID (Deprecated)',

  'IAccountEmail-email-label': 'Email',
  'IAccountEmail-password-label': 'Password',
  'IAccountEmail-password-help': 'Confirm password',
  'IAccountEmail-useForLogin-label': 'Use for login',

  'IPage-title-placeholder': 'Type your title here...',

  'ILicenseAgreement-version-label': 'Version',
  'ILicenseAgreement-version-placeholder': 'Semver i.e. #.#.#',

  'PrincipalRoleSchema-principalId-label': 'User',
  'PrincipalRoleSchema-role-label': 'Role',
  'PrincipalRoleSchema-role-option-admin': 'Admin',
  'PrincipalRoleSchema-role-option-editor': 'Editor',
  'PrincipalRoleSchema-role-option-writer': 'Writer',
  
  'filterForm-all-label': 'All',
  'profileFormSchema-blog-label': 'Blog',
  'profileFormSchema-user-label': 'Profile',
  'profileFormSchema-isPublic-label': 'Make blog public',

  'TopMenu-login': 'login',
  'TopMenu-contact': 'contact',
  'AdminMenu-admin': 'Admin',
  'AdminMenu-content': 'Content',

  'IWorkflowFilter-all-label': 'All',
  'IPublishWorkflow-option-draft': 'Draft',
  'IPublishWorkflow-option-published': 'Published',
  'IPublishWorkflow-option-trash': 'Trash',
  'WorkflowAction-to': 'to ',
  'WorkflowAction-updateStatus': 'Change Status',

  'MainMenu-logout': 'Logout',
  'MainMenu-login': 'Login',
  
  'AdminTypeMenu-Dashboard': 'Dashboard',
  'AdminTypeMenu-Order': 'Orders',
  'AdminTypeMenu-PromoCode': 'Promo Codes',
  'AdminTypeMenu-Page': 'Pages',
  'AdminTypeMenu-LicenseAgreement': 'License Agreement',
  'AdminTypeMenu-User': 'Users',
  'AdminTypeMenu-Invitation': 'Invitations',
  'AdminTypeMenu-NameDay': 'Name Day',

  'FilterSchema-search-placeholder': 'Search...',
  
  'ListAction-createInvitation': '+ Create a new invitation',
  'ListAction-createUser': '+ Create a new user',
  'ListAction-createOrder': '+ Create a new order',
  'ListAction-createLicenseAgreement': '+ Create new version',
  'ListAction-createNameDay': '+ Create new name day',
  'ListAction-import': 'Import',

  // These should probably all be prefixed with TimedPublishing- instead of IPage
  'IPage-publishingStartsAt-label': 'Publish start:',
  'IPage-publishingEndsAt-label': 'Publish end:',
  'TimedPublishing-formHeader': 'Timed publishing',

  'ICard-title-label': 'Title',
  'ICard-title-placeholder': 'Type title here...',
  'ICard-images-label': 'Images',
  'ICard-images-valueType-placeholder': 'Click or drop to upload...',
  'ICard-layoutType-label': 'Layout Type',
  'ICard-layoutType-option-one': 'Single',
  'ICard-layoutType-option-two': 'Two, side by side',
  'ICard-layoutType-option-one_two': 'One on top, two underneath',
  'ICard-layoutType-option-one_three': 'One on top, three underneath',
  'ICard-layoutType-option-one_two_horizontal': 'Three, one on left',
  'ICard-layoutType-option-one_four_horizontal': 'Five, one on left',
  'ICard-allowCrop-label': 'Allow cropping to balance images',
  'ICard-shortDescription-label': 'Short Description',
  'ICard-image-label': 'Image',
  'ICard-image-help': 'Only JPEG or PNG. Max 20Mb.',
  'ICard-image-placeholder': 'Click or drop to upload...',

  'IMediaCard-shortDescription-label': 'Caption',

  'IInvitation-firstName-label': 'First Name',
  'IInvitation-lastName-label': 'Last Name',
  'IInvitation-instagramUserName-label': 'Instagram Username',
  'IInvitation-websiteUrl-label': 'Website',
  'IInvitation-email-label': 'E-mail',

  'IInvitationWorkflow-option-draft': 'Draft',
  'IInvitationWorkflow-option-pending': 'Pending',
  'IInvitationWorkflow-option-activated': 'Activated',
  'IInvitationWorkflow-option-trash': 'Trash',

  'IUserWorkflow-option-active': 'Active',
  'IUserWorkflow-option-investigate': 'Investigate',
  'IUserWorkflow-option-suspended': 'Suspended',
  'IUserWorkflow-option-pendingDelete': 'To Be Delete',

  'INameDay-title-label': 'Name',
  'INameDay-title-placeholder': 'Type name here...',
  'INameDay-startDate-label': 'Start Date',
  'INameDay-date-placeholder': 'Select a date',
  'INameDay-endDate-label': 'End Date'

}