"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.IImage = void 0;

var _componentRegistry = require("component-registry");

var _isomorphicSchema = require("isomorphic-schema");

var Interface = (0, _componentRegistry.createInterfaceClass)('influence-app-entities');
var fileInstanceSchema = new _isomorphicSchema.Schema('File Instance Schema', {
  width: new _isomorphicSchema.IntegerField({}),
  height: new _isomorphicSchema.IntegerField({}),
  imagePath: new _isomorphicSchema.TextField({})
});
var IImage = new Interface({
  name: 'IImage',
  schema: new _isomorphicSchema.Schema('Image Schema', {
    rawImage: new _isomorphicSchema.ObjectField({
      schema: fileInstanceSchema
    }),
    fileType: new _isomorphicSchema.TextField({
      label: (0, _isomorphicSchema.i18n)('IImage-fileType-label', 'Type') // readOnly: true

    }),
    rendered: new _isomorphicSchema.ListField({
      label: (0, _isomorphicSchema.i18n)('IImage-rendered-label', 'Rendered Images'),
      valueType: new _isomorphicSchema.ObjectField({
        schema: fileInstanceSchema
      })
    }),
    thumbnails: new _isomorphicSchema.ListField({
      label: (0, _isomorphicSchema.i18n)('IImage-thumbnails-label', 'Thumbnail Images'),
      valueType: new _isomorphicSchema.ObjectField({
        schema: fileInstanceSchema
      })
    })
  })
});
exports.IImage = IImage;