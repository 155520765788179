import { createInterfaceClass } from 'component-registry'
const Interface = createInterfaceClass('influence-app')

export const IListItem = new Interface({
  name: 'IListItem'
})
IListItem.prototype.Component = function () {}

export const ILiveListItem = new Interface({
  name: 'ILiveListItem'
})
ILiveListItem.prototype.Component = function () {}

export const ILightbox = new Interface({
  name: 'ILightbox'
})
ILightbox.prototype.Component = function () {}

export const IRelatedItem = new Interface({
  name: 'IRelatedItem'
})
IRelatedItem.prototype.Component = function () {}

export const ILiveItem = new Interface({
  name: 'ILiveItem'
})
ILiveItem.prototype.Component = function () {}

export const IRelationFieldItem = new Interface({
  name: 'IRelationFieldItem'
})
IRelationFieldItem.prototype.Component = function () {}

export const IImageHelper = new Interface({
  name: 'IImageHelper'
})
IImageHelper.prototype.getSrcSet = function (maxWidth, maxHeight) {}
IImageHelper.prototype.getThumbSrcSet = function (maxWidth, maxHeight) {}

export const IMemberOpportunityListItem = new Interface({
  name: 'IMemberOpportunityListItem'
})

export const IMediaSelectItem = new Interface({
  name: 'IMediaSelectItem'
})

export const IMediaSelectDetails = new Interface({
  name: 'IMediaSelectDetails'
})

export const ICreateForm = new Interface({
  name: 'ICreateForm'
})
ICreateForm.prototype.Component = function () {}

export const IEditForm = new Interface({
  name: 'IEditForm'
})
IEditForm.prototype.Component = function () {}

export const IImportForm = new Interface({
  name: 'IImportForm'
})
IImportForm.prototype.Component = function () {}

export const IViewForm = new Interface({
  name: 'IViewForm'
})
IViewForm.prototype.Component = function () {}

export const IViewContent = new Interface({
  name: 'IViewContent'
})
IViewContent.prototype.Component = function () {}

export const IContextMenu = new Interface({
  name: 'IContextMenu'
})

export const INotificationManager = new Interface({
  name: 'INotificationManager'
})