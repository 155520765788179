import { Adapter, globalRegistry } from 'component-registry'
import { IWorkflowFilterField } from 'influence-app-entities/lib/interfaces/fields'
import WorkflowFilterFieldWidget from './WorkflowFilterFieldWidget'
import { interfaces } from 'inferno-formlib'
const { IInputFieldWidget } = interfaces

new Adapter({
  implements: IInputFieldWidget,
  adapts: IWorkflowFilterField,
  Component: WorkflowFilterFieldWidget
})

export {
  IWorkflowFilterField, 
  WorkflowFilterFieldWidget
}