import { createObjectPrototype, Utility } from 'component-registry';
import { Permissions } from 'influence-permissions/dist/permissions';
import { PublishWorkflow } from 'influence-app-entities/dist/entities/workflows/PublishWorkflow';
import { INameDay } from '../interfaces/NameDay';
import { IObjectPrototypeFactory } from 'influence-interfaces/object';
var _permissions = {
  create: ['admin', 'customerService'],
  read: ['owner', 'admin', 'customerService', 'anonymous:publishWorkflow.published'],
  update: ['owner', 'admin'],
  delete: ['owner', 'admin']
};
var NameDay = createObjectPrototype({
  implements: [INameDay],
  extends: [Permissions, PublishWorkflow],
  constructor: function constructor(params) {
    this._type = 'NameDay';

    this._IPermissions.constructor.call(this, params, _permissions);

    this._IPublishWorkflow.constructor.call(this, params, {
      defaultState: 'draft'
    });
  }
});
export default NameDay;
var ObjectPrototypeFactory = new Utility({
  implements: IObjectPrototypeFactory,
  name: 'NameDay',
  getPrototype: function getPrototype() {
    return NameDay;
  },
  getObject: function getObject(data, roleManager) {
    data = data || {};
    return new NameDay(data);
  }
});