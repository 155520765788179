import MultiImageIcon from '../img/icons/multi-image.js'
import './ResponsiveImage.scss'
import {
  Image
} from 'inferno-bootstrap'
import { i18n } from '../i18n'
import classnames from 'classnames'
import { IImageHelper } from '../interfaces/presentation'

let _env_ = (typeof window !== 'undefined' && window.__env__ ? window.__env__ : process.env )
let { IMAGE_URI_BASE } = _env_
IMAGE_URI_BASE = IMAGE_URI_BASE || '//localhost:3021/images'

/* Legacy code */
function renderSrc(src) {
  if (typeof src !== 'string') {
    return ''
  }
  else if (src.indexOf('//') === 0 || src.indexOf('http') === 0) {
    return src
  }
  else if (src.indexOf('/images') === 0) {
    // This is only needed for dev images that are stored /images
    return IMAGE_URI_BASE.replace('/images', '') + src
  } else {
    return IMAGE_URI_BASE + src
  }
}

function isMultiple(multiple, images) {
  return (
    typeof multiple === 'undefined'
    ? Array.isArray(images) && images.length > 1
    : multiple
  )
}
/* end legacy */

function Thumbnail ({className, image, imageUrl, multiple, images, imageUrls, onClick}) {
  image = image || imageUrl
  images = images || imageUrls
  multiple = isMultiple(multiple, images)

  try {
    if (!multiple && image && image.thumbnails) {
      const imgHlpr = new IImageHelper(image)
      const { width, height } = image.thumbnails[0]
      const size = {} // { width, height }
      return <Image {...size} className={classnames("thumbnail", className)}
        src={imgHlpr.getThumbSrc()}
        srcSet={imgHlpr.getThumbSrcSet()}
        onClick={onClick} />
    }
    else if (images && images[0] && images[0].hasOwnProperty('rawImage') === true) {
      const imgHlpr = new IImageHelper(images[0])
      const { width, height } = images[0].thumbnails
      const size = {} // { width, height }
      return  <div className={classnames("thumbnail", className)} onClick={onClick}>
        <Image {...size} className="thumbnail"
          src={imgHlpr.getThumbSrc()}
          srcSet={imgHlpr.getThumbSrcSet()} />
        {multiple && <MultiImageIcon className="icon" />}
      </div>
    }
  }
  catch (e) {
    console.log(e)
    return <Image src="Broken image object" />
  }
}

function ResponsiveImage ({className, image, imageUrl, multiple, limit, images, imageUrls, thumbnail, maxWidth, onClick}) {
  image = image || imageUrl
  images = images || imageUrls
  multiple = isMultiple(multiple, images)

  try {

    if (!multiple && image && image.rendered) {
      const imgHlpr = new IImageHelper(image)
      const { width, height } = image.rendered[0]
      const size = {} // { width, height }
      return <Image {...size} className={className}
        src={imgHlpr.getSrc(maxWidth)}
        srcSet={imgHlpr.getSrcSet(maxWidth)}
        onClick={onClick} />
    }
    else if (thumbnail) {
      const imgObj = images[0]
      const imgHlpr = new IImageHelper(imgObj)
      const { width, height } = imgObj.rendered[0]
      const size = {} // { width, height }
      return (
        <div className={classnames("thumbnail", className)} onClick={onClick}>
          <Image {...size} className="thumbnail"
            src={imgHlpr.getSrc(maxWidth)}
            srcSet={imgHlpr.getSrcSet(maxWidth)} />
            {multiple && <MultiImageIcon className="icon" />}
        </div>
      )
    }
    else if (Array.isArray(images) && images[0].hasOwnProperty('rawImage') === true) {
      let showImages = images.filter(img => img)
      let notShown = 0
      if (limit < images.length) {
        notShown = images.length - limit
        showImages = showImages.slice(0, limit)
      }

      return showImages.map((imgObj, i, arr) => {
        const imgHlpr = new IImageHelper(imgObj)
        const { width, height } = imgObj.rendered[0]
        const size = {} // { width, height }
        if (notShown > 0 && i === limit - 1) {
          return (
            <i className="image" onClick={onClick}>
              <Image {...size} className={classnames(className)}
                  src={imgHlpr.getSrc(maxWidth)}
                  srcSet={imgHlpr.getSrcSet(maxWidth)}
                  onClick={onClick} />
              <i>+{notShown}</i>
            </i>
          )
        }
        else {
          return (
            <i className="image" onClick={onClick}>
              <Image {...size} className={classnames(className)}
                src={imgHlpr.getSrc(maxWidth)}
                srcSet={imgHlpr.getSrcSet(maxWidth)}
                onClick={onClick} />
            </i>
          )
        }
      })
    }
  }
  catch (e) {
    console.log(e)
    return <Image src="Broken image object (Error thrown on render, set breakpoint in ResponsiveImage.js to debug)" />
  }
}

export {
  Thumbnail,
  ResponsiveImage
}