import {Component, createComponentVNode, createFragment, VNode} from 'inferno';
import {matchPath} from 'inferno-router';
import {combineFrom, isArray, isInvalid} from 'inferno-shared';

function warning(condition, message) {
  if (!condition) {
    // tslint:disable-next-line:no-console
    console.error(message);
  }
}

function invariant(condition, format, a, b, c, d, e, f) {
  if (!condition) {
    let error;
    if (format === undefined) {
      error = new Error('Minified exception occurred; use the non-minified dev environment ' + 'for the full error message and additional helpful warnings.');
    } else {
      const args = [a, b, c, d, e, f];
      let argIndex = 0;
      error = new Error(
        format.replace(/%s/g, function() {
          return args[argIndex++];
        })
      );
      error.name = 'Invariant Violation';
    }

    error.framesToPop = 1; // we don't care about invariant's own frame
    throw error;
  }
}

function getMatch({ path, exact, strict, sensitive, from }, route, location) {
  const pathProp = path || from;

  return pathProp ? matchPath(location.pathname, { path: pathProp, exact, strict, sensitive }) : route.match;
}

function extractMatchFromChildren(children, route, location) {
  let matchArr = [];
  let match;
  let _child;

  if (isArray(children)) {
    for (let i = 0; i < children.length; ++i) {
      _child = children[i];

      // Don't include redirects unless no matches
      if (matchArr.length > 0 && _child.props.to) continue

      if (isArray(_child)) {
        const nestedMatch = extractMatchFromChildren(_child, route, location);
        if (Array.isArray(nestedMatch)) {
          matchArr.concat(nestedMatch)
        }
      } else {
        match = getMatch(_child.props, route, location);
        if (match) matchArr.push({ match, _child })
      }
      if (matchArr.length > 0 && matchArr[matchArr.length -1].match.isExact && matchArr[matchArr.length -1]._child.props.exact) {
        // We have an exact match for a route that is marked "exact". This means we stop looking for any more
        // matching routes,
        return matchArr
      }
    }
  } else {
    match = getMatch((children).props, route, location);
    _child = children;
    if (match) matchArr.push({ match, _child })
  }
  
  return matchArr;
}

export class LayeredSwitch extends Component {
  render() {
    const { route } = this.context.router;
    const { children } = this.props;
    const location = this.props.location || route.location;

    if (isInvalid(children)) {
      return null;
    }

    const matchArr = extractMatchFromChildren(children, route, location);

    if (matchArr.length > 0) {
      const outp = []

      let asyncBeforeIndex = 0
      for (let i = 0; i < matchArr.length; i++) {
        let { match, _child } = matchArr[i]

        // Get data passed from data fetchers and apply as props
        let asyncBeforeData
        if (_child.props.asyncBefore && Array.isArray(this.context.asyncBeforeData)) {
          asyncBeforeData = this.context.asyncBeforeData[asyncBeforeIndex++]
        }

        outp.push(createComponentVNode(_child.flags, _child.type, combineFrom(_child.props, { location, computedMatch: match, asyncBeforeData })))
      }
      //return createFragment(outp);
      return createFragment(outp, 0 /* UnknownChildren */, null);
      // return outp;
    }

    return null;
  }
}

if (process.env.NODE_ENV !== 'production') {
  LayeredSwitch.prototype.componentWillMount = function() {
    invariant(this.context.router, 'You should not use <LayeredSwitch> outside a <Router>');
  };

  LayeredSwitch.prototype.componentWillReceiveProps = function(nextProps) {
    warning(
      !(nextProps.location && !this.props.location),
      '<LayeredSwitch> elements should not change from uncontrolled to controlled (or vice versa). You initially used no "location" prop and then provided one on a subsequent render.'
    );

    warning(
      !(!nextProps.location && this.props.location),
      '<LayeredSwitch> elements should not change from controlled to uncontrolled (or vice versa). You provided a "location" prop initially but omitted it on a subsequent render.'
    );
  };
}