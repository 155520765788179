export default function (props) {
  // Dropshadow https://stackoverflow.com/questions/6088409/svg-drop-shadow-using-css3
  return (
    <svg width="109px" height="109px" viewBox="0 0 109 109" {...props}>
        <filter id="dropshadow" height="130%">
          <feGaussianBlur in="SourceAlpha" stdDeviation="3"/>
          <feOffset dx="1" dy="1" result="offsetblur"/>
          <feComponentTransfer>
            <feFuncA type="linear" slope="0.5"/>
          </feComponentTransfer>
          <feMerge> 
            <feMergeNode/>
            <feMergeNode in="SourceGraphic"/>
          </feMerge>
        </filter>
        <g id="Modules" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Video-player" transform="translate(5.000000, 5.000000)" fill="currentColor">
                <g id="video_play">
                    <circle id="OvalShadow" fill-opacity="0.5" cx="49.5" cy="49.5" r="49.5" style="filter:url(#dropshadow)"></circle>
                    <circle id="Oval" fill-opacity="0.4" cx="49.5" cy="49.5" r="49.5"></circle>
                    <polygon id="Triangle" transform="translate(55.500000, 50.000000) rotate(90.000000) translate(-55.500000, -50.000000) " points="55.5 35 78 65 33 65"></polygon>
                </g>
            </g>
        </g>
    </svg>
  )
}