import { Adapter } from 'component-registry'
import { Component } from 'inferno'
import { i18n } from '../../i18n'

import { FormRows, widgets } from 'inferno-formlib'
//import 'inferno-formlib/lib/widgets/FormRow'
//import 'inferno-formlib/lib/widgets/InputField'
import '../../formlib/ImageField'

import {
  Button,
  Col,
  Row,
  Form
} from 'inferno-bootstrap'

import { IObjectPrototypeFactory } from 'influence-interfaces/object'
import { IMedia } from 'influence-app-entities/lib/interfaces/Media'
import { IEditForm, INotificationManager } from '../../interfaces/presentation'
import { IApiClient, ISessionManager } from '../../interfaces/app'
import {  } from '../../interfaces/app'
import '../../pages/admin/Create.scss'

const { ActionBar } = widgets

class EditForm extends Component {

    constructor (props, context) {
        super(...arguments)

        this.state = {
            value: props.value || new IObjectPrototypeFactory('Media').getObject(undefined),
            validationError: undefined,
            actionBarBoundary: {top: 0, bottom: 0}
        }

        this.didUpdate = this.didUpdate.bind(this)
        this.doSubmit = this.doSubmit.bind(this)
    }

    componentDidMount () {
        
    }

    didUpdate (propName, value) {
        const newVal = this.state.value
        newVal[propName] = value

        this.setState({
            value: newVal
        })
    }

    doSubmit (e) {
        e.preventDefault()

        if (this.state.value._id) {
            new IApiClient().update({
                URI: `/content/Media/${this.state.value._id}`,
                data: this.state.value,
                invalidate: `/content/Media`
            }).then(({data}) => {
                // TODO: Show success indicator at center of screen
                new INotificationManager().showSuccessMessage()
            })
        } else {
            new IApiClient().create({
                URI: `/content/Media`,
                data: this.state.value,
                invalidate: `/content/Media`
            }).then(({data}) => {
                // TODO: Show success indicator at center of screen
                new INotificationManager().showSuccessMessage()
                this.context.router.history.push(`/admin/Media/${data._id}`)
            })
        }
    }

    render () {
        return (
            <div ref={(e) => this._containerEl = e}>
                {this.state.modalWidget}
                <Form onSubmit={this.doSubmit} className='IEditItem'>

                    <FormRows schema={IMedia.schema} validationErrors={this.state.validationError} value={this.state.value} onChange={this.didUpdate} />

                    <ActionBar boundary={this.state.actionBarBoundary}>
                        <Row col>
                            <Button color="success" type="submit">Save</Button>
                        </Row>
                    </ActionBar>
                </Form>
            </div>
        )
    }
}

new Adapter({
  implements: IEditForm,
  adapts: IMedia,
  Component: EditForm
})
