import { Component, Fragment } from 'inferno'
import { globalRegistry } from 'component-registry'
import { i18n } from '../../i18n'

import { widgets, renderString } from 'inferno-formlib'
import {  } from 'inferno-formlib'
// import 'inferno-formlib/lib/widgets/FormRow'
import { SelectMediaWidget } from './SelectMediaWidget'
import { ResponsiveImage } from '../../widgets/ResponsiveImage'

import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardImg
} from 'inferno-bootstrap'
import './ImageFieldWidget.scss'

const { FileUploadWidget } = widgets

export default class ImageFieldWidget extends Component {
  constructor (props) {
      super(props)

      this.state = {
          value: props.value,
          showModal: false
      }

      this.doShowMediaSelector = this.doShowMediaSelector.bind(this)
      this.doHideMediaSelector = this.doHideMediaSelector.bind(this)
      this.didSelectMedia = this.didSelectMedia.bind(this)
      this.didGetChange = this.didGetChange.bind(this)
      this.doClearImage = this.doClearImage.bind(this)
  }

  componentWillReceiveProps (nextProps) {
      this.setState({
          value: nextProps.value
      })
  }

  doShowMediaSelector (e) {
    e && e.preventDefault()
    this.setState({
      showModal: true
    })
  }

  doHideMediaSelector () {
    this.setState({
      showModal: false
    })
  }

  didSelectMedia (media) {
    this.didGetChange(undefined, media)
  }

  doClearImage (e) {
    e.preventDefault()
    this.setState({
        value: undefined
    })
    this.props.onChange(this.props.propName, undefined)
  }

  didGetChange (propName, data) {
    const field = this.props.adapter.context
    this.setState({
      value: field.fromString(data)
    })
    this.props.onChange(this.props.propName, field.fromString(data.image))
  }

  renderImage () {
    const isImage = this.props.value.hasOwnProperty('imagePath')
    return (
        <Card className="ImageField">
            <ResponsiveImage className="card-img"  width="100%" image={this.props.value} />
            <CardBody>
                <CardFooter>
                    <a className="ImageField-Action text-danger" href="#clear" onClick={this.doClearImage} >Clear</a>
                </CardFooter>
            </CardBody>
        </Card>
    )
  }

  renderUpload () {
    const field = this.props.adapter.context
    const isValid = this.props.validationError ? false : undefined
    
    return (
      <div className="InfernoFormlib-ImageFieldWidget">
        <FileUploadWidget
          id={this.props.namespace.join(".") + "__Field"}
          name={this.props.inputName}
          valid={isValid}
          placeholder={renderString(field.placeholder)}
          readOnly={field.readOnly}
          value={field.toFormattedString(this.state.value)}
          uploadUtilName={field.uploadUtilName}

          // onClick={this.doTriggerUpload}
          onChange={this.didGetChange} />
        <Button color="link" size="sm" onClick={this.doShowMediaSelector}>Open media library...</Button>
      </div>
    )
  }

  render () {
    return (
      <Fragment>
        {!this.props.value && this.renderUpload()}
        {this.props.value && this.renderImage()}
        <SelectMediaWidget
          value={this.state.value}
          isOpen={this.state.showModal}
          onClose={this.doHideMediaSelector}
          onSelect={this.didSelectMedia} />
      </Fragment>
    )
  }
}