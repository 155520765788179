import { createInterfaceClass } from 'component-registry';
var Interface = createInterfaceClass('influence-app-entities');
import { Schema, TextField, DateTimeField, i18n } from 'isomorphic-schema';
var IPage = new Interface({
  name: 'IPage',
  schema: new Schema('Page Schema', {
    title: new TextField({
      label: i18n('IPage-title-label', 'Title'),
      placeholder: i18n('IPage-title-placeholder', 'Type here...')
    }),
    publishingStartsAt: new DateTimeField({
      label: i18n('IPage-publishingStartsAt-label', 'Publish from')
    }),
    publishingEndsAt: new DateTimeField({
      label: i18n('IPage-publishingEndsAt-label', 'Publish to')
    }),
    subject: new TextField({
      label: i18n('IPage-subject-label', 'Ämne'),
      placeholder: i18n('IPage-title-placeholder', 'Type here...')
    })
  })
});
export { IPage };