import { Component } from 'inferno'

export function getPostId(post) {
  return post && (post._pathId || post._id)
}

export function getPostSlug(post) {
  if (post && post._slug) {
    return '/' + post._slug
  }
  else {
    return ''
  }
}

export const maxImages = {
  'one': 1,
  'two': 2,
  'one_two': 3,
  'one_three': 4,
  'one_two_horizontal': 3,
  'one_four_horizontal': 5
}

/**
 * 
 * @param {object} editor - the mobiledoc instance
 * @param {object} postEditor - passed to editor.run callback
 * @param {object} range - current selected range
 * @param {string} cardType - card utility name
 * @param {object} payload - the data for this card
 */
export function insertCard(editor, postEditor, range, cardType, payload) {
  let position
  if (editor.post.isBlank) {
    const p = postEditor.builder.createMarkupSection('p')
    postEditor.insertSection(p)
    // postEditor.setRange(postEditor.editor.post.sections.head.headPosition())
    position = postEditor.editor.post.headPosition()
  } else {
    position = range.tail;
  }
  
  // In case we are at the end of a post
  if (position.isBlank) {
    //const p = postEditor.builder.createMarkupSection('p')
    //postEditor.insertSection(p)
    position = postEditor.editor.post.tailPosition()
  }

  /*
  if (!range.isCollapsed) {
    position = postEditor.deleteRange(range);
  }
  */
 
  let section = position.section;
  if (section.isNested) {
    section = section.parent;
  }
 
  let collection = editor.post.sections;
  const card = postEditor.builder.createCardSection(cardType, payload);
  postEditor.insertSectionBefore(collection, card, section);

  // It is important to explicitly set the range to the end of the card.
  // Otherwise it is possible to create an inconsistent state in the
  // browser. For instance, if the user clicked a button that
  // called `editor.insertCard`, the editor surface may retain
  // the selection but lose focus, and the next keystroke by the user
  // will cause an unexpected DOM mutation (which can wipe out the
  // card).
  // See: https://github.com/bustle/mobiledoc-kit/issues/286
  postEditor.setRange(section.tailPosition());
}

export class CardButtonBase extends Component {
    name = 'CardName'
  
    state = {
      showModal: false
    }
  
    doShowModal = () => {
      this.setState({
        showModal: true
      })
    }
  
    doHideModal = () => {
      this.setState({
        showModal: false
      })
    }
  
    doSubmit = (payload) => {
      const { editor } = this.context
      const { range } = this.props
  
      editor.run(postEditor => {
        insertCard(editor, postEditor, range, this.name, payload)
      })
  
      this.setState({
        showModal: false
      })
      this.props.onInsert()
    }
}