import { Component } from 'inferno'
import { Adapter, Utility } from 'component-registry'
import classnames from 'classnames'
import { i18n } from '../../../i18n'

import { Link } from 'inferno-router'
import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalFooter } from 'inferno-bootstrap'
import { ResponsiveImage } from '../../../widgets/ResponsiveImage'
import { getPostId, getPostSlug } from '../common'

import { IApiClient, ISessionManager } from '../../../interfaces/app'
import { ILightbox } from '../../../interfaces/presentation'
import { IMediaCard } from 'influence-app-entities/lib/interfaces/cards/MediaCard'
import '../LiveListItem.scss'
import './LiveListItem.scss'


class Lightbox extends Component {
  constructor() {
    super(...arguments)

    this.state = {
      posts: []
    }
  }

  async _fetchRelatedPosts() {
    // { "body.cards.type": "MediaCard", "body.cards.id": "5ddd83824f6923faeaf9814f" }

    const query = { 
      query: {
        "body.cards.type": "MediaCard",
        "body.cards.id": this.props.image._id,
        
      }, 
      stateFilter: 'publishWorkflow.published',
      page: 1,
      limit: 5
    }

    const { data } = await new IApiClient().query({
      URI: `/content/Page`,
      query
    })

    this.setState({
      posts: data
    })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isOpen && !this._fetchDone) {
      this._fetchRelatedPosts()
      this._fetchDone = true
    }
  }

  renderRelatedPosts () {
    let outp = []
    return outp
  }

  render (props, state, context) {
    return (
      <Modal className="MediaLightbox Lightbox" isOpen={props.isOpen} toggle={props.onToggle}>
        <ButtonGroup className="Actions modal-header">
            <Button color="link" className="CloseButton" onClick={props.onToggle}>X Close</Button>
        </ButtonGroup>
        <ModalBody>
          <ResponsiveImage className="lightbox-image" {...props.image} onClick={this.doOpen} />
        </ModalBody>
        <ModalFooter>
          <p>
            {props.image.shortDescription}
            {' '}
            {this.renderRelatedPosts()}
          </p>
        </ModalFooter>
      </Modal>
    )
  }
}

new Adapter({
  implements: ILightbox,
  adapts: IMediaCard,
  Component: Lightbox
})

new Utility({
  implements: ILightbox,
  name: 'MediaCard',
  Component: Lightbox
})