import { Adapter } from 'component-registry'
import { i18n } from '../../i18n'

import { Thumbnail } from '../../widgets/ResponsiveImage'

import { IRelationFieldItem } from '../../interfaces/presentation'
import { IUser } from 'influence-app-entities/lib/interfaces/User'
import { safeJoin } from 'safe-utils'
import './RelationFieldItem.scss'

function ListItem ({ context: user }) {
  return (
    <div className="RelationField-User list-item media">
      <Thumbnail className="avatar mr-3" image={user.avatarUrl} />
      <div className="media-body list-item-body">
        {safeJoin([user.firstName, user.lastName], ' ')}
        <h3>{user._id}</h3>
      </div>
    </div>
  )
}

new Adapter({
  implements: IRelationFieldItem,
  adapts: IUser,
  Component: ListItem
})