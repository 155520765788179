import { createObjectPrototype } from 'component-registry';
import { BaseField } from 'isomorphic-schema';
import { IImageField } from '../interfaces/fields';
var ImageField = createObjectPrototype({
  implements: [IImageField],
  extends: [BaseField],
  constructor: function constructor(options) {
    this._IBaseField.constructor.call(this, options);
  }
});
export { ImageField };