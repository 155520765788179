
'use strict';
import { Component } from 'inferno'
import MediaSelector from '../../widgets/MediaSelector'
import MediaUnsplash from '../../widgets/MediaUnsplash'
import { i18n } from '../../i18n'

import {
    Modal,
    ModalBody,
    ModalHeader,
    Nav, NavItem, NavLink
 } from 'inferno-bootstrap'

import './SelectMediaWidget.scss'

export class SelectMediaWidget extends Component {
  constructor (props) {
      super(...arguments)

      this.state = {
        librarySource: undefined
      }

      this.closeModal = this.closeModal.bind(this)
      this.didSelect = this.didSelect.bind(this)
      this.doCancel = this.doCancel.bind(this)
      this.didSelectLibrarySource = this.didSelectLibrarySource.bind(this)
  }
  
  closeModal () {
    this.props.onClose && this.props.onClose()
  }

  didSelect (value) {
      this.props.onSelect(value)
      this.closeModal()
  }
  
  doCancel (e) {
      e.preventDefault()
      this.closeModal()
  }

  didSelectLibrarySource (source) {
    this.setState({
      librarySource: source
    })
  }

  renderMenu () {
    return (
      <Nav className="ModalMenu">
        <NavItem>
          <NavLink
            active={!this.state.librarySource}
            onClick={(e) => {e.preventDefault(); this.didSelectLibrarySource(undefined)}}>Mina bilder</NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            active={this.state.librarySource === 'unsplash'}
            onClick={(e) => {e.preventDefault(); this.didSelectLibrarySource('unsplash')}}>Unsplash</NavLink>
        </NavItem>
        {/*<NavItem>
          <NavLink
            active={this.state.librarySource === 'instagram'}
            onClick={(e) => {e.preventDefault(); this.didSelectLibrarySource('instagram')}}>Instagram</NavLink>
        </NavItem>*/}
      </Nav>
    )
  }

  renderLibrarySource() {
    // TODO: Revise how value is stored and passed around
    const { value } = this.props
    const { librarySource } = this.state 

    if (librarySource === undefined) {
      return <MediaSelector initialSelection={value} onSelect={this.didSelect} />
    }
    else if (librarySource === 'unsplash') {
      return <MediaUnsplash onSelect={this.didSelect} />
    }
  }
  
  render () {
      return (
          <Modal key="modal" className="MediaWidget"
            isOpen={this.props.isOpen}
            toggle={this.closeModal}
            fade="true">
              <ModalHeader tag="div">
                  {this.renderMenu()}
              </ModalHeader>
              <ModalBody>
                  {this.renderLibrarySource()}
              </ModalBody>
          </Modal>
      )
  }
}

