import { Adapter } from 'component-registry'
import { i18n } from '../../i18n'

import { Link } from 'inferno-router'
import {
  Row,
} from 'inferno-bootstrap'

import { ISessionManager } from '../../interfaces/app'
import { ILiveListItem } from '../../interfaces/presentation'
import { IPage } from 'influence-app-entities/lib/interfaces/Page'

function ListItem (props, context) {
  const obj = props.context
  return (
    <Row className="live-list-item list-item col">
      <h2>
        <Link to={`/Page/${obj._pathId || obj._id}`}>{obj.title}</Link>
      </h2>
      <h3>xxx timmar sedan av MMM NNN</h3>
    </Row>
  )
}

new Adapter({
  implements: ILiveListItem,
  adapts: IPage,
  Component: ListItem
})