import { createInterfaceClass } from 'component-registry';
var Interface = createInterfaceClass('influence-app-entities');
import { Schema, BoolField, ListField, SelectField, TextField, i18n } from 'isomorphic-schema';
import { ImageField } from '../../customFields/ImageField';
import { GalleryLayoutField } from '../../customFields/GalleryLayoutField';
var IMediaCard = new Interface({
  name: 'IMediaCard',
  schema: new Schema('MediaCard Schema', {
    shortDescription: new TextField({
      label: i18n('IMediaCard-shortDescription-label', 'Caption')
    }),
    image: new ImageField({
      label: i18n('ICard-image-label', 'Image'),
      placeholder: i18n('ICard-image-placeholder', 'Drag and drop...'),
      help: i18n('ICard-image-help', 'Only JPEG or PNG. Max size 5Mb.')
    }),
    multiple: new BoolField({
      label: 'Multiple images'
    }),
    images: new ListField({
      label: i18n('ICard-images-label', 'Images'),
      valueType: new ImageField({
        placeholder: i18n('ICard-images-valueType-placeholder', 'Drag and drop...')
      }),
      minItems: 1,
      required: true
    }),
    layoutType: new GalleryLayoutField({
      label: i18n('ICard-layoutType-label', 'Layout')
    }),
    allowCrop: new BoolField({
      label: i18n('ICard-allowCrop-label', 'Allow cropping to balance size')
    })
  })
});
IMediaCard.schema.addValidationConstraint(function (data, fieldKey) {
  switch (fieldKey) {
    case 'image':
      return !data.multiple;

    case 'allowCrop':
    case 'images':
    case 'layoutType':
      return data.multiple;

    default:
      return true;
  }
});
export { IMediaCard };