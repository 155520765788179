import { createObjectPrototype, Utility } from 'component-registry';
import { IMediaCard } from '../interfaces/cards/MediaCard';
import { ICard } from '../interfaces/cards/Card';
import { IObjectPrototypeFactory } from 'influence-interfaces/object';
import { IRoleManager, IHasRoleManager, IRoleManagerAdapter } from 'influence-interfaces/permissions';
import { Permissions } from 'influence-permissions/dist/permissions';
import assert from 'assert';
var MediaCard = createObjectPrototype({
  implements: [IMediaCard, ICard, IHasRoleManager],
  extends: [Permissions],
  constructor: function constructor(params) {
    this._type = 'MediaCard';

    this._IPermissions.constructor.call(this, params, params._permissions);
  }
});
export default MediaCard;
var ObjectPrototypeFactory = new Utility({
  implements: IObjectPrototypeFactory,
  name: 'MediaCard',
  getPrototype: function getPrototype() {
    return MediaCard;
  },
  getObject: function getObject(data, roleManager) {
    data = data || {};

    if (!data._permissions) {
      // Permissions for newly created object
      assert(IRoleManager.providedBy(roleManager), 'No RoleManager passed during creation of ' + data._type);
      data._permissions = new IRoleManagerAdapter(roleManager).getPermissions(this._name);
      data._roleManagerId = roleManager._id;
    }

    return new MediaCard(data);
  }
});