import { Adapter, Utility } from 'component-registry'
import { Component, Fragment } from 'inferno'
import { safeGet } from 'safe-utils'
import { i18n } from '../../i18n'

import { widgets, FormRows } from 'inferno-formlib'
import '../../formlib/ImageField'
import './EditForm.scss'
import { Link } from 'inferno-router'
import {
    Button,
    ButtonGroup,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Form,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Nav,
    NavDropdown,
} from 'inferno-bootstrap'

import { IObjectPrototypeFactory } from 'influence-interfaces/object'
import { calculateToolbarBoundary } from '../../widgets/RichTextWidget'

import { IUser } from 'influence-app-entities/lib/interfaces/User'
import { IEditForm, ICreateForm, INotificationManager } from '../../interfaces/presentation'
import { ISessionManager, IApiClient } from '../../interfaces/app'
import { IUserWorkflow } from 'influence-app-entities/lib/interfaces/workflows'

import '../../pages/admin/Create.scss'
import '../../pages/admin/Edit.scss'

const { ActionBar } = widgets


const workflowStates = IUserWorkflow.schema.workflowStates

function doChangeWorkflowState (obj, newState) {
  obj._workflows.userWorkflow = newState
  new IApiClient().update({
    URI: `/admin/User/${obj._id}`,
    data: obj,
    invalidate: `/admin/User`
  }).then(({data}) => {
    // Show success indicator at center of screen
    new INotificationManager().showSuccessMessage()
  })
}

function WorkflowStatus ({ post, isOpen, doOpen, doClose }) {
  return (
    <Nav className="WorkflowStatus">
      <NavDropdown isOpen={isOpen} toggle={() => { isOpen ? doClose() : doOpen()}}>
        <DropdownToggle nav caret className="status">
          {safeGet(() => post._workflows.userWorkflow)}
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem header>{i18n('WorkflowAction-updateStatus', 'Change Status')}</DropdownItem>
          {Object.keys(workflowStates).map((key) => {
            // Don't return the current state since we don't want to transition to ourself
            const isActive = (post._workflows && post._workflows.userWorkflow) === key
            if (isActive) return null
    
            return (
              <DropdownItem onClick={(e) => doChangeWorkflowState(post, key, doClose)}>
                {i18n('WorkflowAction-to', 'till ') + i18n(workflowStates[key].title)}
              </DropdownItem>
            )
          })}
        </DropdownMenu>
      </NavDropdown>
    </Nav>
  )
}

const selectFields = ['avatarUrl', 'firstName', 'lastName', 'userName', 'city', 'gender', 'birthDate', 'occupation', 'roles', 'mainBlogName', 'mainBlogId']
const omitFields = []

class EditForm extends Component {

    constructor (props) {
        super(...arguments)

        this.selectFields = selectFields
        this.omitFields = omitFields

        // This is a bit of a hack to prepopulate a user on creation
        // because we can't add AccountEmail in a nice way
        let newUser
        if (!props.value) {
          newUser = new IObjectPrototypeFactory('User').getObject({
            accounts: [
              new IObjectPrototypeFactory('AccountEmail').getObject({
                useForLogin: true
              })
            ]
          })

          this.selectFields = this.selectFields.concat([
            'accounts',
            'accounts.email',
            'accounts.password',
            'accounts.useForLogin'
          ])
        }

        this.state = {
          value: props.value || newUser,
          validationErrors: undefined,
          actionBarBoundary: {top: 0, bottom: 0},
          isDirty: false,
          popoverWorkflowStatusOpen: false,
          popoverTimedPublishingOpen: false,
          showCloseWarningModal: false
        }

        this.calculateToolbarBoundary = calculateToolbarBoundary.bind(this)        
    }

    componentDidMount () {
      this.calculateToolbarBoundary();
    }

    doOpenWorkflowStatus = (e) => {
      e && e.preventDefault()
      this.setState({
        popoverWorkflowStatusOpen: true
      });
    }

    doCloseWorkflowStatus = (e) => {
      e && e.preventDefault()
      this.setState({
        popoverWorkflowStatusOpen: false
      });
    }

    didUpdate = (propName, value) =>{
        const newVal = this.state.value
        const isDirty = this.state.isDirty || newVal

        let validationErrors
        if (this.state.submitted) {
          validationErrors = IUser.schema.validate(this.state.value, {
            selectFields: this.selectFields,
            omitFields: this.omitFields
          })
        }
        
        newVal[propName] = value
        this.setState({
            value: newVal,
            validationErrors,
            isDirty
        })
        this.calculateToolbarBoundary()
    }

    doClose = (e) => {
      let forceClose
      if (e.force) {
        forceClose = true
      }

      if (e.event) {
        // setting event param
        e = e.event
      }

      e.preventDefault()
      
      if (this.state.isDirty && !forceClose) {
        this.setState({
          showCloseWarningModal: true
        })
      }
      else {
        const { type } = this.props.match.params
        this.context.router.history.push(`/admin/${type}`)
      }
    }

    doSave = (e) => {
        this.doCloseWorkflowStatus()

        let validationErrors = IUser.schema.validate(this.state.value, {
          selectFields: this.selectFields,
          omitFields: this.omitFields
        })
        if (validationErrors) {
          return this.setState({
            validationErrors,
            submitted: true
          })
        }

        if (this.state.value._id) {
          new IApiClient().update({
            URI: `/content/User/${this.state.value._id}`,
            data: this.state.value,
            invalidate: ['/content/User', '/session']
          }).then(({data}) => {
            const sessionManager = new ISessionManager()
            const currentUser = sessionManager.getCurrentUser()
            // TODO: Figure out why this component doesn't re-render on .updateCurrentUser
            if (this.state.value._id === currentUser._id) {
              sessionManager.refreshCurrentUser()
            }
            new INotificationManager().showSuccessMessage()
          })
        } else {
            new IApiClient().create({
                URI: '/content/User',
                data: this.state.value,
                invalidate: '/content/User'
            }).then(({data}) => {
                // TODO: Show success indicator at center of screen
                new INotificationManager().showSuccessMessage()
                this.context.router.history.push(`/admin/User/${data._id}`)
            })
        }
    }

    renderCloseWarningModal () {
      return (
        <Modal isOpen={this.state.showCloseWarningModal} toggle={() => this.setState({ showCloseWarningModal: false })}>
          <ModalHeader>WARNING! Unsaved changes</ModalHeader>
          <ModalBody>
            <p>You have unsaved changes, are you sure you want to close without saving?</p>
          </ModalBody>
          <ModalFooter className="ActionBarContent">            
              <Button color="primary"
                onClick={(e) => {e.preventDefault(); this.setState({ showCloseWarningModal: false })}}>Cancel</Button>

              <i>or</i>
              
              <Button color="danger" outline
                onClick={(e) => {this.doClose({ event: e, force: true })}}>Close</Button>
              
              <i>or</i>
              
              <Button color="link"
                onClick={(e) => {this.doSave({}).then(() => this.doClose({ event: e }))}}>Save & close</Button>
          </ModalFooter>
        </Modal>
      )
    }

    renderSaveActions () {
      if (this.state.value._id) {
        return (
          <div className="ActionBarContent-MainActions">
            <Button color="success" type="submit">Update</Button>
          </div>
        )
      }
      else {
        return (
          <div className="ActionBarContent-MainActions">
            <Button color="success" type="submit">Create</Button>
          </div>
        )
      }
    }

    renderSecondaryActions () {
      if (!this.state.value._id) {
        return null
      }

      return (
        <div className="ActionBarContent-SecondaryActions">
          <WorkflowStatus post={this.state.value} isOpen={this.state.popoverWorkflowStatusOpen}
              doOpen={this.doOpenWorkflowStatus}
              doClose={this.doCloseWorkflowStatus} />
        </div>
      )
    }

    render () {
      const isCreated = (this.state.value._id !== undefined)
      
      return <Fragment>
          <ButtonGroup className="Actions form-header">
              <Button color="link" className="CloseButton" onClick={this.doClose}>X Close</Button>
          </ButtonGroup>
          <div ref={(e) => this._containerEl = e}>
              {this.state.modalWidget}
              <Form onSubmit={(e) => {e.preventDefault(); this.doSave({})}} className='IEditItem'>
                  <FormRows
                    schema={IUser.schema}
                    selectFields={this.selectFields}
                    omitFields={this.omitFields}
                    validationErrors={this.state.validationErrors}
                    value={this.state.value} onChange={this.didUpdate} />
                  <div className="ActionBar" boundary={this.state.actionBarBoundary}>
                      {/* Adding a key for ActionBarContent forces rerender when publish state is changed.
                          This makes sure that floating bar and sticky bar i in sync */}
                      <div className="ActionBarContent">
                          {this.renderSaveActions()}
                          {this.renderSecondaryActions()}
                      </div>
                  </div>
              </Form>
              {this.renderCloseWarningModal()}
          </div>
      </Fragment>
    }
}

new Adapter({
  implements: IEditForm,
  adapts: IUser,
  Component: EditForm
})

new Utility({
  implements: ICreateForm,
  name: 'User',
  Component: EditForm
})
