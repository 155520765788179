import { Component } from 'inferno'
import classnames from 'classnames'
import { findDOMNode } from 'inferno-extras'
import { safeGet } from 'safe-utils'
import './List.scss'
import './Dashboard.scss'
import {
  Col,
  Container,
} from 'inferno-bootstrap'
import StickyRow from '../../widgets/StickyRow'
import { MainMenu } from '../../widgets/MainMenu'
import { ISessionManager } from '../../interfaces/app'

let _env_ = (typeof window !== 'undefined' && window.__env__ ? window.__env__ : process.env )
let { IMAGE_URI_BASE } = _env_
IMAGE_URI_BASE = IMAGE_URI_BASE || '//localhost:3021/images'

export default class ListPage extends Component {

  constructor () {
    super(...arguments)

    this.state = {}
  }

  componentDidMount () {
    requestAnimationFrame(this._checkMainMenuHeight)
  }

  _checkMainMenuHeight = () => {
    // Adding 4px for shadow
    const tmp = safeGet(() => document.querySelector('.MainMenu').clientHeight, 0) + 4
    if (tmp !== this.state.mainMenuHeight) {
      this.setState({
        mainMenuHeight: tmp
      })
    }
    requestAnimationFrame(this._checkMainMenuHeight)
  }

  componentWillReceiveProps (nextProps, nextContext) {
    if (!nextProps.match.isExact && this.props.match.isExact) {
      // We are no longer on top so store scroll position and constrain content to screen to remove scrollbar
      this._storedScroll = this._storedScroll || {}
      const x = this._storedScroll['x'] = typeof window !== 'undefined' ? window.scrollX : 0
      const y = this._storedScroll['y'] = typeof window !== 'undefined' ? window.scrollY : 0
      requestAnimationFrame(() => {
        if (this && !this.$UN) {
          safeGet(() => findDOMNode(this).scroll(x, y))
        }
      })
    }
    else if (nextProps.match.isExact && !this.props.match.isExact) {
      // We are now on top again. Restore scroll position and let the content flow
      if (typeof window !== 'undefined') {
        const { x, y } = this._storedScroll || { x: 0, y: 0 }
        // Need to move after re-render so we have space to scroll
        requestAnimationFrame(() => {
          if (this && !this.$UN) {
            safeGet(() => findDOMNode(this).scroll(0, 0))
            window.scroll(x, y)
          }
        })
      }
    }
  }

  renderToolbar () {
    if (!this.props.toolbar) {
      return null
    }
    else {
      return (
        <div className="toolbar">{this.props.toolbar}</div>
      )
    }
  }

  renderFilter () {
    if (!this.props.filter) return null

    return (
      <Container fluid className="FilterContainer">
        <StickyRow stickToTop boundary={{ top: this.state.mainMenuHeight, bottom: 0}}>
          <Col>
            {this.props.filter}
          </Col>
        </StickyRow>
      </Container>
    )
  }

  render ({ type }, state, context) {
    const { isExact, params } = this.props.match
    type = type || params.type
    const listPageType = type

    const currentUser = new ISessionManager({ registry: context.registry }).getCurrentUser()
    if (!currentUser) return null


    const cls = classnames(
      "ListPage",
      { "not-on-top": !isExact },
      this.props.className
    )

    return (
      <div className={cls} style={{ 'padding-top': this.state.mainMenuHeight + 'px' }}>
        <MainMenu active={true}>
          {this.props.pullUpMenuItems}
        </MainMenu>
        {this.renderToolbar()}
        {this.renderFilter()}
        <Container fluid className={`ListPage-${listPageType}`}>
          {this.props.children || null}
        </Container>
      </div>
    )
  }
}



