import { Component } from 'inferno'
import { safeGet, safeJoin } from 'safe-utils'
import { Container, Row, Col, Form, Button } from 'inferno-bootstrap'
import { Animated } from 'inferno-animation'
import { widgets, FormRows } from 'inferno-formlib'
import StickyRow from '../../widgets/StickyRow'
import { i18n } from '../../i18n'
import SitePageFooter from '../../widgets/SitePageFooter'
import '../../formlib/ImageField'
import '../../entities/User/EditForm.scss'
import logo_colored_svg from '../../img/logo/logo-colored.svg'
import { IImage } from 'influence-app-entities/dist/interfaces/Image'
import { IObjectPrototypeFactory } from 'influence-interfaces/object'
import { IUser } from 'influence-app-entities/lib/interfaces/User'
import { IApiClient, ISessionManager } from '../../interfaces/app'
import { IImageHelper, INotificationManager } from '../../interfaces/presentation'

import anonAvatar_svg from '../../img/anon_avatar.svg'

const selectFields = ['avatarUrl', 'firstName', 'lastName', 'userName', 'city', 'gender', 'birthDate', 'occupation']
const omitFields = []


export default class Page extends Component {

  constructor (props, context) {
    super(...arguments)

    this.state = {
      value: new ISessionManager({ registry: context.registry }).getCurrentUser() || {},
      validationErrors: undefined,
      actionBarBoundary: {top: 0, bottom: 0}
    }

    this.didUpdate = this.didUpdate.bind(this)
    this.doSubmit = this.doSubmit.bind(this)
  }

  static async fetchData ({registry, match, location}) {
    const { data } = await new IApiClient({ registry }).query({
        URI: `/session`
    })
    
    return data
  }

    componentDidUpdate(prevProps, prevState) {
      const currentUser = new ISessionManager(this.context).getCurrentUser()
      if (safeGet(() => prevState.value._id !== currentUser._id)) {
        this.setState({
          value: currentUser
        })
      }
    }

    didUpdate (propName, value) {
      const newVal = this.state.value
      newVal[propName] = value

      let validationErrors
      if (this.state.submitted) {
        validationErrors = IUser.schema.validate(this.state.value, {
          selectFields,
          omitFields
        })
      }

      this.setState({
          value: newVal,
          validationErrors
      })
    }

    doSubmit (e) {
      e.preventDefault()

      let validationErrors = IUser.schema.validate(this.state.value, {
        selectFields,
        omitFields
      })
      if (validationErrors) {
        return this.setState({
          validationErrors,
          submitted: true
        })
      }
      
      new IApiClient().update({
          URI: `/content/User/${new ISessionManager().getCurrentUser()._id}`,
          data: this.state.value,
          invalidate: ['/content/User', '/session']
      }).then(({data}) => {
          // TODO: Figure out why this component doesn't re-render on .updateCurrentUser
          new ISessionManager().refreshCurrentUser()
          new INotificationManager().showSuccessMessage()
          this.context.router.history.push('/admin/dashboard')
      })
  }

  renderAvatar() {
    const context = this.context
    const currentUser = new ISessionManager({ registry: context.registry }).getCurrentUser()

    let src = anonAvatar_svg
    let srcSet
    
    if (currentUser && IImage.providedBy(currentUser.avatarUrl)) {
      const imgHlpr = new IImageHelper(currentUser.avatarUrl)
      src = imgHlpr.getThumbSrc()
      srcSet = imgHlpr.getThumbSrcSet()
    }
    
    return <img class="Onboarding-Avatar" src={src} srcSet={srcSet} />
  }

    render (props, state, context) {
        
        return (
          <Animated key="me" prefix="PageNav">
            <div className="Fullpage Onboarding">
              <img id="Page-Logo" src={logo_colored_svg} />
              <Container fluid className="Onboarding-VerifyEmail">
                <Row className="col">
                  {this.renderAvatar()}
                </Row>
                <Row className="col">
                  <p>We need some more information about you to be able to finnish the registration.</p>
                </Row>
                <Row className="col">
                  <Form onSubmit={this.doSubmit} className='IEditItem'>
                    <FormRows
                      schema={IUser.schema}
                      validationErrors={this.state.validationErrors}
                      value={this.state.value}
                      selectFields={selectFields}
                      omitFields={omitFields}
                      onChange={this.didUpdate} />
                  </Form>
                </Row>
                <StickyRow className="Onboarding-BasicData">
                  <Button
                    className="Onboarding-Button Onboarding-Action-Button"
                    color="primary"
                    size="lg"
                    onClick={this.doSubmit} href="#instagram">Let's go!</Button>
                </StickyRow>
                  
              </Container>
              <SitePageFooter />
            </div>
          </Animated>
        )
    }
}

