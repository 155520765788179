import MobileDocComponent from '../../widgets/MobileDocComponent'
import { i18n } from '../../i18n'
import { safeGet } from 'safe-utils'
import { IApiClient, IPageManager, ISessionManager } from '../../interfaces/app'
import PageTemplate from '../../widgets/PageTemplate'
import './ContentPage.scss'
import '../onboarding/RegisterStep.scss'

const env = (typeof window === 'undefined' ? process.env : window.__env__)
const { FRONTEND_BASE_URI } = env

export default class Page extends MobileDocComponent {

  static async fetchData ({registry, match, location}) {
    new IPageManager({ registry }).setMetaData({
      title: 'Veckoappen - Användarvillkor',
      description: `Veckoappen gör din vardagsplanering enkel och stressfri. Få en tydlig översikt och upplev känslan av att få mer gjort.`,
      url: FRONTEND_BASE_URI + '/license-agreement'
    })

    const query = { 
      query: {}, 
      stateFilter: 'publishWorkflow.published'
    }

    const { data } = await new IApiClient({ registry }).query({
      URI: `/content/LicenseAgreement`,
      query,
      page: 1,
      limit: 1
    })
    return data

  }

  componentDidMount() {
    window.scroll(0, 0)
  }

  render () {
    const userAgreement = Array.isArray(this.props.fetchData) ? this.props.fetchData[0] || {} : {}
    const mobiledoc = safeGet(() => userAgreement.body)

    return (
      <PageTemplate>
        <div className="Content">
          <div className="Content-Header">
            <h1>{userAgreement.title}</h1>
          </div>
          <div className="Content-Section">
            {mobiledoc && this.renderer.render(mobiledoc)}
          </div>
        </div>
      </PageTemplate>
    )
  }
}

