"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PublishWorkflow = void 0;

var _componentRegistry = require("component-registry");

var _influenceWorkflow = require("influence-workflow");

var _workflow = require("influence-interfaces/workflow");

var _object = require("influence-interfaces/object");

var _workflows = require("../../interfaces/workflows");

var _workflows$IPublishWo = _workflows.IPublishWorkflow.schema,
    statePropName = _workflows$IPublishWo.statePropName,
    workflowStates = _workflows$IPublishWo.workflowStates;
var PublishWorkflow = (0, _componentRegistry.createObjectPrototype)({
  implements: [_workflows.IPublishWorkflow],
  extends: [_influenceWorkflow.Workflow],
  constructor: function constructor(params, _ref) {
    var defaultState = _ref.defaultState;

    this._IWorkflow.constructor.call(this, params);

    if (this._workflows[statePropName] === undefined) {
      this._workflows[statePropName] = defaultState;
    }
  }
});
exports.PublishWorkflow = PublishWorkflow;
var PublishWorkflowPrototypeFactory = new _componentRegistry.Utility({
  implements: _object.IObjectPrototypeFactory,
  name: 'PublishWorkflow',
  getPrototype: function getPrototype() {
    return PublishWorkflow;
  },
  getObject: function getObject() {
    return new PublishWorkflow();
  }
});
var PublishWorkflowLookup = new _componentRegistry.Utility({
  implements: _workflow.IWorkflowLookup,
  name: 'PublishWorkflow',
  getInterface: function getInterface() {
    return _workflows.IPublishWorkflow;
  }
});
var PublishWorkflowState = new _componentRegistry.Adapter({
  implements: _workflow.IWorkflowState,
  adapts: _workflows.IPublishWorkflow,
  getState: function getState() {
    if (this.context._workflows) {
      var tmpStates = this.getAllStates();
      var tmpName = this.context._workflows[statePropName];
      var tmpState = tmpStates[tmpName];
      return tmpState && {
        state: tmpName,
        title: tmpState.title
      };
    }
  },
  setState: function setState(newState, currPrincipal) {
    var tmp = this.getAvailableStates(currPrincipal);

    if (tmp.hasOwnProperty(newState)) {
      this.context._workflows[statePropName] = newState;
    } else {// TODO: Throw WorkflowStateError
    }
  },
  getAllStates: function getAllStates() {
    return workflowStates;
  },
  getAvailableStates: function getAvailableStates(currPrincipal) {
    return workflowStates;
  }
});