'use strict'
import { globalRegistry, Utility } from 'component-registry'

import { IDeserialize, IObjectPrototypeFactory } from 'influence-interfaces/object'

var DeserializeUtility = new Utility({
    implements: IDeserialize,
    name: 'admin-api',
    
    _determineObjectType: function (obj) {
        return obj['_type']
    },
    
    deserialize: function (item) {
        if (Array.isArray(item)) {
    
            // Item is an array so lets create one and iterate over the input
            const outp = []
            for (let i = 0, imax = item.length; i < imax; i++) {
                outp.push(this.deserialize(item[i]))
            }
            return outp
    
        } else if (item && typeof item === "object") {
        
            const objectType = this._determineObjectType(item)

            // We traverse the object in case it contains objects further down
            // the hierarchy
            const outp = {}
            const itemKeys = Object.keys(item)
            for (let i = 0, imax = itemKeys.length; i < imax; i++) {
                const key = itemKeys[i]
                outp[key] = this.deserialize(item[key])
            }

            if (objectType !== undefined){
                // Ok so we got an object from . We need to create it with the prototype factory
                const ofu = new IObjectPrototypeFactory(objectType)
                // Create with the deserialized
                return ofu.getObject(outp, this.roleManager)
            }
    
            // It wasn't an object defined here so we are just returning it as is
            return outp
    
        } else {
    
            // Ok, it is just an ordinary value, we just return it
            return item
        }
    }
})
