import { Utility } from 'component-registry'
import { Component, Fragment } from 'inferno'
import classnames from 'classnames'
import { safeGet } from 'safe-utils'
import { i18n } from '../../i18n'

import { FormRows } from 'inferno-formlib'
import { Schema, DateField, IntegerField } from 'isomorphic-schema'

import {
    Button,
    ButtonGroup,
    Form
} from 'inferno-bootstrap'

import { IImportForm, IListItem } from '../../interfaces/presentation'
import { IApiClient } from '../../interfaces/app'
import { INotificationManager } from '../../interfaces/presentation'

import '../../pages/admin/List.scss'
import '../../pages/admin/Create.scss'
import '../Page/EditForm.scss'
import './ImportForm.scss'



const importSchema = new Schema({
  schemaName: 'Import Schema',
  fields: {
    startDate: new DateField({
      label: 'StartDate',
      required: true
    }),
    nrofWeeks: new IntegerField({
      label: 'Nr of weeks',
      min: 0,
      max: 10,
      required: true
    })
  }
})

const IS_DEV = process.env.NODE_ENV !== 'production'

class ImportForm extends Component {

    constructor (props, context) {
        super(...arguments)

        this.state = {
            value: {},
            validationError: undefined,
            toolbarBoundary: {top: 0, bottom: 0},
            actionBarBoundary: {top: 0, bottom: 0},
            isDirty: false
        }
    }

    didUpdate = (propName, value) => {
        const isNewValue = JSON.stringify(value) !== JSON.stringify(this.state.value[propName])
        const isDirty = this.state.isDirty || isNewValue
        
        if (isNewValue) {
          const newVal = this.state.value
          newVal[propName] = value

          let validationError
          if (this.state.submitted) {
            validationError = importSchema.validate(newVal)
          }

          this.setState({
              value: newVal,
              validationError,
              isDirty
          })
        }
    }

    doClose = (e) => {
      e.preventDefault()
      this.context.router.history.push(`/admin/NameDay`)
    }

    doImport = async ({ publish }) => {
      const val = Object.assign({}, this.state.value)

      const validationError = importSchema.validate(this.state.value)
      if (validationError) {
        return this.setState({
          validationError,
          submitted: true
        })
      }
      else {
        this.setState({
          submitted: false
        })
      }

      const { data } = await new IApiClient().query({
          URI: `/api/veckoappen`,
          query: {
            "startDate": val.startDate,
            "nrofWeeks": val.nrofWeeks
          },
          invalidate: `/content/NameDay`
      })

      // Show success indicator at center of screen
      new INotificationManager().showSuccessMessage()
      
      val.startDate = data.nextDate
      this.setState({
        importResult: data,
        value: val
      })
    }

    renderSaveActions () {
      return (
        <div className="ActionBarContent-MainActions">
          <Button color="success" type="submit">Import</Button>
        </div>
      )
    }

    renderSecondaryActions () {
      return null
    }

    render () {
      const lastImportedItems = safeGet(() => this.state.importResult.nameDays, [])
      const lastImportedErrors = safeGet(() => JSON.stringify(this.state.importResult.errors), null)
      return (
        <Fragment>
          <ButtonGroup className="Actions form-header">
              <Button color="link" className="CloseButton" onClick={this.doClose}>X Close</Button>
          </ButtonGroup>
          <div ref={(e) => this._containerEl = e} className='IEditItem'>
              {this.state.modalWidget}
              <Form onSubmit={(e) => {e.preventDefault(); this.doImport({})}}>

                  <FormRows
                    schema={importSchema}
                    validationErrors={this.state.validationError}
                    value={this.state.value} onChange={this.didUpdate} />

                  <div className="ActionBar" boundary={this.state.actionBarBoundary}>
                      {/* Adding a key for ActionBarContent forces rerender when publish state is changed.
                          This makes sure that floating bar and sticky bar i in sync
                          key={this.state.value._workflows.publishWorkflow} */}
                      <div className="ActionBarContent">
                          {this.renderSaveActions()}
                          {this.renderSecondaryActions()}
                      </div>
                  </div>
              </Form>
              <h2>Errors</h2>
              <div>{lastImportedErrors}</div>
              <h2>Imported Items</h2>
              <div className="List-Contaier">
                {lastImportedItems.map((item) => {
                  const ListItem = new IListItem(item).Component
                  return <ListItem key={item._id} context={item} />
                })}
              </div>
          </div>
        </Fragment>
      )
    }
}

new Utility({
  implements: IImportForm,
  name: 'NameDay',
  Component: ImportForm
})