import { Adapter } from 'component-registry'
import { IMobileDocField } from 'influence-app-entities/lib/interfaces/fields'
import MobileDocFieldWidget from './MobileDocFieldWidget'
import { interfaces } from 'inferno-formlib'
const { IInputFieldWidget } = interfaces

new Adapter({
  implements: IInputFieldWidget,
  adapts: IMobileDocField,
  Component: MobileDocFieldWidget
})

export {
  IMobileDocField, 
  MobileDocFieldWidget
}