import { createObjectPrototype } from 'component-registry';
import { TextField, SelectField, i18n } from 'isomorphic-schema';
import { IGalleryLayoutField } from '../interfaces/fields';
/**
 * Gallery Layout Field
 */

var GalleryLayoutField = createObjectPrototype({
  implements: [IGalleryLayoutField],
  extends: [SelectField],
  constructor: function constructor(options) {
    if (!options) {
      options = {};
    }

    options.valueType = new TextField({}); // We can override value options when using this field

    if (!options.options) {
      options.options = [{
        name: 'one',
        title: i18n('ICard-layoutType-option-one', 'Single')
      }, {
        name: 'two',
        title: i18n('ICard-layoutType-option-two', 'Two, side by side')
      }, {
        name: 'one_two',
        title: i18n('ICard-layoutType-option-one_two', 'Three, one on top')
      }, {
        name: 'one_three',
        title: i18n('ICard-layoutType-option-one_three', 'Four, one on top')
      }, {
        name: 'one_two_horizontal',
        title: i18n('ICard-layoutType-option-one_two_horizontal', 'Three, one on left')
      }, {
        name: 'one_four_horizontal',
        title: i18n('ICard-layoutType-option-one_four_horizontal', 'Five, one on left')
      }];
    }

    this._ISelectField.constructor.call(this, options);
  },
  validate: function validate(inp) {
    var error = this._ISelectField.validate.call(this, inp);

    if (error) {
      return error;
    }
  }
});
export { GalleryLayoutField };