import {createComponentVNode, } from 'inferno'
import {VNodeFlags} from 'inferno-vnode-flags'
import {Route} from 'inferno-router';
import {combineFrom, isFunction} from 'inferno-shared';

export class RestishRoute extends Route {
  render() {
    const { match } = this.state;
    const { children, component, render, asyncBeforeData } = this.props;
    const { history, route, staticContext } = this.context.router;
    const location = this.props.location || route.location;

    const props = Object.assign({ match, location, history, staticContext }, asyncBeforeData);

    if (component) {
      if (process.env.NODE_ENV !== 'production') {
        if (!isFunction(component)) {
          throw new Error("Inferno error: <Route /> - 'component' property must be prototype of class or functional component, not vNode.");
        }
      }
      return match ? createComponentVNode(VNodeFlags.ComponentUnknown, component, props) : null;
    }

    if (render) {
      return match ? render(props, this.context) : null;
    }

    if (typeof children === 'function') {
      return (children)(props);
    }

    return children;
  }
}