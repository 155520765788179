export default function () {
  return (
    <svg width="200px" height="150px" viewBox="0 0 200 150">
        <g id="icon_gallery_one_two_horizontal" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <rect fill="currentColor" x="0" y="0" width="95" height="150"></rect>
            <rect fill="currentColor" x="105" y="0" width="95" height="70"></rect>
            <rect fill="currentColor" x="105" y="80" width="95" height="70"></rect>
        </g>
    </svg>
  )
}