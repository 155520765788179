import { Component } from 'inferno'
import { Container } from 'inferno-bootstrap'
import { Animated } from 'inferno-animation'
import { IImportForm } from '../../interfaces/presentation'
import './Create.scss'

export default class Page extends Component {

    // static async fetchData ({registry, match, location}) {
    //   // TODO: Something
    // }
    
    componentDidMount() {
      window.scroll(0, 0)
    }

    render (props, state, context) {
        const { type } = context.router.route.match.params

        const ImportForm = new IImportForm(type).Component
        if (!ImportForm) return null

        return (
          <Animated key="me" className="EditPage" prefix="PageNav">
            <Container fluid>
                <h1>Import {type}</h1>
                <ImportForm {...this.props} />
            </Container>
          </Animated>
        )
    }
}

