"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Principal = void 0;

var _componentRegistry = require("component-registry");

var _principal = require("influence-interfaces/principal");

var _object = require("influence-interfaces/object");

var Principal = (0, _componentRegistry.createObjectPrototype)({
  implements: [_principal.IPrincipal],
  constructor: function constructor(params) {
    this._principalId = params.principalId;

    if (params.principalId) {
      delete params.principalId;
    }
  },
  permissions: function permissions() {
    return this.roles;
  }
});
exports.Principal = Principal;
var PrincipalFactory = new _componentRegistry.Utility({
  implements: _object.IObjectPrototypeFactory,
  name: 'Principal',
  getPrototype: function getPrototype() {
    return Principal;
  },
  getObject: function getObject(obj) {
    return new Principal(obj);
  }
}); // The root principal is used to do really deep shit! 

var RootPrincipal = (0, _componentRegistry.createObjectPrototype)({
  implements: [_principal.IRootPrincipal, _principal.IPrincipal],
  constructor: function constructor() {
    this._principalId = "root";
    this._id = "root";
    this._type = "RootPrincipal";
  },
  permissions: function permissions() {
    return 'admin';
  }
});
var RootPrincipalFactory = new _componentRegistry.Utility({
  implements: _object.IObjectPrototypeFactory,
  name: 'RootPrincipal',
  getPrototype: function getPrototype() {
    return RootPrincipal;
  },
  getObject: function getObject() {
    return new RootPrincipal();
  }
}); // The anonymous principal is used to do do public access for none logged in users

var AnonymousPrincipal = (0, _componentRegistry.createObjectPrototype)({
  implements: [_principal.IAnonymousPrincipal, _principal.IPrincipal],
  constructor: function constructor() {
    this._principalId = "anonymous";
    this._id = "anonymous";
    this._type = "AnonymousPrincipal";
  },
  permissions: function permissions() {
    return ['anonymous'];
  }
});
var AnonymousPrincipalFactory = new _componentRegistry.Utility({
  implements: _object.IObjectPrototypeFactory,
  name: 'AnonymousPrincipal',
  getPrototype: function getPrototype() {
    return AnonymousPrincipal;
  },
  getObject: function getObject() {
    return new AnonymousPrincipal();
  }
});