import { safeGet } from 'safe-utils'
import { Adapter } from 'component-registry'

import { Link } from 'inferno-router'
import {
  Row,
  Col,
  Nav,
  NavLink
} from 'inferno-bootstrap'
import { i18n } from '../../i18n'

import { IApiClient } from '../../interfaces/app'
import { ISessionManager } from '../../interfaces/app'
import { IListItem, INotificationManager } from '../../interfaces/presentation'
import { INameDay } from 'veckoappen-app-entities/lib/interfaces/NameDay'
import { IPublishWorkflow } from 'influence-app-entities/lib/interfaces/workflows'

import '../common/ListItem.scss'
import { DateField } from 'isomorphic-schema'

const workflowStates = IPublishWorkflow.schema.workflowStates

function doChangeWorkflowState (obj, newState) {
  obj._workflows.publishWorkflow = newState
  new IApiClient().update({
    URI: `/content/NameDay/${obj._id}`,
    data: obj,
    invalidate: `/content/NameDay`
  }).then(({data}) => {
    // Show success indicator at center of screen
    new INotificationManager().showSuccessMessage()
  })
}

function WorkflowActions ({ obj }, context) {
  
  return (
    <Nav className="actions" pills horizontal="end">
      {Object.keys(workflowStates).map((key) => {
        // Don't return the current state since we don't want to transition to ourself
        const isActive = (obj._workflows && obj._workflows.publishWorkflow) === key
        if (isActive) return null

        return (
          <NavLink href="#" active={isActive} onClick={(e) => doChangeWorkflowState(obj, key)}>
            {i18n('WorkflowAction-to', 'till ') + i18n(workflowStates[key].title)}
          </NavLink>
        )
      })}
    </Nav>
  )
}

function ListItem (props, context) {
  const obj = props.context
  const date = safeGet(() => new Date(obj.startDate))
  const dateStr = safeGet(() => date.getDate() + '/' + (date.getMonth() + 1), '')

  return (
    <Row className="list-item">
      <Col className="list-item-body">
        {dateStr} <Link to={`/admin/NameDay/${obj._pathId || obj._id}`}>{obj.title}</Link>
        <h3>{obj.startDate}-{obj.endDate}</h3>
      </Col>
      <Col className="list-item-workflow">
        
        <h2 className="status">
          {obj._workflows && obj._workflows.publishWorkflow}
        </h2>
        <WorkflowActions obj={obj} />
      </Col>
    </Row>
  )
}

new Adapter({
  implements: IListItem,
  adapts: INameDay,
  Component: ListItem
})