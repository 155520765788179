import { Component } from 'inferno'
import { globalRegistry } from 'component-registry'
import { utilityToCard, utilityToAtom } from './utils'
import { renderString } from 'inferno-formlib'
import { i18n } from '../../i18n'

import * as ReactMobiledoc from 'inferno-mobiledoc-editor'
import Toolbar from './Toolbar'

import { IMobileDocCardUtil, IMobileDocAtomUtil } from 'influence-app-entities/lib/interfaces/fields'

import './MobileDocFieldWidget.scss';

const mobiledoc = {
  version: "0.3.0",
  markups: [],
  atoms: [],
  cards: [],
  sections: []
}

class MobileDocFieldWidget extends Component {
  constructor (props) {
      super(props)

      const cardUtils = new IMobileDocCardUtil('*')
      const atomUtils = new IMobileDocAtomUtil('*')

      this.state = {
        cards: cardUtils.map(util => utilityToCard(util)),
        atoms: atomUtils.map(util => utilityToAtom(util)),
        value: props.value || mobiledoc
      }
      this.didGetInput = this.didGetInput.bind(this)
      this.didGetChange = this.didGetChange.bind(this)
  }

  componentWillReceiveProps (nextProps) {
      this.setState({
          value: nextProps.value
      })
  }

  didGetInput (val) {
      const field = this.props.adapter.context
      const newVal = field.fromString(val)
      this.setState({
          value: newVal
      })

      // Trigger change so FormRow can update character counter
      this.props.onChange(this.props.propName, newVal)
  }

  didGetChange (e) {
      this.props.onChange(this.props.propName, this.state.value)
  }

  render ({inputName, namespace, options}) {
      const field = this.props.adapter.context

      const isValid = this.props.validationError || this.props.invariantError ? false : undefined

    return (
      <ReactMobiledoc.Container
        className="MobileDoc"
        cards={this.state.cards} atoms={this.state.atoms}
        placeholder={renderString(field.placeholder, options && options.lang)}
        mobiledoc={field.toFormattedString(this.state.value)}
        onChange={this.didGetInput}>
        <Toolbar className="Toolbar" />
        <ReactMobiledoc.Editor />
      </ReactMobiledoc.Container>
    )

    /*
      return <Input
          id={generateId(namespace, '__Field')}
          name={inputName}
          valid={isValid}
          placeholder={renderString(field.placeholder, options && options.lang)}
          readOnly={field.readOnly}
          value={field.toFormattedString(this.state.value)}

          onInput={this.didGetInput}
          onChange={this.didGetChange} />
    */
  }
}

export default MobileDocFieldWidget;
