"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.IGalleryLayoutField = exports.IRelationField = exports.IGeoPointField = exports.IMobileDocAtomUtil = exports.IMobileDocCardUtil = exports.IMobileDocField = exports.IWorkflowFilterField = exports.IImageField = void 0;

var _componentRegistry = require("component-registry");

var Interface = (0, _componentRegistry.createInterfaceClass)('influence-app-entities');
var IImageField = new Interface({
  name: 'IImageField'
});
exports.IImageField = IImageField;
var IWorkflowFilterField = new Interface({
  name: 'IWorkflowFilterField'
});
exports.IWorkflowFilterField = IWorkflowFilterField;
var IMobileDocField = new Interface({
  name: 'IMobileDocField'
});
exports.IMobileDocField = IMobileDocField;
var IMobileDocCardUtil = new Interface({
  name: 'IMobileDocCardUtil'
});
exports.IMobileDocCardUtil = IMobileDocCardUtil;
IMobileDocCardUtil.prototype.type = String;
IMobileDocCardUtil.prototype.RenderComponent = Object;
IMobileDocCardUtil.prototype.EditComponent = Object;
var IMobileDocAtomUtil = new Interface({
  name: 'IMobileDocAtomUtil'
});
exports.IMobileDocAtomUtil = IMobileDocAtomUtil;
IMobileDocAtomUtil.prototype.type = String;

IMobileDocAtomUtil.prototype.RenderComponent = function () {};

var IGeoPointField = new Interface({
  name: 'IGeoPointField'
});
exports.IGeoPointField = IGeoPointField;
var IRelationField = new Interface({
  name: 'IRelationField'
});
exports.IRelationField = IRelationField;
var IGalleryLayoutField = new Interface({
  name: 'IGalleryLayoutField'
});
exports.IGalleryLayoutField = IGalleryLayoutField;