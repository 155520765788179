import { Component } from 'inferno'
import { Adapter } from 'component-registry'
import { i18n } from '../../../i18n'

import classnames from 'classnames'

import { Card } from 'inferno-bootstrap'
import { Thumbnail } from '../../../widgets/ResponsiveImage'

import { recalcMasonryItemHeight } from '../../common/masonry'

import { IMediaSelectItem } from '../../../interfaces/presentation'
import { IUnsplashImage } from 'influence-app-entities/lib/interfaces/external'
import './MediaSelectItem.scss'

function onSelect (props, event) {
  event.preventDefault()
  props.onSelect(props.context)
}

// <Button onClick={linkEvent(props, onSelect)}>Select</Button>
class MediaSelectItem extends Component {

  state = {
    rowSpan: 15 // Set a good starting point to improve layout speed
  }

  didUpdateMasonry = (rowSpan) => this.setState({ rowSpan, sizeSet: true })

  componentDidMount () {
    recalcMasonryItemHeight.call(this, this._masonryItemEl, this.didUpdateMasonry)
  }

  render (props) {
    const $this = this
    return (
      <Card className={classnames("masonry-item-container media Unsplash-MediaSelectItem MediaSelectItem", { loaded: this.state.sizeSet})}
        style={{ "grid-row-end": `span ${this.state.rowSpan}` }}
        onClick={(e) => onSelect(props, e)}>
        <div ref={(el) => this._masonryItemEl = el} className="masonry-item">
          <img src={props.context.urls.small} />
          <div className="media-body">
            <img src={props.context.user.profile_image.small} />
            <caption><a target="_blank" href={`https://unsplash.com/@${props.context.user.username}`}>{props.context.user.name}</a></caption>
          </div>
        </div>
      </Card>
    )
  }
}

new Adapter({
  implements: IMediaSelectItem,
  adapts: IUnsplashImage,
  Component: MediaSelectItem
})
