import { Adapter, globalRegistry } from 'component-registry'
import { IImageField } from 'influence-app-entities/lib/interfaces/fields'
import ImageFieldWidget from './ImageFieldWidget'
import { interfaces } from 'inferno-formlib'
import './FileUploadUtil'
const { IInputFieldWidget } = interfaces

new Adapter({
  implements: IInputFieldWidget,
  adapts: IImageField,
  Component: ImageFieldWidget
})

export {
  IImageField, 
  ImageFieldWidget
}