import { i18n } from '../../i18n'

import {
    Nav,
    NavLink
} from 'inferno-bootstrap'

export default function WorkflowFilterFieldWidget (props) {
  const field = props.adapter.context
  const workflowStates = field.workflowInterface.schema.workflowStates

  return (
      <Nav pills>
          <NavLink active={props.value === undefined} onClick={(e) => {
            e.preventDefault()
            props.onChange(props.propName, undefined)
          }}>{i18n('IWorkflowFilter-all-label', 'Alla')}</NavLink>
          {Object.keys(workflowStates).map((key) => <NavLink active={props.value === key} onClick={(e) => {
            e.preventDefault()
            props.onChange(props.propName, key)
          }}>{i18n(workflowStates[key].title)}</NavLink>) }
      </Nav>
  )

}