import { Adapter } from 'component-registry'
import { i18n } from '../../i18n'

import { IRelationFieldItem } from '../../interfaces/presentation'
import { IPage } from 'influence-app-entities/lib/interfaces/Page'
import './RelationFieldItem.scss'

function ListItem ({ context: post }) {
  return (
    <div className="RelationField-Page list-item">
      <div className="list-item-body">
        {post.title}
      </div>
    </div>
  )
}

new Adapter({
  implements: IRelationFieldItem,
  adapts: IPage,
  Component: ListItem
})