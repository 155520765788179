import { globalRegistry, createObjectPrototype, Utility } from 'component-registry';
import { IMedia } from '../interfaces/Media';
import { ICard } from '../interfaces/cards/Card';
import { IObjectPrototypeFactory } from 'influence-interfaces/object';
import { IRoleManager, IRoleManagerAdapter } from 'influence-interfaces/permissions';
import { Permissions } from 'influence-permissions/dist/permissions';
import assert from 'assert';
var Media = createObjectPrototype({
  implements: [IMedia],
  extends: [Permissions],
  constructor: function constructor(params) {
    this._type = 'Media';

    this._IPermissions.constructor.call(this, params, {
      create: ['admin', 'customerService', 'member', 'anonymous'],
      // TODO: Anon should not be alowed to create image
      read: ['owner', 'admin', 'customerService', 'anonymous'],
      update: ['owner', 'admin', 'customerService'],
      delete: ['owner', 'admin', 'customerService']
    });
  }
});
export default Media;
var ObjectPrototypeFactory = new Utility({
  implements: IObjectPrototypeFactory,
  name: 'Media',
  getPrototype: function getPrototype() {
    return Media;
  },
  getObject: function getObject(data, roleManager) {
    data = data || {};
    return new Media(data);
  }
});