import { createObjectPrototype, Utility } from 'component-registry';
import { IImage } from '../interfaces/Image';
import { IObjectPrototypeFactory } from 'influence-interfaces/object';
import { Permissions } from 'influence-permissions/dist/permissions';
var Image = createObjectPrototype({
  implements: [IImage],
  extends: [Permissions],
  constructor: function constructor(params) {
    this._type = 'Image';

    this._IPermissions.constructor.call(this, params, {
      create: ['admin', 'customerService', 'member'],
      // TODO: Anon should not be alowed to create image
      read: ['owner', 'admin', 'customerService', 'anonymous'],
      update: ['owner', 'admin', 'customerService'],
      delete: ['owner', 'admin', 'customerService']
    });
  }
});
export default Image;
var ObjectPrototypeFactory = new Utility({
  implements: IObjectPrototypeFactory,
  name: 'Image',
  getPrototype: function getPrototype() {
    return Image;
  },
  getObject: function getObject(data) {
    data = data || {};
    return new Image(data);
  }
});