import { createInterfaceClass } from 'component-registry';
var Interface = createInterfaceClass('influence-app-entities');
import { Schema, TextField, DateTimeField, i18n } from 'isomorphic-schema';
var ILicenseAgreement = new Interface({
  name: 'ILicenseAgreement',
  schema: new Schema('LicenseAgreement Schema', {
    title: new TextField({
      label: i18n('ILicenseAgreement-title-label', 'Title'),
      placeholder: i18n('ILicenseAgreement-title-placeholder', 'Type here...')
    }),
    version: new TextField({
      label: i18n('ILicenseAgreement-version-label', 'Version'),
      placeholder: i18n('ILicenseAgreement-version-placeholder', 'Semver i.e. #.#.#')
    })
  })
});
export { ILicenseAgreement };