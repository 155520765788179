import "./FacebookButton.scss"

/**
 * Render a Facebook login button
 * @param {string} id – Set ID
 * @param {string} className – Add a CSS-class to button
 * @param {string} size – Size of button. Options: small|large|default
 * @param {string} type – Button type, affects text. Options: login|continue 
 * @param {string} onClick – onClick callback
 */
export default function ({ id, className, size = 'default', type = 'continue', onClick }) {
  let msg
  switch (type) {
    case "login":
      if (size === 'small') {
        msg = "Log in"
      }
      else {
        msg = "Login with Facebook"
      }
      break;
    default:
      msg = "Continue with Facebook"
      break;
  }

  let cls = 'fb-btn'
  switch (size) {
    case "small":
      cls += ' fb-btn-small'
      break;
    case "large":
      cls += ' fb-btn-large'
      break;
    default:
      break;
  }

  if (className) {
    cls += ' ' + className
  }


  return (
    <div id={id} className={cls} onClick={onClick}>
      <div className="fb-btn-text">{msg}</div>
      <div className="block" />
    </div>
  )
}