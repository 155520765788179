import { Adapter } from 'component-registry'
import { IImageHelper } from '../../interfaces/presentation'
import { IImage } from 'influence-app-entities/dist/interfaces/Image'

let _env_ = (typeof window !== 'undefined' && window.__env__ ? window.__env__ : process.env )
let { IMAGE_URI_BASE } = _env_
IMAGE_URI_BASE = IMAGE_URI_BASE || '//localhost:3021/images'

function _srcSet(lst, maxWidth) {
  if (!Array.isArray(lst)) return "deprecatedImageObject"

  return lst.filter((obj) => !maxWidth || obj.width <= maxWidth )
  .map((obj) => `${IMAGE_URI_BASE}${obj.imagePath}`, undefined).join(', ')
}

function _src(lst, maxWidth) {
  if (!Array.isArray(lst)) return "deprecatedImageObject"

  const tmp = lst.filter((obj) => !maxWidth || obj.width <= maxWidth )
  if (tmp.length > 0) {
    return `${IMAGE_URI_BASE}${tmp[0].imagePath}`
  }
  else {
    return ''
  }
}

const ImageHelper = new Adapter({
  implements: IImageHelper,
  adapts: IImage,
  getSrc(maxWidth) {
    return _src(this.context.rendered, maxWidth)
  },
  getSrcSet(maxWidth) {
    return _srcSet(this.context.rendered, maxWidth)
  },
  getThumbSrc(maxWidth) {
    return _src(this.context.thumbnails, maxWidth)
  },
  getThumbSrcSet(maxWidth) {
    return _srcSet(this.context.thumbnails, maxWidth)
  }
})