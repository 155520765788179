import { Component } from 'inferno'
import { findDOMNode } from 'inferno-extras'

export class InfiniteScrollContainer extends Component {
  constructor (props) {
    super(...arguments)
    this.didScroll = this.didScroll.bind(this)
  }

  componentDidMount () {
    window.addEventListener('scroll', this.didScroll, {
      passive: true
    })
  }

  componentWillUnmount () {
    window.removeEventListener('scroll', this.didScroll, {
      passive: true
    })
  }

  didScroll (e) {
    if (!(this.props.queryIsLoading || this.animFrame || this.props.inBackground) && this.props.hasMore) {
      this.animFrame = window.requestAnimationFrame(async () => {
        const reloadContainerEl = findDOMNode(this)
        // If we aren't loading the query, check if we need to load more content
        const viewportBottom = window.scrollY + window.innerHeight
        const reloadEdge = reloadContainerEl.offsetTop + reloadContainerEl.offsetHeight
        if ((viewportBottom + this.props.loadMorePadding) > reloadEdge) {
          // load some more
          this.props.onLoadMore()
        }
        delete this.animFrame
      })  
    }  
  }

  render () {
    return <div className={this.props.className}>{this.props.children}</div>
  }
}