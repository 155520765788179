import PageComponent from './PageComponent'
import { safeGet } from 'safe-utils'
import { Container, Col, Row, Button } from 'inferno-bootstrap'
import { Animated } from 'inferno-animation'
import PageTemplate from '../../widgets/PageTemplate'
import { IApiClient, IPageManager } from '../../interfaces/app'
import './ContentPage.scss'
import '../onboarding/RegisterStep.scss'

const env = (typeof window === 'undefined' ? process.env : window.__env__)
const { FRONTEND_BASE_URI } = env

export default class Page extends PageComponent {
  static async fetchData ({registry, match, location, router, page = 1}) {
    new IPageManager({ registry }).setMetaData({
      title: 'Veckoappen - Kontaktuppgifter',
      description: `Veckoappen gör din vardagsplanering enkel och stressfri. Få en tydlig översikt och upplev känslan av att få mer gjort.`,
      url: FRONTEND_BASE_URI + '/contact'
    })

    try {
      const { data } = await new IApiClient({ registry }).query({
          URI: `/content/Page/contact`
      })
      return data
    }
    catch (e) {
      return undefined
    }
  }

  componentDidMount() {
    window.scroll(0, 0)
  }

  render() {
    const page = this.props.fetchData
    const mobiledoc = safeGet(() => page.body)
    if (!mobiledoc) return null
    
    return (
      <PageTemplate>
        <div className="Content">
          <div className="Content-Header">
            <h1>{page.title}</h1>
          </div>
          <div className="Content-Section">
            {this.renderer.render(mobiledoc)}
          </div>
        </div>
      </PageTemplate>
    )
  }
}


/*
<h1>Kontakta Veckoappen</h1>
<p>Beter sig Veckoappen konstigt? Skicka ett mail till oss så är det lättare att lösa problemet. För att lättare kunna hjälpa dig får du gärna bifoga en skärmbild.</p>
<p>Beskriv vad som inte fungerat och vad du förväntade dig, skicka sedan mailet till vår support på <a href="mailto:info@veckoappen.se">info@veckoappen.se</a>.</p>
*/