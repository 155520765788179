import { Component } from 'inferno'
import { Container } from 'inferno-bootstrap'
import { Animated } from 'inferno-animation'
import { ICreateForm } from '../../interfaces/presentation'
import { IApiClient, ISessionManager } from '../../interfaces/app'
import { IObjectPrototypeFactory } from 'influence-interfaces/object'
import './Create.scss'

export class CreatePage extends Component {

    static async fetchData ({match, location}) {
      // Just triggering update of RoleManager
      return
    }

    render (props, state, context) {
      const EditForm = new ICreateForm(this.context.router.route.match.params.type).Component

      return (
        <Animated key="me" className="EditPage" prefix="PageNav">
          <Container fluid>
              <EditForm {...this.props} />
          </Container>
        </Animated>
      )
    }
}
