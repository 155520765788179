import { Utility } from 'component-registry'
import { Component, Fragment } from 'inferno'
import classnames from 'classnames'
import { CardButtonBase, getPostId, getPostSlug, insertCard, maxImages } from './common'
import { i18n } from '../../i18n'
import YouTube from 'inferno-youtube';

import { IVideoCard } from 'influence-app-entities/lib/interfaces/cards/VideoCard';

import 'influence-app-entities/lib/cards/VideoCard';
import { IObjectPrototypeFactory } from 'influence-interfaces/object'
import { ISessionManager } from '../../interfaces/app'
import '../../formlib/ImageField'
import '../../formlib/GalleryLayoutField'
import IconVideoCard from '../../img/icons/youtube'
import IconArrow from '../../img/icons/arrow'
import IconVideoPlay from '../../img/icons/video_play'


import {
  Form,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Media,
  MediaBody } from 'inferno-bootstrap'
import { FormRows } from 'inferno-formlib'
import { ResponsiveImage } from '../ResponsiveImage'

import { IMobileDocCardUtil, IMobileDocCardButton } from '../../interfaces/formlib'
import './common.scss'
import './MediaCard.scss'
import './VideoCard.scss'

function Icon(props) {
  return <img {...props} />
}

/**
 * Component-based cards are rendered with these props:
 *
 * - `payload` The payload for this card. Note: the payload object is
 *    disconnected from the card's payload in the serialized mobiledoc.
 *    To update the mobiledoc payload, use the `save` callback.
 * - `edit` A callback for toggling this card into edit mode (no-op
 *    if the card is already in edit mode).
 * - `remove` A callback for removing this card.
 * - `save` A callback accepting new payload for the card, then
 *    saving that payload and toggling this card into display mode.
 *    Can optionally be passed an extra `false` argument to avoid
 *    toggling to display mode.
 * - `cancel` A callback for toggling this card to display mode
 *    without saving (a no-op if the card is already in display mode).
 * - `name` The name of this card.
 * - `postModel` A reference to this card's model in the editor's
 *    abstract tree. This may be necessary to do programmatic editing.
 * - `isEditing` A bool indicating if the card is in Edit mode or not.
 * - `isInEditor` A bool indicating if the card is displayed inside an
 *    editor interface or not.
 */

class ImageRender extends Component {

  state = {
    showVideo: false
  }

  doPlay = (e) => {
    e.preventDefault()

    if (this.props.isInEditor) {
      this.props.edit(e)
    }
    else {
      this.setState({
        showVideo: true
      })
    }

  }

  didVideoReady = (event) => {
    const ua = window.navigator.userAgent
    const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i)
    if (iOS) {
      setTimeout(() => {
        this.setState({
          videoPlaying: true
        })
      }, 300)
    }
  }

  didStateChange = (stateObj) => {
    const { data: state } = stateObj
    switch (state) {
      case 1:
        this.setState({
          videoPlaying: true
        })
        break;
      case 0:
        this.setState({
          videoPlaying: false
        })

        // Hide the video player if we have a poster image so we can restart it
        if (this.props.payload.image) {
          setTimeout(() => {
            this.setState({
              showVideo: false
            })
          }, 500)
        }
        break;
    }
  }

  renderVideo({ payload }) {
    const opts = {
      height: '100%',
      width: '100%',
      modestbranding: 1,
      playerVars: { // https://developers.google.com/youtube/player_parameters
        autoplay: (payload.image ? 1 : 0)
      }
    }

    // TODO: This is a hack for youtube
    const videoId = payload.videoUrl.split('v=')[1]

    const renderPosterImage = !!payload.image
    const renderVideo = !payload.image || this.state.showVideo === true

    return (
      <div className={classnames("video-player", { "video-playing": this.state.videoPlaying })}>
        {renderPosterImage && <div className="poster-image">
          <ResponsiveImage {...payload} onClick={this.doPlay} />
          {true && <IconVideoPlay className="video-play" />}
        </div>}
        {renderVideo && <div className="video">
          <YouTube
            videoId={videoId}
            opts={opts}
            onReady={this.didVideoReady}
            onStateChange={this.didStateChange} />
        </div>}
      </div>
    )
  }

  render ({ isInEditor, payload, edit }) {
    return (
      <Media vertical className="VideoCard">
        {this.renderVideo({ isInEditor, payload, edit })}
        <MediaBody>
          <small>{payload.shortDescription}</small>
        </MediaBody>
        {isInEditor && <Button className="CardEditButton" color="link" onClick={edit} style={{color: 'white'}}>Edit</Button>}
      </Media>
    )
  }
}


class ImageEdit extends Component {

  constructor ({ payload }, context) {
    super(...arguments)

    this.state = {
      validationErrors: undefined,
      value: payload || new IObjectPrototypeFactory('VideoCard').getObject({}),
      isOpen: true
    }

    // Prevents animations on mounting of form
    this.isMounted = false
  }

  componentDidMount () {
    this.isMounted = true
  }
  
  componentWillUnmount () {
    console.log('leaving world')
  }

  didChange = (propName, value) => {
    const newValue = Object.assign({}, this.state.value)
    newValue[propName] = value
    
    this.setState({
      value: newValue
    })
  }

  doCancel = () => {
    // TODO: Check if dirty
    this.setState({ isOpen: false })
    setTimeout(() => this.props.cancel(), 300)
  }

  doSubmit = (e) => {
    e.preventDefault()
    const validationErrors = IVideoCard.schema.validate(this.state.value)
    this.setState({
      validationErrors
    })

    if (validationErrors === undefined) {
      // TODO: If using placeholder, update VideoCard with proper Image (should be used by Unsplash)
      
      // Submit changes
      this.setState({ isOpen: false })
      setTimeout(() => this.props.save(this.state.value), 300)
    } else {
      // TODO: Show error message
    }
  }

  render () {
    return (
      <Fragment>
        {this.props.children /* This is the actual widget so it is displayed during editing*/}

        <Modal className="VideoCardModal EditModal" isOpen={this.state.isOpen} toggle={this.doCancel}>
          <Form onSubmit={this.doSubmit}>
            <ModalHeader>
              Edit Media Card
            </ModalHeader>
            <ModalBody>
              <FormRows
                value={this.state.value}
                schema={IVideoCard.schema}
                omitFields="fileType"
                validationErrors={this.state.validationErrors}
                isMounted={this.isMounted}

                onChange={this.didChange} />
            </ModalBody>
            <ModalFooter>
              <Button onClick={this.doCancel} color="link">Cancel</Button>
              <Button type="submit" color="success">Save</Button>
            </ModalFooter>
          </Form>
              
        </Modal>

      </Fragment>
    )
  }
}

new Utility({
  implements: IMobileDocCardUtil,
  name: 'VideoCard',
  type: 'dom',
  RenderComponent: ImageRender,
  EditComponent: ImageEdit
})

new Utility({
  implements: IMobileDocCardUtil,
  name: 'VideoCard',
  type: 'dom',
  RenderComponent: ImageRender,
  EditComponent: ImageEdit
})

export class VideoButton extends CardButtonBase {
  name = 'VideoCard'

  render () {
    return (
      <Media className="mt-1" onClick={this.doShowModal}>
        {this.state.showModal && <ImageEdit save={this.doSubmit} cancel={this.doHideModal} />}
        <IconVideoCard />
        <MediaBody>
          <h4>Youtube Video <IconArrow className="arrow" /></h4>
          <p>Embed Youtube videos in your content.</p>
        </MediaBody>
      </Media>
    )
  }
}

new Utility({
  implements: IMobileDocCardButton,
  name: 'VideoCard',
  Component: VideoButton
})