import { Component } from 'inferno'
import { Adapter } from 'component-registry'
import classnames from 'classnames'
import { i18n } from '../../../i18n'

import { Card } from 'inferno-bootstrap'
import { ResponsiveImage } from '../../../widgets/ResponsiveImage'
import { recalcMasonryItemHeight } from '../../common/masonry'

import { ILiveListItem, ILightbox } from '../../../interfaces/presentation'
import { IMediaCard } from 'influence-app-entities/lib/interfaces/cards/MediaCard'
import '../LiveListItem.scss'
import './LiveListItem.scss'

class ListItem extends Component {
  state = {
    lightboxOpen: false,
    rowSpan: 15 // Set a good starting point to improve layout speed
  }

  didUpdateMasonry = (rowSpan) => this.setState({ rowSpan, sizeSet: true })

  componentDidMount () {
    recalcMasonryItemHeight.call(this, this._masonryItemEl, this.didUpdateMasonry)
  }

  doOpen = (e) => {
    e.preventDefault()

    this.setState({
      lightboxOpen: true
    })
  }

  toggle = () => {
    this.setState({
      lightboxOpen: !this.state.lightboxOpen
    })
  }

  render (props, state, context) {
    const Lightbox = new ILightbox(props.context).Component
    return (
      <Card className={classnames("masonry-item-container list-item card media", { loaded: this.state.sizeSet })}
           style={{ "grid-row-end": `span ${this.state.rowSpan}` }}>
        <div ref={(el) => this._masonryItemEl = el} className="masonry-item">
          <ResponsiveImage {...props.context} thumbnail maxWidth={320} onClick={this.doOpen} />
          <Lightbox image={props.context} isOpen={this.state.lightboxOpen} onToggle={this.toggle} />
        </div>
      </Card>
    )
  }
}

export {
  ListItem
}

new Adapter({
  implements: ILiveListItem,
  adapts: IMediaCard,
  Component: ListItem
})