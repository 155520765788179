import { Utility } from 'component-registry'
import axios from 'axios'
import { i18n } from '../../i18n'
import { interfaces } from 'inferno-formlib'
import { IObjectPrototypeFactory } from 'influence-interfaces/object'
import { IApiClient, ISessionManager } from '../../interfaces/app'
const { IFileUploadUtil } = interfaces


const _env_ = (typeof window !== 'undefined' && window.__env__ ? window.__env__ : process.env )
if (!_env_['API_UPLOAD_URI']) {
  // TODO: This is literally dev settings and should really be configured in a single place
  _env_['API_UPLOAD_URI'] = 'http://localhost:3021/upload'
}
const { API_UPLOAD_URI } = _env_

export const FileUploadUtil = new Utility({
  implements: IFileUploadUtil,
  name: 'Image',

  upload: async function (file, onProgress) {
      var config = {
          withCredentials: true,
          onUploadProgress: function(progressEvent) {
              var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total )
              onProgress(percentCompleted)
          }
      };

      const formData = new FormData();
      formData.append('file', file);
      
      const res = await axios.post(API_UPLOAD_URI, formData, config)
      
      // Create the actual Image object which references the image file
      const image = new IObjectPrototypeFactory('Image').getObject(res.data)
      // Also create a Media object which contains a reference to the Image
      // and also allows tagging and searching in the media library
      const media = new IObjectPrototypeFactory('Media').getObject({
        shortDescription: file.name,
        fileType: file.type,
        image
      })

      const { data } = await new IApiClient().create({
        URI: '/content/Media',
        data: media,
        invalidate: '/content/Media'
      })

      // Pass the Media object to the uploader
      return Promise.resolve(data)
  },

  delete: function (uri) {
      // TODO: Implement this bummer
  }
})
