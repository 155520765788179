import { createInterfaceClass } from 'component-registry';
var Interface = createInterfaceClass('influence-app-entities');
import { i18n } from 'isomorphic-schema';
/**
 * IMPORTANT           IMPORTANT           IMPORTANT           IMPORTANT           IMPORTANT
 * 
 * When adding a workflow you also need to add the corresponding property to the
 * Elasticsearch index and migrate the database!!!
 * 
 * Make sure you update the definition in 
 *  - cli/helpers/elasticsearch
 *  - packages/AdminApi/README.md
 * 
 * Basic workflows:
   "workflows": {
			"properties": {
				"publishWorkflow": { "type": "keyword" },
				"userWorkflow": { "type": "keyword" },
				"invitationWorkflow": { "type": "keyword" }
			}
    }
 *
 * IMPORTANT           IMPORTANT           IMPORTANT           IMPORTANT           IMPORTANT
 */

export var IPublishWorkflow = new Interface({
  // Interface for PublishWorkflow
  name: 'IPublishWorkflow',
  schema: {
    statePropName: 'publishWorkflow',
    workflowStates: {
      draft: {
        title: i18n('IPublishWorkflow-option-draft', 'Draft')
      },
      published: {
        title: i18n('IPublishWorkflow-option-published', 'Published')
      },
      trash: {
        title: i18n('IPublishWorkflow-option-trash', 'Trash')
      }
    }
  }
});
export var IInvitationWorkflow = new Interface({
  // Interface for InvitationWorkflow
  name: 'IInvitationWorkflow',
  schema: {
    statePropName: 'invitationWorkflow',
    workflowStates: {
      draft: {
        title: i18n('IInvitationWorkflow-option-draft', 'Draft')
      },
      pending: {
        title: i18n('IInvitationWorkflow-option-pending', 'Pending')
      },
      activated: {
        title: i18n('IInvitationWorkflow-option-activated', 'Activated')
      },
      trash: {
        title: i18n('IInvitationWorkflow-option-trash', 'Trash')
      }
    }
  }
});
export var IUserWorkflow = new Interface({
  // Interface for InvitationWorkflow
  name: 'IUserWorkflow',
  schema: {
    statePropName: 'userWorkflow',
    workflowStates: {
      active: {
        title: i18n('IUserWorkflow-option-active', 'Active')
      },
      investigate: {
        title: i18n('IUserWorkflow-option-investigate', 'Investigate')
      },
      suspended: {
        title: i18n('IUserWorkflow-option-suspended', 'Suspended')
      },
      pendingDelete: {
        title: i18n('IUserWorkflow-option-pendingDelete', 'To Be Delete')
      }
    }
  }
});