import { Adapter } from 'component-registry'
import { i18n } from '../../../i18n'

import {
  Card,
  CardBody,
  CardFooter,
  CardImg,
  Row,
  Col
} from 'inferno-bootstrap'
import { Thumbnail } from '../../../widgets/ResponsiveImage'
import { FormRows } from 'inferno-formlib'

import { IMediaSelectDetails } from '../../../interfaces/presentation'
import { IUnsplashImage } from 'influence-app-entities/lib/interfaces/external'

function MediaSelectDetails (props) {
  return (
    <Card className="MediaSelectDetails">
      <img className="card-img-top" width="100%" src={props.context.urls.small} />
      <CardBody>
        <p>{props.context.description}</p>
        {/*<FormRows
          schema={props.context._implements[0].schema}
          value={props.context}
          onChange={() => null} />*/}
      </CardBody>
      {props.children && <CardFooter>
        {props.children}
      </CardFooter>}
    </Card>
  )
}

new Adapter({
  implements: IMediaSelectDetails,
  adapts: IUnsplashImage,
  Component: MediaSelectDetails
})
