import { createObjectPrototype, Utility } from 'component-registry';
import { IAccount } from '../interfaces/Account';
import { IAccountFacebook } from '../interfaces/AccountFacebook';
import { IObjectPrototypeFactory } from 'influence-interfaces/object';
var AccountFacebook = createObjectPrototype({
  implements: [IAccountFacebook, IAccount],
  constructor: function constructor(params) {
    this._type = 'AccountFacebook';
  }
});
export default AccountFacebook;
var ObjectPrototypeFactory = new Utility({
  implements: IObjectPrototypeFactory,
  name: 'AccountFacebook',
  getPrototype: function getPrototype() {
    return AccountFacebook;
  },
  getObject: function getObject(data) {
    return new AccountFacebook(data);
  }
});