import { Component } from 'inferno'
import { findDOMNode } from 'inferno-extras'
import { globalRegistry } from 'component-registry'
import { safeGet } from 'safe-utils'
import { i18n } from '../i18n'
import { getElOffset } from 'inferno-formlib'
import { ISessionManager } from '../interfaces/app'

import { IMobileDocCardUtil, IMobileDocCardButton, IMobileDocAtomUtil } from '../interfaces/formlib'
import {
  cardRenderer,
  utilityToCard,
  atomRenderer,
  utilityToAtom
 } from 'inferno-mobiledoc-editor'
import * as ReactMobiledoc from 'inferno-mobiledoc-editor'
import {
  MarkupButton,
  SectionButton,
  LinkButton,
  SectionSelect
} from 'inferno-mobiledoc-editor'

import {
  ButtonGroup,
  Button
} from 'inferno-bootstrap'
import { IDeserialize } from 'influence-interfaces/object'
import { MentionInputHandler } from './cards/MentionAtom'
import { CardPanel } from './CardPanel'

import './RichTextWidget.scss'
import listBullet_svg from '../img/icons/list_bullet.svg'
import listNumber_svg from '../img/icons/list_number.svg'
import link_svg from '../img/icons/link.svg'
import blockQuote_svg from '../img/icons/block_quote.svg'
import italic_svg from '../img/icons/italic.svg'
import bold_svg from '../img/icons/bold.svg'

const mobiledoc = {
  version: "0.3.0",
  markups: [],
  atoms: [],
  cards: [],
  sections: []
}

const willCreateEditor = () => { console.log('creating editor...') }

function _getCursorOffset (editor) {
  const parentEl = editor.range.tail.marker.renderNode.markupElement.parentElement
  let textNode = editor.range.tail.marker.renderNode.markupElement
  while (textNode instanceof Element) {
    textNode = textNode.firstChild
  }

  const clone = document.createElement('div')
  // Traverse through previous siblings in this renderNode to allow us to find
  // the cursor offset using editor.range.tailSectionOffset
  // Some test cases: First text node, inline element (em/i), nested inline element, last text node
  let _curr = textNode
  while (_curr !== null && _curr !== parentEl) {
    if (_curr.previousSibling === null) {
      _curr = _curr.parentElement
    }

    if (_curr && _curr !== parentEl) {
      _curr = _curr.previousSibling ? _curr.previousSibling : _curr.parentNode.previousSibling
      
      if (_curr) {
        // Add all nodes to that element
        clone.prepend(_curr.cloneNode(true))
      }
    }
  }
  
  // _.tailSectionOffset_ is the character position within parent element
  // _.splitText_ takes the character position within the current text node
  let iEl
  if (editor.range.tailSectionOffset > 0) {
    textNode.splitText(editor.range.tailSectionOffset - clone.innerText.length)
    textNode.after(document.createElement('i'))
    iEl = textNode.nextElementSibling
  }
  else {
    textNode.before(document.createElement('i'))
    iEl = textNode.previousElementSibling
  }
  
  let cursorPosY = iEl.offsetTop + iEl.offsetHeight
  _curr = iEl.offsetParent
  while (_curr !== null) {
    cursorPosY += _curr.offsetTop
    _curr = _curr.offsetParent
  }

  iEl.remove()
  textNode.parentNode.normalize()

  // Debug: console.log('Cursor position: ' + cursorPosY)
  return { y: cursorPosY }
}

var _prevEl
const didCreateEditor = (e) => {
  _prevEl = undefined
}

function _scrollTo(el, y) {
  if (el === window) {
    window.scrollTo(0, y)
  }
  else {
    el.scroll(0, y)
  }
}

const onCursorDidChange = (editor, currCursorPos, scrollableEl) => {
  if (!scrollableEl) return

  // Keep cursor position at last known location
  const renderNode = editor.range.tail.marker.renderNode
  if (renderNode === undefined || renderNode.markupElement === undefined) {
    return currCursorPos
  }

  const { parentElement: element } = editor.range.tail.marker.renderNode.markupElement
  
  let newCursorPosY
  // TODO: Find other way to compare!
  if (_prevEl !== null || _prevEl.isSameNode(element)) {
    // TODO: Don't scroll if element is out of viewport
    if (editor.range.isCollapsed) {
      // Only do this if range is collapsed (at least for now)
      const { y: cursorPosY } = _getCursorOffset(editor)
      newCursorPosY = cursorPosY

      if (currCursorPos !== undefined && currCursorPos === newCursorPosY) {
        // currCursorPos is only passed on animFrames. If we haven't
        // moved the cursor we shouldn't scroll the page
        return currCursorPos
      }
      
      const scrollY = scrollableEl === window ? window.scrollY : scrollableEl.scrollTop

      if (scrollY > (cursorPosY - 100)) {
        // Cursor is scrolled out of view
        _scrollTo(scrollableEl, (cursorPosY - 100) > 0 ? cursorPosY - 100 : 0)
      }
      else if ((cursorPosY + 100) > (scrollY + window.innerHeight) ) {
        _scrollTo(scrollableEl, cursorPosY + 100)
      }


    }
  }
  else {
    // Measure where we are in document by
    console.log('jumped')
  }
  
  _prevEl = element
  return newCursorPosY
}

function Icon(props) {
  return <img {...props} />
}

class CardDropDown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dropdownOpen: false
    };
  }

  toggle = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    })
  }

  openModal = (e) => {
    const { editor } = this.context

    this.setState({
      dropdownOpen: true,
      range: editor.range
    })
  }

  didInsert = () => {
    this.setState({
      dropdownOpen: false,
      range: undefined
    })
  }

  render() {
    const { editor } = this.context
    const cardBtnUtils = new IMobileDocCardButton('*')
    
    return (
      <div className="dropdown" onClick={this.openModal}>
        <button className="dropdown-toggle btn btn-secondary" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" type="button">
          Cards
        </button>
        <CardPanel range={this.state.range} onInsert={this.didInsert} isOpen={this.state.dropdownOpen} toggle={this.toggle} />
      </div>
    );
  }
}

const Toolbar = ({onClose, ...props}) => <div {...props}>
  <div className="shadow-block" />
  <ButtonGroup className="Actions">
    <Button color="link" className="CloseButton" onClick={onClose}>X Close</Button>
    <SectionSelect className="ParagraphStyle" tags={[
      ["Header 1", "h1"],
      ["Header 2", "h2"],
      ["Header 3", "h3"],
      ["Paragraph", "p"]
    ]} title="Other..." />
    <ButtonGroup>
      <MarkupButton className="btn btn-secondary" tag='strong'><Icon src={bold_svg} /></MarkupButton>
      <MarkupButton className="btn btn-secondary" tag='em'><Icon src={italic_svg} /></MarkupButton>
    </ButtonGroup>
    <ButtonGroup>
      <LinkButton className="btn btn-secondary"><Icon src={link_svg} /></LinkButton>
      <SectionButton className="btn btn-secondary" tag='blockquote'><Icon src={blockQuote_svg} /></SectionButton>
      <SectionButton className="btn btn-secondary" tag='ul'><Icon src={listBullet_svg} /></SectionButton>
      <SectionButton className="btn btn-secondary" tag='ol'><Icon src={listNumber_svg} /></SectionButton>
    </ButtonGroup>
    <ButtonGroup>
      <CardDropDown />
    </ButtonGroup>
    <div className="spacer" />
  </ButtonGroup>
</div>

// TODO: convert RichTextWidget to stateful component and create the config in the constructor
// Mount the cardRenderer method from the classToCard.js file as a bound method and it can
// access the context object.

class RichTextWidget extends Component {

  constructor(props, context) {
    super(...arguments)

    const cardUtils = globalRegistry.getUtilities(IMobileDocCardUtil)
    const atomUtils = globalRegistry.getUtilities(IMobileDocAtomUtil)
    const deserializeUtil = new IDeserialize('admin-api')

    this.mobiledoc_config = {
      cards: cardUtils.map(util => utilityToCard(util, cardRenderer.bind(this), deserializeUtil)),
      atoms: atomUtils.map(util => utilityToAtom(util, atomRenderer.bind(this))),
      placeholder: "Welcome to Mobiledoc!",
      willCreateEditor,
      didCreateEditor,
      onAnimFrame: (editor) => {
        try {
          this._cursorPosY = onCursorDidChange(editor, this._cursorPosY, this._scrollableEl)
        }
        catch (e) {
          // Do nothing, this triggers sometimes on unmount
        }
      }
    }
  }

  componentDidMount() {
    this._scrollableEl = document.getElementsByClassName(this.props.scrollElCls || 'App')[0]
  }

  render (props) {
    return (               
      <div className="InfernoFormlib-FormRows">
        <ReactMobiledoc.Container
          mobiledoc={props.value || mobiledoc}
          {...this.mobiledoc_config}
          onChange={props.onChange}>
          <MentionInputHandler />
          <Toolbar className="Toolbar" onClose={this.props.onClose} />
          <ReactMobiledoc.Editor />
        </ReactMobiledoc.Container>
      </div>
    )
  }
}

function calculateToolbarBoundary () {
  if (!this._editor) { return };
  
  // TODO: Remove jQuery and use Inferno calls
  var editorEl = findDOMNode(this._editor)
  var topBoundaryNode = this._containerEl; // TODO: We shouldn't hard code this!!!
  
  var bottomBoundary = getElOffset(editorEl).top + editorEl.clientHeight;
  var topBoundary = getElOffset(topBoundaryNode).top;
  
  this.setState({
      toolbarBoundary: { 
          top: topBoundary, 
          bottom: bottomBoundary
      }
  });
}

export {
  RichTextWidget,
  calculateToolbarBoundary,
}