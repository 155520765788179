import { Adapter, Utility } from 'component-registry'
import { Component } from 'inferno'
import classnames from 'classnames'
import { safeGet } from 'safe-utils'
import { i18n } from '../../i18n'
import { renderTimedPublishingString } from '../../widgets/utils'

import { widgets, FormRows } from 'inferno-formlib'
//import 'inferno-formlib/lib/widgets/FormRow'
//import 'inferno-formlib/lib/widgets/InputField'
//import 'inferno-formlib/lib/widgets/DateTimeField'
import { Manager, Target, Popper, Arrow, Travel } from 'inferno-popper'

import {
    Button,
    ButtonGroup,
    Nav,
    NavDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Col,
    Row,
    Form
} from 'inferno-bootstrap'

import '../../formlib/MobileDocField'

import {
  RichTextWidget,
  calculateToolbarBoundary,
} from '../../widgets/RichTextWidget'

import { IObjectPrototypeFactory } from 'influence-interfaces/object'
import { INameDay } from 'veckoappen-app-entities/lib/interfaces/NameDay'
import { ICreateForm, IEditForm } from '../../interfaces/presentation'
import { ISessionManager, IApiClient } from '../../interfaces/app'
import { INotificationManager } from '../../interfaces/presentation'
import { IPublishWorkflow } from 'influence-app-entities/lib/interfaces/workflows'

import IconClock from '../../img/icons/clock'

import '../../pages/admin/Create.scss'
import '../Page/EditForm.scss'

const { ActionBar } = widgets

const IS_DEV = process.env.NODE_ENV !== 'production'

const workflowStates = IPublishWorkflow.schema.workflowStates

function doChangeWorkflowState (obj, newState) {
  obj._workflows.publishWorkflow = newState
  new IApiClient().update({
    URI: `/content/NameDay/${obj._id}`,
    data: obj,
    invalidate: `/content/NameDay`
  }).then(({data}) => {
    // Show success indicator at center of screen
    new INotificationManager().showSuccessMessage()
  })
}

function WorkflowStatus ({ post, isOpen, doOpen, doClose }) {
  return (
    <Nav className="WorkflowStatus">
      <NavDropdown isOpen={isOpen} toggle={() => { isOpen ? doClose() : doOpen()}}>
        <DropdownToggle nav caret className="status">
          {safeGet(() => post._workflows.publishWorkflow)}
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem header>{i18n('WorkflowAction-updateStatus', 'Change Status')}</DropdownItem>
          {Object.keys(workflowStates).map((key) => {
            // Don't return the current state since we don't want to transition to ourself
            const isActive = (post._workflows && post._workflows.publishWorkflow) === key
            if (isActive) return null
    
            return (
              <DropdownItem onClick={(e) => doChangeWorkflowState(post, key)}>
                {i18n('WorkflowAction-to', 'till ') + i18n(workflowStates[key].title)}
              </DropdownItem>
            )
          })}
        </DropdownMenu>
      </NavDropdown>
    </Nav>
  )
}

class EditForm extends Component {

    constructor (props, context) {
        super(...arguments)

        this.state = {
            value: props.value || new IObjectPrototypeFactory('NameDay').getObject({}),
            validationError: undefined,
            toolbarBoundary: {top: 0, bottom: 0},
            actionBarBoundary: {top: 0, bottom: 0},
            isDirty: false,
            popoverWorkflowStatusOpen: false,
            popoverTimedPublishingOpen: false,
            showCloseWarningModal: false
        }

        this.calculateToolbarBoundary = calculateToolbarBoundary.bind(this)        
    }

    

    componentDidMount () {
        this.calculateToolbarBoundary();
    }

    doOpenWorkflowStatus = (e) => {
      e && e.preventDefault()
      this.setState({
        popoverWorkflowStatusOpen: true
      });
    }

    doCloseWorkflowStatus = (e) => {
      e && e.preventDefault()
      this.setState({
        popoverWorkflowStatusOpen: false
      });
    }

    doOpenTimedPublishing = (e) => {
      e && e.preventDefault()
      this.setState({
        popoverTimedPublishingOpen: true
      });
    }

    doCloseTimedPublishing = (e) => {
      e && e.preventDefault()
      this.setState({
        popoverTimedPublishingOpen: false
      });
    }

    didUpdate = (propName, value) => {
        const isNewValue = JSON.stringify(value) !== JSON.stringify(this.state.value[propName])
        const isDirty = this.state.isDirty || isNewValue
        
        if (isNewValue) {
          const newVal = this.state.value
          newVal[propName] = value
  
          this.setState({
              value: newVal,
              isDirty
          })
        }
        this.calculateToolbarBoundary()
    }

    doClose = (e) => {
      let forceClose
      if (e.force) {
        forceClose = true
      }

      if (e.event) {
        // setting event param
        e = e.event
      }

      e.preventDefault()
      
      if (this.state.isDirty && !forceClose) {
        this.setState({
          showCloseWarningModal: true
        })
      }
      else {
        this.context.router.history.push(`/admin/NameDay`)
      }
    }

    doSave = ({ publish }) => {
        this.doCloseWorkflowStatus()

        let post = this.state.value
        if (publish) {
          post._workflows.publishWorkflow = 'published'
        }

        if (post._id) {
            return new IApiClient().update({
                URI: `/content/NameDay/${this.state.value._id}`,
                data: post,
                invalidate: `/content/NameDay`
            }).then(({data}) => {
                // TODO: Show success indicator at center of screen
                new INotificationManager().showSuccessMessage()
                this.setState({
                  value: data,
                  isDirty: false
                })
            })
        } else {
            return new IApiClient().create({
                URI: `/content/NameDay`,
                data: post,
                invalidate: `/content/NameDay`
            }).then(({data}) => {
                // TODO: Show success indicator at center of screen
                new INotificationManager().showSuccessMessage()
                this.context.router.history.push(`/admin/NameDay/${data._pathId || data._id}`)
            })
        }
    }

    renderCloseWarningModal () {
      return (
        <Modal isOpen={this.state.showCloseWarningModal} toggle={() => this.setState({ showCloseWarningModal: false })}>
          <ModalHeader>WARNING! Unsaved changes</ModalHeader>
          <ModalBody>
            <p>You have unsaved changes, are you sure you want to close without saving?</p>
          </ModalBody>
          <ModalFooter className="ActionBarContent">            
              <Button color="primary"
                onClick={(e) => {e.preventDefault(); this.setState({ showCloseWarningModal: false })}}>Cancel</Button>

              <i>or</i>
              
              <Button color="danger" outline
                onClick={(e) => {this.doClose({ event: e, force: true })}}>Close</Button>
              
              <i>or</i>
              
              <Button color="link"
                onClick={(e) => {this.doSave({}).then(() => this.doClose({ event: e }))}}>Save & close</Button>
          </ModalFooter>
        </Modal>
      )
    }

    renderSaveActions () {
      switch (this.state.value._workflows.publishWorkflow) {
        case 'published':
          return (
            <div className="ActionBarContent-MainActions">
              <Button color="success" type="submit">Update</Button>
            </div>
          )   
        case 'draft':
          return (
            <div className="ActionBarContent-MainActions">
              <Button color="success" type="submit">Save to draft</Button>
              <i>or</i>
              <Button color="link" onClick={(e) => {e.preventDefault(); this.doSave({ publish: true })}}>Save and publish</Button>
            </div>
          )
        default:
          return (
            <div className="ActionBarContent-MainActions">
              <Button color="success" type="submit">Save</Button>
            </div>
          )
      }
    }

    renderSecondaryActions () {

      return (
        <div className="ActionBarContent-SecondaryActions">
          <WorkflowStatus post={this.state.value} isOpen={this.state.popoverWorkflowStatusOpen}
              doOpen={this.doOpenWorkflowStatus}
              doClose={this.doCloseWorkflowStatus} />
        </div>
      )
    }

    render () {
        return (
            <div ref={(e) => this._containerEl = e}>
                {this.state.modalWidget}
                <Form onSubmit={(e) => {e.preventDefault(); this.doSave({})}} className='IEditItem'>

                    <FormRows
                      schema={INameDay.schema}
                      omitFields="body"
                      validationErrors={this.state.validationError}
                      value={this.state.value} onChange={this.didUpdate} />

                    <h3>Body</h3>
                    <RichTextWidget
                        toolbarBoundary={this.state.toolbarBoundary}
                        doSetEditor={(e) => this._editor = e}
                        onChange={(mobiledoc) => this.didUpdate('body', mobiledoc)}
                        onClose={this.doClose}
                        
                        placeholder="type here..."
                        value={this.state.value && this.state.value.body}

                    />
                    <div className="ActionBar" boundary={this.state.actionBarBoundary}>
                        {/* Adding a key for ActionBarContent forces rerender when publish state is changed.
                            This makes sure that floating bar and sticky bar i in sync */}
                        <div key={this.state.value._workflows.publishWorkflow} className="ActionBarContent">
                            {this.renderSaveActions()}
                            {this.renderSecondaryActions()}
                        </div>
                    </div>
                </Form>
                {this.renderCloseWarningModal()}
            </div>
        )
    }
}

new Adapter({
  implements: IEditForm,
  adapts: INameDay,
  Component: EditForm
})

new Utility({
  implements: ICreateForm,
  name: 'NameDay',
  Component: EditForm
})


const dummyHtml = `
<h2>Grattis på kvinnodagen alla fantastiska kvinnor!!!</h2>
<p>Vilken viktig dag detta är,&nbsp;som&nbsp;behövs. Vi är dessutom verkligen värda att hyllas på alla sätt som går. Fick alldeles nyss detta fantastiska bud från Nelly.com med deras Sisterhood t-shirt där alla intäkter går oavkortat till UNHCR och deras arbete med skydd för kvinnor och flickor på flykt. Verkligen någon värt att blogga om, läs mer/köp den tröjan <a href="https://nelly.com/se/nelly-sister-hood-campaign-33040/" target="_blank">här</a>.BRA, viktigt och fint intiativ av Nelly tycker jag!&nbsp;</p>
<p>Jag älskar verkligen&nbsp;att arbeta i en kvinnodominerad branch, där tjejer styr och tar för sig. En branch kvinnor skapat arbeten för sig själva. Där vi faktiskt bestämmer, ha kontrollen och gör ett bra jobb. Jag älskar att alla mina bloggkollegor är tjejer, att jag når ut till så många fantastiska tjejer och jag älskar framförallt allt ni ger mig. Hur mycket girlpower är det inte i kommentarsfältet i inlägget med min kollektion? Helt sjukt! Exakt så det ska vara. Vi ska höja varandra och aldrig trycka ner varandra det gör män tillräckligt ändå, eller hur?</p><p>Mina ord till er är att alltid stå på er, alltid vara er själva, inte låta någon eller något hindra er. Låt alla era drömmar bli verklighet och gör allt ni vill. Nu ska jag iväg på två möten med grymma tjejer som har coola jobb. Det inspirerar mig. &lt;3&nbsp;</p><p>Nu måste jag springa, puss och kram ni alla är heeelt fantastiska!!!</p>
`
