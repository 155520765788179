import { Component } from 'inferno'
import { createElement} from 'inferno-create-element'
import { Adapter } from 'component-registry'
import { safeGet } from 'safe-utils'
import { i18n } from '../../i18n'
import { utilityToCard, utilityToAtom } from '../../formlib/MobileDocField/utils'

import MobiledocRenderer from '../../widgets/RenderMobiledoc'
import { globalRegistry } from 'component-registry'
import { IMobileDocCardUtil, IMobileDocAtomUtil } from '../../interfaces/formlib'

import { ILiveItem } from '../../interfaces/presentation'
import { IPage } from 'influence-app-entities/lib/interfaces/Page'

import clock_svg from '../../img/icons/clock.svg'
import './LiveItem.scss'

function IconClock() {
  return <img className="Icon-Clock" src={clock_svg} />
}

function renderTimedPublishingBanner (post) {
  const now = new Date()
  const startsAt = post.publishingStartsAt
  const endsAt = post.publishingEndsAt
  const outp = []
  if (startsAt instanceof Date && !isNaN(startsAt.getTime())) {
    const tmp = startsAt.toISOString()
    outp.push(`${tmp.substr(0, 10)} `)
  }
  if (endsAt instanceof Date && !isNaN(endsAt.getTime())) {
    const tmp = endsAt.toISOString()
    outp.push(` ${tmp.substr(0, 10)}`)
  }

  if (startsAt instanceof Date &&startsAt > now || endsAt instanceof Date && endsAt < now) {
    return (
      <div className="Live-TimedPublishing-Banner">
        <IconClock />
        <h2>This post is not visible for other users!</h2>
        <h3>{`${outp[0] || ''}-${outp[1] || ''}`}</h3>
      </div>
    )
  }
  else {
    return null
  }
}

class LiveItem extends Component {
  constructor() {
    super(...arguments)

      const cardUtils = new IMobileDocCardUtil('*')
      const atomUtils = new IMobileDocAtomUtil('*')

    const config = {
      cards: cardUtils.map(util => utilityToCard(util)),
      atoms: atomUtils.map(util => utilityToAtom(util)),
      markups: [],
      sections: [],
      additionalProps: {}
    }

    this.renderer = new MobiledocRenderer(config)
  }

  render(props, context) {
    const mobiledoc = safeGet(() => props.context.body)
    if (!mobiledoc) return null
    
    return <>
      {renderTimedPublishingBanner(props.context)}
      <h1>{props.context.title}</h1>
      {this.renderer.render(mobiledoc)}
    </>
  }
}

new Adapter({
  implements: ILiveItem,
  adapts: IPage,
  Component: LiveItem
})