/*

    To use this input widget adapter you need to register it with your
    adapter registry.

*/
import { Component } from 'inferno'
import { Adapter } from 'component-registry'
import { i18n } from '../i18n'
import { interfaces, renderString, generateId } from 'inferno-formlib'
import { Input } from 'inferno-bootstrap'
import SearchIcon from '../img/icons/search'
import { ISearchField } from '../customFields/SearchField'
import './SearchField.scss'

const { IInputFieldWidget, IFormRowWidget } = interfaces

// A dummy row, because we don't need all the fancy stuff
new Adapter({
  implements: IFormRowWidget,
  adapts: ISearchField,
  
  Component: function ({ children }) {
    return children
  },
  doesNotRenderLabel: function () { 
      return false
  }
})

class SearchWidget extends Component {
    constructor (props) {
        super(props)

        this.state = {
            value: props.value
        }
        this.didGetInput = this.didGetInput.bind(this)
        this.didGetChange = this.didGetChange.bind(this)
    }

    componentWillReceiveProps (nextProps) {
        this.setState({
            value: nextProps.value
        })
    }

    didGetInput (e) {
        const field = this.props.adapter.context
        const newVal = field.fromString(e.target.value)
        this.setState({
            value: newVal
        })
        // Trigger change so FormRow can update character counter
        this.props.onChange(this.props.propName, newVal)

    }

    didGetChange (e) {
        this.props.onChange(this.props.propName, this.state.value)
    }

    render ({inputName, namespace, options, id, doesNotRenderLabel}) {
        const field = this.props.adapter.context

        const isValid = this.props.validationError || this.props.invariantError ? false : undefined

        const ariaLabels = {
            'aria-invalid': isValid !== undefined,
            'aria-labelledby': doesNotRenderLabel ? undefined : id,
            'aria-label': doesNotRenderLabel ? renderString(field.label || 'inferno-formlib--InputField', options && options.lang, 'Input Field') : undefined,
            'aria-required': field._isRequired ? field._isRequired : undefined
        }

        return (
          <div className="SearchWidget">
            <Input
              id={generateId(namespace, '__Field')}
              name={inputName}
              valid={isValid}
              placeholder={renderString(field.placeholder, options && options.lang)}
              readOnly={field.readOnly}
              value={field.toFormattedString(this.state.value)}

              {...ariaLabels}

              onInput={this.didGetInput}
              onChange={this.didGetChange} />
              <SearchIcon className="icon" />
          </div>
        )
    }
}

export default SearchWidget

new Adapter({
  implements: IInputFieldWidget,
  adapts: ISearchField,
  Component: SearchWidget
})
