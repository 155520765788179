import { safeGet } from 'safe-utils';
import { createInterfaceClass } from 'component-registry';
import { Schema, TextField, DateField, i18n } from 'isomorphic-schema';
import { MobileDocField } from 'influence-app-entities/dist/customFields/MobileDocField';
var Interface = createInterfaceClass('influence-app-entities');
var INameDay = new Interface({
  name: 'INameDay',
  schema: new Schema('NameDay Schema', {
    title: new TextField({
      label: i18n('INameDay-title-label', 'Name'),
      placeholder: i18n('INameDay-title-placeholder', 'Type here...')
    }),
    startDate: new DateField({
      label: i18n('INameDay-startDate-label', 'Start Date'),
      placeholder: i18n('INameDay-date-placeholder', 'yyyy-mm-dd')
    }),
    endDate: new DateField({
      label: i18n('INameDay-endDate-label', 'End Date'),
      placeholder: i18n('INameDay-date-placeholder', 'yyyy-mm-dd')
    }),
    body: new MobileDocField({
      label: i18n('INameDay-body-label', 'NameDay Description'),
      placeholder: i18n('INameDay-body-placeholder', 'Type here...')
    })
  })
});
INameDay.schema.addInvariant(function (data, selectedFields) {
  var tmpFields = ['startDate', 'endDate']; // Check that all the fields in this invariant are passed

  for (var i in tmpFields) {
    var key = tmpFields[i];

    if (selectedFields.indexOf(key) < 0) {
      // The fields aren't supplied so we don't do the test
      return;
    }
  }

  var start = safeGet(function () {
    return new Date(data.startDate);
  });
  var end = safeGet(function () {
    return new Date(data.endDate);
  });

  if (start && end && start > end) {
    return {
      message: "End date must be later than start date!",
      fields: tmpFields
    };
  }
});
export { INameDay };