import { Component } from 'inferno'
import { IApiClient, ISessionManager } from '../../interfaces/app'
import { safeGet } from 'safe-utils'
import {
  Container,
  Col,
  Row,
  Button,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter } from 'inferno-bootstrap'
import classnames from 'classnames'
import { BoolField } from 'isomorphic-schema'
import { widgets } from 'inferno-formlib'
import { Animated } from 'inferno-animation'
import StickyRow from '../../widgets/StickyRow'
import { i18n } from '../../i18n'
import SitePageFooter from '../../widgets/SitePageFooter'
import './RegisterStep.scss'
import logo_colored_svg from '../../img/logo/logo-colored.svg'
import { INotificationManager } from '../../interfaces/presentation'
const { CheckboxWidget } = widgets

const acceptField = new BoolField({
  label: 'I accept the terms'
})


export default class Page extends Component {


  constructor () {
    super(arguments)

    this.state = {
      modal: false,
      accepted: false
    }
    this.didChange = this.didChange.bind(this)
    this.doNext = this.doNext.bind(this)
    this.doOpenModal = this.doOpenModal.bind(this)
    this.doCloseModal = this.doCloseModal.bind(this)
  }

  static async fetchData ({registry, match, location}) {
      const { data } = await new IApiClient({ registry }).query({
          URI: `/session`
      })
      
      return data
  }
  
  doNext (e) {
    e.preventDefault()
    if (this.state.accepted) {
      const currentUser = new ISessionManager().getCurrentUser()
      currentUser.termsAndConditions = {
        name: 'onboarding-terms',
        dateTime: new Date().toUTCString()
      }
      new IApiClient().update({
        URI: `/content/User/${currentUser._id}`,
        data: currentUser,
        invalidate: ['/content/User', '/session']
      }).then(({data}) => {
        // TODO: Figure out why this component doesn't re-render on .updateCurrentUser
        new ISessionManager().refreshCurrentUser()
        new INotificationManager().showSuccessMessage()
        this.context.router.history.push(`/register_3`)
      })
    } else {
      this.setState({
        modal: true
      })
    }
  }

  didChange (propName, value) {
    this.setState({
      accepted: value
    })
  }

  doCloseModal () {
    this.setState({
      modal: false
    })
  }

  doOpenModal () {
    this.setState({
      modal: true
    })
  }

  renderModal () {
    return (
      <Modal isOpen={this.state.modal} toggle={this.doCloseModal} fade={true}>
        <ModalHeader toggle={this.doCloseModal}>Oops!</ModalHeader>
        <ModalBody>
          You need to accept the terms by clicking the checkbox to continue.
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.doCloseModal}>Ok</Button>
        </ModalFooter>
      </Modal>
    )
  }

  render () {
      const currentUser = safeGet(() => new ISessionManager().getCurrentUser())
      return (
          <Animated key="me" prefix="PageNav">
            {this.renderModal()}

            <div className="Fullpage Onboarding">
              <img id="Page-Logo" src={logo_colored_svg} />
              <Container fluid>
                  <Row className="col">
                    <h2>Terms & Conditions</h2>
                  </Row>
                  <Row className="columns-2">
                      <p>Memly.io is a kind and respectful community of world class content creators. You are responsible for the content you post, <strong>following our community rules</strong> and <strong>respecting the law.</strong></p>
                      <p>Memly.io is currently in beta. You therefor have to accept that there can be bugs and at worst you could loose all of your content. Obviously we will do everything in our power to ensure this doesn't happen. If you run into issues just contact community support and we will help you.</p>
                      <p>By posting content with memly.io you accept that we make it available through those channels we have at our disposal at any given time. You can control visibility through our privacy options.</p>
                      <p>These communty rules can be updated at our discretion, but we will be mindful of how these changes might impact you as a content creator.</p>
                      <div className="keep-together">
                        <h4>Here are examples of what we don't tolerate:</h4>
                        <ul>
                          <li>Intellectual property infringement</li>
                          <li>Hate speech</li>
                          <li>Sexism</li>
                          <li>Denial of scientific fact</li>
                          <li>Abuse or harassment</li>
                          <li>Breaking the law or inciting others to break the law</li>
                          <li>Sell or promote controlled substances or other illegal products or services</li>
                        </ul>
                      </div>
                      <p>If the content you publish are in breach of our community rules we reserve the right to unpublish it. Any content that has been edited to conform to our rules can be published again. If you repetadly break our rules your account can be suspended.</p>
                      <p>If you have any questions, send a DM to <a target="_blank" href="https://www.instagram.com/memly.io/">@memly.io</a>. To contact our support by e-mail, use <a href="mailto:support@memly.io">support@memly.io</a>.</p>
                  </Row>
    
                  {currentUser && <StickyRow className="Onboarding-AcceptTerms">
                    <Col className="_InputContainer">
                      <FormGroup>
                        <CheckboxWidget namespace={["accept"]} value={this.state.accepted} adapter={{ context: acceptField }} onChange={this.didChange}/>
                        <Label for="accept__Field">{acceptField.label}</Label>
                      </FormGroup> 
                    </Col>
                    <Col className="_ButtonContainer">
                      <Button
                        className={classnames("Onboarding-Button Onboarding-Action-Button", { "fake-disabled": !this.state.accepted })}
                        color="primary"
                        onClick={this.doNext} href="#instagram">Next...</Button>
                    </Col>
                  </StickyRow>}
              </Container>
              <SitePageFooter />
            </div>
          </Animated>
      )
  }
}

