import { createInterfaceClass } from 'component-registry';
var Interface = createInterfaceClass('influence-app-entities');
import { Schema, BoolField, EmailField, PasswordField, i18n } from 'isomorphic-schema';
var IAccountEmail = new Interface({
  name: 'IAccountEmail',
  schema: new Schema('AccountEmail Schema', {
    email: new EmailField({
      email: i18n('IAccountEmail-email-label', 'E-mail'),
      required: true
    }),
    password: new PasswordField({
      label: i18n('IAccountEmail-password-label', 'Password'),
      minLength: 12,
      required: true,
      help: i18n('IAccountEmail-password-help', 'A long password that is easy to remeber and type, such as a phrase, is more secure than a short password with weird characters.')
    }),
    useForLogin: new BoolField({
      label: i18n('IAccountEmail-useForLogin-label', 'Use for login')
    })
  })
});
export { IAccountEmail };