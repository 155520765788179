import { createTextVNode, normalizeProps } from 'inferno'
import { createElement } from 'inferno-create-element'
import uuidV4 from 'uuid/v4';

export const MOBILE_DOC_VERSION_0_3_0 = '0.3.0';
export const MOBILE_DOC_VERSION_0_3_1 = '0.3.1';

export const MARKUP_SECTION_TYPE = 1;
export const IMAGE_SECTION_TYPE = 2;
export const LIST_SECTION_TYPE = 3;
export const CARD_SECTION_TYPE = 10;

export const MARKUP_MARKER_TYPE = 0;
export const ATOM_MARKER_TYPE = 1;

class Renderer_0_3 {
  constructor (mobiledoc, { atoms = [], cards = [], sections = [], markups = [], additionalProps = {} }) {
    this.mobiledoc = mobiledoc;
    this.atoms = atoms;
    this.cards = cards;
    this.markups = markups;
    this.sections = sections;
    this.additionalProps = additionalProps;

    this.renderCallbacks = [];
  }

  render () {
    // <div className='Mobiledoc'>{this.renderSections()}</div>;
    const renderedSections = createElement('div', {
      className: 'Mobiledoc'
    }, this.renderSections())
    this.renderCallbacks.forEach(cb => cb());

    return renderedSections;
  }

  renderSections () {
    const sections = this.mobiledoc.sections.map((section, index) => {
      const outp = this.renderSection(section, index)
      return outp
    });
    return sections
  }

  renderSection (section, nodeKey) {
    const [type] = section;

    switch (type) {
      case MARKUP_SECTION_TYPE:
        return this.renderMarkupSection(section, nodeKey);
      case LIST_SECTION_TYPE:
        return this.renderListSection(section, nodeKey);
      case CARD_SECTION_TYPE:
        return this.renderCardSection(section, nodeKey);
      default:
        return null;
    }
  }

  renderMarkupSection ([type, TagName, markers], nodeKey) {
    let Element;
    const customSection = this.sections.find(s => s.name === TagName);

    if (customSection) {
      const Section = customSection.component;
      // <Section key={nodeKey} {...this.additionalProps}>{[]}</Section>;
      Element = createElement(Section, {
        key: nodeKey,
        ...this.additionalProps
      }, [''])
    } else {
      // <TagName key={nodeKey}>{[]}</TagName>;
      Element = createElement(TagName, {
        key: nodeKey,
      }, [''])
    }

    // TODO: This is really hackish so we should consider changing the way we render so we don't 
    // need to add children after creating element
    Element.children.shift()

    return this.renderMarkersOnElement(Element, markers);
  }

  renderListSection ([type, TagName, markers], nodeKey) {
    // <li key={index}>{[]}</li>, item
    const items = markers.map((item, index) => {
      const Element = createElement('li', { key: index }, [''])
      // TODO: This is really hackish so we should consider changing the way we render so we don't need to add children after creating element
      Element.children.shift()
      return this.renderMarkersOnElement(Element, item);
    })

    // <TagName key={nodeKey}>{items}</TagName>;
    return createElement(TagName, {
      key: nodeKey
    }, items)
  }

  renderAtomSection (atomIndex) {
    const [name, text, payload] = this.mobiledoc.atoms[atomIndex];
    const atom = this.atoms.find(a => a.name === name);

    if (atom) {
      const key = `${name}-${text.length}`;
      const env = {
        name,
        isInEditor: false,
        dom: 'dom'
      };
      const options = {};
      const props = {
        key,
        env,
        options,
        payload: { ...payload, ...this.additionalProps },
        text
      };

      return atom.render(props);
    }

    return null;
  }

  renderCardSection ([type, index], nodeKey) {
    const [name, payload] = this.mobiledoc.cards[index];
    const card = this.cards.find(c => c.name === name);

    if (card) {
      const env = {
        name,
        isInEditor: false,
        dom: 'dom',
        didRender: (callback) => this.registerRenderCallback(callback),
        onTeardown: (callback) => this.registerRenderCallback(callback)
      };
      const options = {};
      const props = {
        env,
        options,
        payload: { ...payload, key: nodeKey, ...this.additionalProps }
      };

      return card.render(props);
    }

    return null;
  }

  renderMarkersOnElement (element, markers) {
    const elements = [element];
    const pushElement = (openedElement) => {
      if (element.children === undefined) {
        element.children = []
      }
      element.children.push(openedElement);
      elements.push(openedElement);
      element = openedElement;
    };

    markers.forEach(marker => {
      let [type, openTypes, closeCount, value] = marker; // eslint-disable-line prefer-const

      openTypes.forEach(openType => {
        const [TagName, attrs] = this.mobiledoc.markups[openType];
        const props = this.parseProps(attrs);

        if (TagName) {
          const definedMarkup = this.markups.find(markup => markup.name === TagName);
          if (definedMarkup) {
            const { render: Markup } = definedMarkup;
            // <Markup key={uuidV4()} {...props} {...this.additionalProps} />
            pushElement(createElement(Markup, {
              key: uuidV4(),
              ...props,
              ...this.additionalProps
            }), ['']);
          } else {
            // <TagName key={uuidV4()} {...props} />
            pushElement(createElement(TagName, {
              key: uuidV4(),
              ...props
            }, ['']))
          }
        } else {
          closeCount -= 1;
        }
      });

      switch (type) {
        case MARKUP_MARKER_TYPE:
          element.children.push(createTextVNode(value, uuidV4()));
          // element.children.push(value);
          break;
        case ATOM_MARKER_TYPE:
          element.children.push(this.renderAtomSection(value));
          break;
        default:
      }

      for (let j = 0, m = closeCount; j < m; j += 1) {
        normalizeProps(elements.pop());
        element = elements[elements.length - 1];
      }
    });

    return element;
  }

  parseProps (attrs) {
    if (attrs) {
      return {
        [attrs[0]]: attrs[1]
      };
    }

    return null;
  }

  registerRenderCallback (cb) {
    this.renderCallbacks.push(cb);
  }
}

export default class MobiledocRenderer {
  constructor ({ atoms = [], cards = [], markups = [], sections = [], additionalProps = {} }) {
    this.options = {
      atoms,
      cards,
      markups,
      sections,
      additionalProps
    };
  }

  render (mobiledoc) {
    const { version } = mobiledoc;
    switch (version) {
      case MOBILE_DOC_VERSION_0_3_0:
      case MOBILE_DOC_VERSION_0_3_1:
        return new Renderer_0_3(mobiledoc, this.options).render();
      default:
        return null;
    }
  }
}
