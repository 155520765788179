import { Link } from 'inferno-router'
import classnames from 'classnames'
import SitePageFooter from './SitePageFooter'
import { LogoutMenu } from '../widgets/MainMenu'
import './PageTemplate.scss'
import { createSimpleDate } from 'isomorphic-date'

import logo_svg from '../img/logo/logo-inverse.svg'

export default function PageTemplate ({ className, children }) {
  return (
    <Page className={className}>
      <PageHeader>
        <TopMenu>
          <WeekNr />
          <Logo />
          <LogoutMenu />
        </TopMenu>
      </PageHeader>
      <PageBody>{children}</PageBody>
      <SitePageFooter />
    </Page>
  )
}

function Page ({ className, children }) {
  return (
    <div className={classnames("Page", className)}>
      {children}
    </div>
  )
}

function PageBody ({ children }) {
  return (
    <div className="Page-Body">
      {children}
    </div>
  )
}

function PageHeader ({ children }) {
  return (
    <div className="Page-Header">
      {children}
    </div>
  )
}

function TopMenu ({ children }) {
  return (
    <div className="TopMenu-Container">
      <div className="TopMenu">
        {children}
      </div>
    </div>
  )
}

function Logo (props) {
  return (
    <div className="Page-Logo">
      <Link to="/"><img src={logo_svg} /></Link>
    </div>
  )
}

const SimpleDate = createSimpleDate({}, {})
function WeekNr (props) {
  const date = new SimpleDate(new Date())
  
  return (
    <div className="TopMenu-WeekNr">
      v{date.isoWeekNr()}
    </div>
  )
}

