import { createInterfaceClass } from 'component-registry';
var Interface = createInterfaceClass('influence-app-entities');
import { Schema, TextAreaField, TextField, i18n } from 'isomorphic-schema';
import { ImageField } from '../customFields/ImageField';
var IMedia = new Interface({
  name: 'IMedia',
  schema: new Schema('Media Schema', {
    shortDescription: new TextAreaField({
      label: i18n('IMedia-shortDescription-label', 'Short description')
    }),
    image: new ImageField({
      label: i18n('IMedia-image-label', 'Image'),
      placeholder: i18n('IMedia-image-placeholder', 'Drag och släpp här...'),
      help: i18n('IMedia-image-help', 'Bara rimliga bilder')
    }),
    fileType: new TextField({
      label: i18n('IMedia-fileType-label', 'Type')
    })
  })
});
export { IMedia };