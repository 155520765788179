import './icon_ok.scss'

export default function () {
  return (
    <svg width="100px" height="100px" viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Page-2" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Steg-3" transform="translate(-455.000000, -404.000000)">
                <g id="icon_ok" transform="translate(455.000000, 404.000000)">
                    <circle id="Oval" cx="50" cy="50" r="50"></circle>
                    <path d="M53.2475235,64.1054042 L53.2475235,19.1054042 L62.2475235,19.1054042 L62.2475235,64.1054042 L62.2475235,73.1054042 L37.2475235,73.1054042 L37.2475235,64.1054042 L53.2475235,64.1054042 Z" id="Combined-Shape" transform="translate(49.747524, 46.105404) rotate(44.000000) translate(-49.747524, -46.105404) "></path>
                </g>
            </g>
        </g>
    </svg>
  )
}