import { Component } from 'inferno'
import { safeGet } from 'safe-utils'
import { i18n } from '../i18n'
import { IMediaSelectItem, IMediaSelectDetails, INotificationManager } from '../interfaces/presentation'
import { IApiClient, ISessionManager } from '../interfaces/app'
import {
  Button,
  Container,
  Row,
  Col,
  Form
} from 'inferno-bootstrap'

import { Schema, i18n as i18nStr } from 'isomorphic-schema'
import { SearchField } from '../customFields/SearchField'
import '../formlib/SearchField'
  
import { FormRows, widgets, interfaces } from 'inferno-formlib'
import { Progress } from 'inferno-bootstrap'
import { recalcMasonryDimensions, loadMoreMasonry } from '../entities/common/masonry'

import './MediaSelector.scss'

const { DragNDrop } = widgets
const { IFileUploadUtil } = interfaces

const filterSchema = new Schema('Filter Schema', {
  search: new SearchField({
    placeholder: 'Filter by title'
  })
})

export default class ListPage extends Component {
  
  static _limit = 30

  constructor (props) {
    super(...arguments)

    this.state = {
      search: undefined,
      items: [],
      selectedItem: props.initialSelection,
      progress: undefined,
      errMsg: undefined,
      rowGap: 10,
      rowHeight: 10
    }
  }


  _query (page = 1) {
    this._lastLoadedPage = page
    
    const query = {
      query: {
        stateFilter: this.state.publishWorkflow ? `publishWorkflow.${this.state.publishWorkflow}` : undefined,
        search: this.state.search && {
          shortFreetext: this.state.search
        },
      },
      page,
      limit: ListPage._limit + 1 // One more so we can check if there are more images to load
    }
    
    this._queryIsLoading = true
    return new IApiClient().query({
      URI: `/content/Media`,
      query
    }).then(({data}) => {
      if (data.length > ListPage._limit) {
        this._hasMoreResults = true
        // Only return this._limit nrof results
        this._queryLastNrofResults = ListPage._limit
        data = data.splice(0, ListPage._limit)
      }
      else {
        this._hasMoreResults = false
        this._queryLastNrofResults = data.length
      }
      
      this.setState({
        items: this._lastLoadedPage === 1 ? data : this.state.items.concat(data)
      })

      // Delay so we don't get insane amounts of reloading
      setTimeout(() => this._queryIsLoading = false, 100)
    })
  }

  doLoadMore = () => {
    if (!this._queryIsLoading && this._hasMoreResults) {
      this._query(this._lastLoadedPage + 1)
    }
  }
 
  didUpdateMasonry = (rowGap, rowHeight, el) => {
    this.setState({
      rowGap,
      rowHeight
    })

    loadMoreMasonry.call(this, el, 100, this.doLoadMore)
  }

  async componentDidMount () {
    await this._query()
    recalcMasonryDimensions.call(this, this._masonryEl, this.didUpdateMasonry)
  }

  didUpdate = (propName, value) => {
    const state = this.state
    state[propName] = value
    this.setState(state)

    // Throttle calls to search when typing
    if (this._searchTimer) {
      clearTimeout(this._searchTimer)
    }
    this._searchTimer = setTimeout(() => {
      const search = this.state.search
      if (typeof search === 'string' && (search.length >= 3 || search.length === 0)) {
        this._query()
      }
      delete this._searchTimer
    }, 500)
  }

  doSelect = (item) => {
    if (this.state.selectedItem && item._id === this.state.selectedItem._id) {
      // Toggle select off
      this.setState({
        selectedItem: undefined
      })
    } else {
      this.setState({
        selectedItem: item
      })
    }
  }

  doClearError = (e) => {
    e.preventDefault()
    this.setState({
      progress: undefined,
      errMsg: undefined
    })
  }

  didGetProgress = (perc) => {
    this.setState({
      progress: perc,
      errMsg: undefined
    })
  }

  doTriggerUpload = (e) => {
    var input = document.createElement('input');
    input.type = 'file';

    input.onchange = e => { 
      this.doUpload(e.target.files)
    }

    input.click();
  }

  doUpload = async (files) => {
    this.setState({
      progress: 0
    })

    const nrofFiles = files.length
    for (let i = 0; i < files.length; i++) {
      let file = files[i]
      var fileUploadUtil = new IFileUploadUtil(this.props.uploadUtilName || 'Image')
      
      try {
        const newMediaObj = await fileUploadUtil.upload(
          file,
          (perc) => this.didGetProgress(perc / nrofFiles + i * (100 / nrofFiles))
        )
                
        // Show success indicator at center of screen
        new INotificationManager().showSuccessMessage()
        
        this.setState({
          selectedItem: newMediaObj
        })

      }
      catch (e) {
        // TODO: Show the error message in some nice way
        this.setState({
          errMsg: e.message,
          progress: undefined // Or should it show an error state?
        })
      }
    }
    
    // Clear the progressbar
    setTimeout(() => {
      this.setState({
        progress: undefined
      })  
    }, 100)

    // Update the list of files
    return this._query()
  }

  renderThumbnailsCol () {
    return (
      <div className="masonry-container col col-md-9 MediaSelector-ThumbnailContainer" ref={(el) => this._masonryEl = el}>
          {this.state.items.map((item) => {
            const isSelected = this.state.selectedItem ? item._id === this.state.selectedItem._id : false
            const ListItem = new IMediaSelectItem(item).Component
            return <ListItem key={item._id}
              context={item}
              rowGap={this.state.rowGap}
              rowHeight={this.state.rowHeight}
              isSelected={isSelected}
              onSelect={this.doSelect} />
          })}
      </div>
    )
  }

  renderDetailsCol () {
    if (!this.state.selectedItem) {
      return (
        <Col md="3" className="MediaSelector-ThumbnailDetails no-selection">
          <h2>No Image Selected</h2>
          <p>Select an image from your media library or upload a new one.</p>
          <Button outline color="primary" onClick={this.doTriggerUpload}>Upload image...</Button>
          <p className="info text-muted">...or use drag'n'drop.</p>
          <p className="info">JPEG, PNG och WEBP, max 5Mb</p>
        </Col>
      )
    } else {
      const { selectedItem } = this.state
      const MediaDetails = new IMediaSelectDetails(selectedItem).Component
      return (
        <Col md="3" className="MediaSelector-ThumbnailDetails">
          <MediaDetails context={selectedItem}>
            <Button size="sm" color="primary" onClick={() => this.props.onSelect(selectedItem)}>Select</Button>
          </MediaDetails>
        </Col>
      )
    }
  }

  render () {
    return (
      <Container fluid>
        {(this.state.progress !== undefined) && <Progress className="MediaSelector-Progress" value={this.state.progress} />}
        <Row className="MediaSelector-Filter">
          <Col>
            <Form onSubmit={(e) => e.preventDefault()}>
              <FormRows schema={filterSchema} value={this.state} onChange={this.didUpdate} />
            </Form>
          </Col>
        </Row>
        <Row className="MediaSelector-List">
          {this.renderThumbnailsCol()}
          {this.renderDetailsCol()}
        </Row>
        <DragNDrop multiFile onDrop={this.doUpload}><div className="text">Drop image here!</div></DragNDrop>
      </Container>
    )
  }
}