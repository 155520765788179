import { Component } from 'inferno'
import { Row } from 'inferno-bootstrap'
import { getElOffset } from 'inferno-formlib'
import { i18n } from '../i18n'
import classnames from 'classnames'
import './StickyRow.scss'

export default class StickyRow extends Component {

  constructor () {
    super(...arguments)

    this.state = {
      isSticky: false,
      stickyWidth: undefined,
    }

    this.pollForMovementTimer = undefined
    this.offsetFlowNode = undefined
    
    this.didScroll = this.didScroll.bind(this)
    this.pollForMovement = this.pollForMovement.bind(this)
  }

  didScroll (e) {
    const boundary = this.props.boundary || this.state.boundary
    const newState = {}

    const flowNode = this._flowingEl
    if (this.props.stickToTop) {
      let isSticky = getElOffset(flowNode).top < (window.scrollY + boundary.top)
      if (isSticky !== this.state.isStickyTop) {
        newState['isStickyTop'] = isSticky
      }
    }
    else {
      const isSticky = getElOffset(flowNode).top + flowNode.offsetHeight > (window.visualViewport.height + window.scrollY) && (boundary.top < window.innerHeight + window.scrollY)
      if (isSticky !== this.state.isSticky) {
        newState['isSticky'] = isSticky
      }
    }

    const stickyWidth = flowNode.offsetWidth
    if (stickyWidth !== this.state.stickyWidth) {
      newState['stickyWidth'] = stickyWidth
    }

    // Store viewport size so we can avoid calulations
    const { width, height } = window.visualViewport
    newState.visualViewport = { width, height }

    if (Object.keys(newState).length > 0) {
      this.setState(newState)
    }
  }

  pollForMovement () {
    if (!this._flowingEl) {
      return this.pollForMovementTimer = requestAnimationFrame(this.pollForMovement)
    }

    // TODO: Call this.didScroll() if position of flowing element has changed!
    
    const flowNode = this._flowingEl
    const offset = flowNode.offsetTop - (window.scrollY !== undefined ? window.scrollY : window.pageYOffset)
    if (this.offsetFlowNode !== offset) {
      this.offsetFlowNode = offset
      this.didScroll()
    } else if (window.visualViewport.height !== this.state.visualViewport.height ||
               window.visualViewport.width !== this.state.visualViewport.width) {
      this.didScroll()
    }

    this.pollForMovementTimer = requestAnimationFrame(this.pollForMovement)
  }

  componentDidMount () {
    this.pollForMovementTimer = requestAnimationFrame(this.pollForMovement)
    this.didScroll()
  }

  componentWillUnmount () {
    if (this.pollForMovementTimer) {
      cancelAnimationFrame(this.pollForMovementTimer)
    }
  }
  
  render ({ boundary, children, className = "", ...props }) {
      // TODO: Apply the rest of bootstrap Row and add to inferno-bootstrap
      let stickyCls = className.split(" ")
      const i = stickyCls.indexOf('col')
      if (i >= 0) {
        stickyCls.splice(i, 1)
      }
      return (
          <div className="sticky-row-wrapper">
              <div ref={(e) => this._flowingEl = e} className={classnames("row", className, {"row--hidden": this.state.isSticky || this.state.isStickyTop} )}>
                  {children}
              </div>
              <div className={classnames("row sticky-row", stickyCls, {"sticky-row--hidden": !this.state.isSticky && !this.state.isStickyTop})}
                  style={{ 
                    bottom: (this.props.stickToTop ? undefined : `${boundary.bottom}px`),
                    top: (this.props.stickToTop ? `${boundary.top}px` : undefined),
                    width: `${this.state.stickyWidth}px` }}>
                  {children}
              </div>
          </div>
      )
  }
}

StickyRow.defaultProps = { boundary: { top: '0', bottom: '0' } }
