import { createObjectPrototype, Utility } from 'component-registry';
import { IUnsplashImage } from '../../interfaces/external';
import { IObjectPrototypeFactory } from 'influence-interfaces/object';
var UnsplashImage = createObjectPrototype({
  implements: [IUnsplashImage],
  constructor: function constructor(params) {
    this._type = 'UnsplashImage';
  }
});
export default UnsplashImage;
var ObjectPrototypeFactory = new Utility({
  implements: IObjectPrototypeFactory,
  name: 'UnsplashImage',
  getPrototype: function getPrototype() {
    return UnsplashImage;
  },
  getObject: function getObject(data) {
    return new UnsplashImage(data);
  }
});