import { createInterfaceClass } from 'component-registry'
const Interface = createInterfaceClass('influence-app')

export const IMobileDocCardUtil = new Interface({
  name: 'IMobileDocCardUtil'
})

export const IMobileDocCardButton = new Interface({
  name: 'IMobileDocCardButton'
})

export const IMobileDocAtomUtil = new Interface({
  name: 'IMobileDocAtomUtil'
})