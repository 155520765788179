import { createInterfaceClass } from 'component-registry'
const Interface = createInterfaceClass('influence-app')

export const IApiClient = new Interface({
  name: 'IApiClient'
})

export const ISessionManager = new Interface({
  name: 'ISessionManager'
})

export const IPageManager = new Interface({
  name: 'IPageManager'
})
/**
 * @typedef ImageMetaData
 * @type {object}
 * @property {string} param0.url - image URI
 * @property {int} param0.width - image width in pixels
 * @property {int} param0.height - image height in pixels
 */
/**
 * @typedef MetaData
 * @type {object}
 * @property {string} param0.title
 * @property {string} param0.description
 * @property {ImageMetaData} param0.image - sharing image
 * @property {string} param0.url - canonical url
 */
/**
 * @param [MetaData] metaData - set meta data for page head
 */
IPageManager.prototype.setMetaData = function (metaData) {}
