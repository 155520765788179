import { createObjectPrototype, Utility } from 'component-registry';
import { Permissions } from 'influence-permissions/dist/permissions';
import { PublishWorkflow } from './workflows/PublishWorkflow';
import { TimedPublishing } from './TimedPublishing';
import { IPage } from '../interfaces/Page';
import { IObjectPrototypeFactory } from 'influence-interfaces/object';
var _permissions = {
  create: ['admin', 'customerService'],
  read: ['owner', 'admin', 'customerService', 'anonymous:publishWorkflow.published'],
  update: ['owner', 'admin', 'customerService'],
  delete: ['owner', 'admin', 'customerService']
};
var Page = createObjectPrototype({
  implements: [IPage],
  extends: [Permissions, PublishWorkflow, TimedPublishing],
  constructor: function constructor(params, roleManager) {
    this._type = 'Page';

    this._IPermissions.constructor.call(this, params, _permissions);

    this._IPublishWorkflow.constructor.call(this, params, {
      defaultState: 'draft'
    });

    this._ITimedPublishing.constructor.call(this, params);
  }
});
export default Page;
var ObjectPrototypeFactory = new Utility({
  implements: IObjectPrototypeFactory,
  name: 'Page',
  getPrototype: function getPrototype() {
    return Page;
  },
  getObject: function getObject(data, roleManager) {
    data = data || {};
    return new Page(data);
  }
});