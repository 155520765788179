import { Component } from 'inferno'

import { utilityToCard, utilityToAtom } from '../formlib/MobileDocField/utils'
import MobiledocRenderer from '../widgets/RenderMobiledoc'
import { IMobileDocCardUtil, IMobileDocAtomUtil } from '../interfaces/formlib'

export default class Page extends Component {
  constructor() {
    super(...arguments)

      const cardUtils = new IMobileDocCardUtil('*')
      const atomUtils = new IMobileDocAtomUtil('*')

    const config = {
      cards: cardUtils.map(util => utilityToCard(util)),
      atoms: atomUtils.map(util => utilityToAtom(util)),
      markups: [],
      sections: [],
      additionalProps: {}
    }

    this.renderer = new MobiledocRenderer(config)
  }

  // static async fetchData ({registry, match, location, router, page = 1}) {
  //   try {
  //     const { data } = await new IApiClient({ registry }).query({
  //         URI: `/content/Page/terms-and-conditions`
  //     })
      
  //     return data
  //   }
  //   catch (e) {
  //     return undefined
  //   }
  // }

  // render() {
  //   const page = this.props.fetchData
  //   const mobiledoc = safeGet(() => page.body)
  //   if (!mobiledoc) return null
  //   return this.renderer.render(mobiledoc)
  // }
}

