import { Adapter } from 'component-registry'
import { i18n } from '../../i18n'

import { Link } from 'inferno-router'
import { Row } from 'inferno-bootstrap'

import { IListItem } from '../../interfaces/presentation'
import { IMedia } from 'influence-app-entities/lib/interfaces/Media'
import '../common/ListItem.scss'

function ListItem (props, context) {
  return (
    <Row className="list-item media">
      <img className="thumbnail mr-3" src={props.context.imageUrl} />
      <div className="media-body list-item-body">
        <Link to={`/admin/Media/${props.context._id}`}>{props.context.shortDescription}</Link>
      </div>
    </Row>
  )
}

new Adapter({
  implements: IListItem,
  adapts: IMedia,
  Component: ListItem
})