export default function () {
  return (
    <svg width="180px" height="150px" viewBox="0 0 180 150">
        <g id="icon_gallery_one_two" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <rect fill="currentColor" x="0" y="90" width="85" height="60"></rect>
            <rect fill="currentColor" x="0" y="0" width="180" height="80"></rect>
            <rect fill="currentColor" x="95" y="90" width="85" height="60"></rect>
        </g>
    </svg>
  )
}