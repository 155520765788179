import { createInterfaceClass, createObjectPrototype } from 'component-registry';
var Interface = createInterfaceClass('influence-app');
import { BaseField } from 'isomorphic-schema';
import { IWorkflowFilterField } from '../interfaces/fields';
var WorkflowFilterField = createObjectPrototype({
  implements: [IWorkflowFilterField],
  extends: [BaseField],
  constructor: function constructor(options) {
    this._IBaseField.constructor.call(this, options);
  }
});
export { WorkflowFilterField };