import { createInterfaceClass } from 'component-registry';
var Interface = createInterfaceClass('influence-app-entities');
export var IImageField = new Interface({
  name: 'IImageField'
});
export var IWorkflowFilterField = new Interface({
  name: 'IWorkflowFilterField'
});
export var IMobileDocField = new Interface({
  name: 'IMobileDocField'
});
export var IMobileDocCardUtil = new Interface({
  name: 'IMobileDocCardUtil'
});
IMobileDocCardUtil.prototype.type = String;
IMobileDocCardUtil.prototype.RenderComponent = Object;
IMobileDocCardUtil.prototype.EditComponent = Object;
export var IMobileDocAtomUtil = new Interface({
  name: 'IMobileDocAtomUtil'
});
IMobileDocAtomUtil.prototype.type = String;

IMobileDocAtomUtil.prototype.RenderComponent = function () {};

export var IGeoPointField = new Interface({
  name: 'IGeoPointField'
});
export var IRelationField = new Interface({
  name: 'IRelationField'
});
export var IGalleryLayoutField = new Interface({
  name: 'IGalleryLayoutField'
});