import { Component } from 'inferno'
import { safeGet } from 'safe-utils'
import classnames from 'classnames'
import { Container, Col } from 'inferno-bootstrap'
// Formlib widget for ImageField
import StickyRow from '../../widgets/StickyRow'
import { MainMenu } from '../../widgets/MainMenu'
import Page from './ListTemplate'

import Logo from '../../widgets/Logo'

import '../../entities/User/EditForm.scss'
import './Dashboard.scss'

import { IUser } from 'influence-app-entities/lib/interfaces/User'
import { IApiClient, ISessionManager } from '../../interfaces/app'

export default class DashboardPage extends Component {

  constructor (props, context) {
    super(...arguments)

    this.state = {
      value: { blogName: safeGet(() => new ISessionManager({ registry: context.registry }).getCurrentUser().blogName) },
      validationError: undefined,
      actionBarBoundary: {top: 0, bottom: 0}
    }
  }

  static async fetchData ({registry, match, location, router}) {
    const { data } = await new IApiClient({ registry }).query({
      URI: '/session'
    })

    if (!data) return undefined

    if (IUser.providedBy(data)) {
      // Check that user has accepted terms and conditions
      if (safeGet(() => data.termsAndConditions.name) != 'onboarding-terms') {
        return safeGet(() => router.history.push('/register_2'))
      }
    }

    return data
  }


  renderToolbar () {
    return (
      <StickyRow className="Onboarding-AcceptTerms">
        <Col className="_ButtonContainer">
          {/*<Button
            className="Onboarding-Button Onboarding-Action-Button"
            color="primary"
          onClick={() => {}} href="#instagram">Create!</Button>*/}
        </Col>
      </StickyRow>
    )
  }

  render (props, state, context) {
    const { match } = this.props
    const { isExact, path } = match
    
    const currentUser = new ISessionManager({ registry: context.registry }).getCurrentUser() || {}
    if (!currentUser) return null

    const cls = classnames({
      "not-on-top": !isExact,
      "isAdminRoot": path == '/admin'
    }, this.props.className
    )

    return (
        <Page
          type="dashboard"
          match={match}>
            <div className={cls}> 
              <Container fluid className="Dashboard">
                <p>TODO: Create content for dashboard</p>
              </Container>
              <Logo className="PageLogo" />
            </div>
        </Page>
    )
  }
}