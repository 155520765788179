import { Link } from 'inferno-router'
import './SitePageFooter.scss'

export default function SitePageFooter () {
  return (
    <PageFooterContainer>
      <Link to="/license-agreement">Användarvillkor</Link>
      <Link to="/privacy">Integritetspolicy</Link>
      <Link to="/contact">Kontakta Veckoappen</Link>
      <PageFooter>
        <PageFooterBody>Veckoappen © Copyright 2020</PageFooterBody>
      </PageFooter>
    </PageFooterContainer>
  )
}

function PageFooterContainer (props) {
  return (
    <div className="PageFooterContainer">
      {props.children}
    </div>
  )
}

function PageFooter (props) {
  return (
    <div className="PageFooter">
      {props.children}
    </div>
  )
}

function PageFooterBody (props) {
  return (
    <div className="PageFooterBody">
      {props.children}
    </div>
  )
}