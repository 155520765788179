"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MobileDocField = void 0;

var _componentRegistry = require("component-registry");

var _isomorphicSchema = require("isomorphic-schema");

var _fields = require("../interfaces/fields");

var MobileDocField = (0, _componentRegistry.createObjectPrototype)({
  implements: [_fields.IMobileDocField],
  extends: [_isomorphicSchema.BaseField],
  constructor: function constructor(options) {
    this._IBaseField.constructor.call(this, options);
  }
});
exports.MobileDocField = MobileDocField;