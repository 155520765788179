import { Component } from 'inferno'
import classnames from 'classnames'
import querystring from 'querystring'
import { safeGet } from 'safe-utils'
import { i18n } from '../i18n'
import { Link } from 'inferno-router'
import {
  Nav, NavItem, NavLink
} from 'inferno-bootstrap'
import { Avatar } from './Avatar'
import { Animated } from 'inferno-animation'
import {
  Form,
} from 'inferno-bootstrap'
import { 
  Schema,
  TextField,
  i18n as i18nStr
} from 'isomorphic-schema'
import { SearchField } from '../customFields/SearchField'
import '../formlib/SearchField'
import { FormRows } from 'inferno-formlib'

import { IApiClient, ISessionManager } from '../interfaces/app'

import '../pages/admin/Dashboard.scss'
import './MainMenu.scss'

export const MainMenu = (props, context) => {
  const { type } = context.router.route.match.params
  const { pathname } = context.router.route.location
  const currentUser = new ISessionManager({ registry: context.registry }).getCurrentUser() || {}
  let avatarUrl = currentUser.avatarUrl

  let publicMode = true
  let activePath, prefix
  if (pathname.startsWith('/admin')) {
    activePath = pathname.substr(5)
    prefix = '/admin'
    publicMode = false
  }
  else {
    activePath = pathname
    prefix = ''
  }

  const { location } = context.router.history
  const { search } = querystring.parse(location.search.replace(/^\?/, ''))
  const searchMode = (search ? true : false)

  return (
    <div className={classnames({
      MainMenu: true,
      search:  search,
      isAdminRoot: pathname == '/admin', // use this to determine if menu should be visible on mobile
      active: props.active
    }, props.classNames)}>
      <div className="MainMenu-Background" />
      <div className="MainMenu-Slot LeftSlot">
        <Avatar image={avatarUrl} active={props.active} targetElId="AvatarImg" />
        <AdminTypeMenu />
        <LogoutMenu />
      </div>
      <div className="MainMenu-PageNav">
        <PageNavMenu />
      </div>
    </div>
  )
}

export function PageNavMenu(props, context) {
  const { pathname } = context.router.route.location
  const tmp = pathname.split('/')
  tmp.pop()
  const newPath = tmp.join('/')
  return (
    <Nav className="PageNavMenu">
      <NavItem>
        <NavLink tag={Link} 
          onClick={(e) => {
            context.router.history.push(newPath)
          }}>{i18n('PageNavMenu-back', 'Back')}</NavLink>
      </NavItem>
    </Nav>
  )
}


const filterSchema = new Schema('Filter Schema', {
  search: new SearchField({
    label: 'Filter list:',
    placeholder: i18nStr('FilterSchema-search-placeholder', 'Sök...')
  })
})


class SearchMenu extends Component {
  constructor () {
    super(...arguments)
    this.didUpdate = this.didUpdate.bind(this)
  }
  
  didUpdate (propName, value) {
    if (propName === 'search') {
      const { router } = this.context
      router.history.push(router.route.location.pathname + (value ? '?' + querystring.encode({ search: value }) : ''))
    }
  }

  render () {
    const { location } = this.context.router.history
    const { search } = querystring.parse(location.search.replace(/^\?/, ''))
    const filterValue = { search }

    return (
      <Form className="SearchContainer">
        <FormRows schema={filterSchema} value={filterValue} onChange={this.didUpdate} />
      </Form>
    )
  }
}

async function doLogout(e, context) {
  e.preventDefault()

  await new IApiClient().delete({
    URI: '/session'
  })

  await new ISessionManager({ registry: context.registry }).refreshCurrentUser()

  // I have short timeout to allow the animation to play
  setTimeout(() => {
    window.location.reload(true)
  }, 500)
}

export function LogoutMenu(props, context) {
  const currentUser = new ISessionManager({ registry: context.registry }).getCurrentUser()
  if (currentUser) {
    return (
      <Nav className="LogoutMenu">
        <NavItem>
          <NavLink tag={Link} 
            to='/logout' onClick={(e) => doLogout(e, context)}>{i18n('MainMenu-logout', 'Logout')}</NavLink>
        </NavItem>
      </Nav>
    )
  }
  else {
    return (
      <Nav className="LogoutMenu">
        <NavItem>
          <NavLink tag={Link} 
            to='/admin'>{i18n('MainMenu-login', 'Login')}</NavLink>
        </NavItem>
      </Nav>
    )
  }
}

export function AdminMenu(props, context) {
  const { pathname } = context.router.route.location
  const currentUser = new ISessionManager({ registry: context.registry }).getCurrentUser()
  if (!safeGet(() => currentUser.roles.indexOf('admin') >= 0 )) {
    return null
  }

  return (
    <Nav className="AdminMenu">
      <NavItem>
        <NavLink tag={Link} active={pathname.startsWith('/admin')}
          to='/admin/Invitation'>{i18n('AdminMenu-admin', 'Admin')}</NavLink>
      </NavItem>
    </Nav>
  )
}

export const AdminTypeMenu = (props, context) => {
  const { pathname } = context.router.route.location
  const showTypes = [
    'Page',
    'LicenseAgreement',
    'User',
    'NameDay'
    // 'Invitation'
  ]
  return (
    <Nav>
      <NavItem>
        <NavLink tag={Link}
          active={`/admin/dashboard` === pathname}
          to={`/admin/dashboard`}>{i18n(`AdminTypeMenu-Dashboard`, 'Dashboard')}</NavLink>
      </NavItem>
      {showTypes.map((type) => (
        <NavItem>
          <NavLink tag={Link}
            active={`/admin/${type}` === pathname}
            to={`/admin/${type}`}>{i18n(`AdminTypeMenu-${type}`, type)}</NavLink>
        </NavItem>
      ))}
    </Nav>
  )
}
