import { Component } from 'inferno'
import { i18n } from '../i18n'
import {
  Schema,
  EmailField,
  PasswordField,
  i18n as i18nStr
} from 'isomorphic-schema'
import { FormRows } from 'inferno-formlib'
import { IApiClient, ISessionManager } from '../interfaces/app'
//import 'inferno-formlib/lib/widgets/FormRow'
//import 'inferno-formlib/lib/widgets/InputField'
//import 'inferno-formlib/lib/widgets/PasswordField'
import { Container, Row } from 'inferno-bootstrap'
import InstagramIcon from './InstagramIcon'
import FacebookButton from './FacebookButton'

import {
  Form,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter
} from 'inferno-bootstrap'

import logo_colored_svg from '../img/logo/logo-colored.svg'
import './Form.scss'
import './LoginWidget.scss'

const loginForm = new Schema('Login Form', {
  email: new EmailField({
    label: 'E-mail',
    required: true
  }),
  password: new PasswordField({
    label: 'Password',
    required: true
  })
})

let _env_ = (typeof window !== 'undefined' && window.__env__ ? window.__env__ : process.env )
const INSTAGRAM_CLIENT_ID = _env_.INSTAGRAM_CLIENT_ID
const INSTAGRAM_REDIRECT_URI = _env_.INSTAGRAM_LOGIN_CALLBACK_URI
const FACEBOOK_CLIENT_ID = _env_.FACEBOOK_CLIENT_ID
const FACEBOOK_REDIRECT_URI = _env_.FACEBOOK_LOGIN_CALLBACK_URI

export default class LoginWidget extends Component {
  constructor () {
    super (...arguments)

    this.state = {
      formValue: {},
      validationErrors: undefined,
      submitted: false
    }

    this.doSubmit = this.doSubmit.bind(this)
    this.didChange = this.didChange.bind(this)
  }

  doInstagramLogin (e) {
    e.preventDefault()
    window.location.href = `https://api.instagram.com/oauth/authorize/?client_id=${INSTAGRAM_CLIENT_ID}&redirect_uri=${INSTAGRAM_REDIRECT_URI}&scope=basic&response_type=code`
    // window.location.href = `https://api.instagram.com/oauth/authorize/?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&scope=user_profile,user_media&response_type=code`
  }

  doFacebookLogin (e) {
    // If your app asks for more than than public_profile and email, it will require
    // review by Facebook before your app can be used by people other than the app's
    // developers.
    // https://developers.facebook.com/docs/facebook-login/permissions/review/
    e.preventDefault()
    window.location.href = `https://www.facebook.com/v7.0/dialog/oauth?client_id=${FACEBOOK_CLIENT_ID}&redirect_uri=${FACEBOOK_REDIRECT_URI}&scope=public_profile,email&response_type=code&state=${encodeURIComponent("type=facebook")}`
  }

  doSubmit (e) {
    e.preventDefault()
    const formData = this.state.formValue
    const errors = loginForm.validate(formData)
    this.setState({
      validationErrors: errors,
      submitted: true
    })

    if (!errors) {
      const client = this.context.client
      // TODO: The data cache stores params as part of the cache key so the password is easily accesible
      // I should probably perform a manual login using fecth and store current user in local state.

      // TODO: Post to create sessiom
      new IApiClient().create({
        URI: '/session',
        data: Object.assign({ authMethod: 'AccountEmail' }, formData),
        invalidate: '*'
      }).then(() => {
        new ISessionManager().refreshCurrentUser()
        this.context.router.history.push(this.context.router.history.location.pathname)
      })
    }
  }

  didChange (propName, value) {
    const val = this.state.formValue
    val[propName] = value
    this.setState({
      formValue: val
    })
    // This is where you would call this.props.onChange
  }

  render () {
    const isValid = this.state.validationErrors ? false : undefined

    return (
      <Form onSubmit={this.doSubmit} className="login-pane">
      <Card className="LoginWidget">
          <img id="Login-Logo" src={logo_colored_svg} />
          <CardHeader>Login</CardHeader>
          <CardBody>
            <FormRows schema={loginForm} validationErrors={this.state.validationErrors} value={this.state.formValue} onChange={this.didChange} />
            
            <Container>
              <Row className="LoginWidget-Action">
                <Button type="submit">Login</Button>
              </Row>
              <Row className="LoginWidget-Separator">
                <i>or</i>
              </Row>
              <Row className="LoginWidget-Social">
                {/*<Button
                  className="has-icon LoginWidget-Button"
                  color="primary"
                  href="#login-instagram"
                onClick={this.doInstagramLogin}><InstagramIcon className="icon" /><label>Sign in with Instagram</label></Button>*/}
                <FacebookButton id="FB-Login-Btn" type="login" onClick={this.doFacebookLogin} />
              </Row>
            </Container>
          </CardBody>
          <CardFooter>
            <a href="#">TODO: Password reset...</a>
          </CardFooter>
        </Card>
      </Form>
    )
  }
}