import { Component } from 'inferno'
import { safeJoin } from 'safe-utils'
import { Animated } from 'inferno-animation'
import SitePageFooter from '../../widgets/SitePageFooter'

import { IApiClient, IPageManager } from '../../interfaces/app'
import { ILiveItem, INotificationManager } from '../../interfaces/presentation'
import { i18n } from '../../i18n'
import logo_colored_svg from '../../img/logo/logo-colored.svg'

import './RegisterStep.scss'


export default class Page extends Component {

 static async fetchData ({registry, match, location}) {
    const { data } = await new IApiClient({ registry }).query({
      URI: `/admin/Invitation/${match.params.id}`
    })

    const name = safeJoin([data.firstName, data.lastName], ' ')
    new IPageManager({ registry }).setMetaData({
      title: 'Invitation to Memly.io',
      description: `Hi${name ? ' ' + name : ''}! We have invited you to join our community because you are an inspiring content creator. To sign up, all you need is your Facebook account.`,
      url: 'https://memly.io' + location.pathname
    })
    
    return data
  }

  render(props) {
    const data = this.props.fetchData

    if (!data) {
      return null
    }

    const Content = new ILiveItem(data).Component

    return (
      <Animated key="me" prefix="PageNav">
        <div className="Fullpage Onboarding">
          <img id="Page-Logo" src={logo_colored_svg} />
          <Content context={data} />
          <SitePageFooter />
        </div>
      </Animated>
    )
  }
}

