import { Component } from 'inferno'
import { Adapter } from 'component-registry'

import classnames from 'classnames'
import { i18n } from '../../i18n'

import { Card } from 'inferno-bootstrap'
import { Thumbnail } from '../../widgets/ResponsiveImage'

import { recalcMasonryItemHeight } from '../common/masonry'

import { IMediaSelectItem } from '../../interfaces/presentation'
import { IMedia } from 'influence-app-entities/lib/interfaces/Media'
import './MediaSelectItem.scss'

function onSelect (props, event) {
  event.preventDefault()
  props.onSelect(props.context)
}

class MediaSelectItem extends Component {

  state = {
    rowSpan: 15 // Set a good starting point to improve layout speed
  }

  didUpdateMasonry = (rowSpan) => this.setState({ rowSpan, sizeSet: true })

  componentDidMount () {
    recalcMasonryItemHeight.call(this, this._masonryItemEl, this.didUpdateMasonry)
  }

  render (props) {
    return (
      <Card className={classnames("masonry-item-container media MediaSelectItem", { loaded: this.state.sizeSet })}
        style={{ "grid-row-end": `span ${this.state.rowSpan}` }}
        onClick={(e) => onSelect(props, e)}>
        <div ref={(el) => this._masonryItemEl = el} className="masonry-item">
          <Thumbnail {...props.context} /> 
          <div className="media-body">
            <caption>{props.context.shortDescription}</caption>
          </div>
        </div>
      </Card>
    )
  }
}

new Adapter({
  implements: IMediaSelectItem,
  adapts: IMedia,
  Component: MediaSelectItem
})
