import { Component } from 'inferno'
import { i18n } from '../../../i18n'
import { Button } from 'inferno-bootstrap'
import { Link } from 'inferno-router'
import { IApiClient, IPageManager, ISessionManager } from '../../../interfaces/app'
import PageTemplate from '../../../widgets/PageTemplate'

import './index.scss'
import placeholder_jpg from './placeholder.jpg'
import all_screens_png from './all_screens.png'
import app_store_se_svg from './app-store-se.svg'
import { safeGet } from 'safe-utils'

const env = (typeof window === 'undefined' ? process.env : window.__env__)
const { FRONTEND_BASE_URI } = env

export default class Page extends Component {

  static async fetchData ({registry, match, location}) {
    new IPageManager({ registry }).setMetaData({
      title: 'Veckoappen - Planera din vardag',
      description: `Veckoappen gör din vardagsplanering enkel och stressfri. Få en tydlig översikt och upplev känslan av att få mer gjort.`,
      url: FRONTEND_BASE_URI
    })
  }

  componentDidMount() {
    window.scroll(0, 0)
  }

  render () {
    return (
      <PageTemplate>
        <Hero />
        <Content />
      </PageTemplate>
    )
  }
}

function Hero () {
  return (
    <div className="Hero">
      <img src={all_screens_png} />
      <div className="Hero-Divider">
        <div className="Hero-Shadow" />
        <div className="Hero-Blockers" />
      </div>
    </div>
  )
}

function Content () {
  return (
    <div className="Content">
      <div className="Content-Header">
        <h1>Planera din vardag med Veckoappen</h1>
      </div>
      <div className="Content-Section">
        <p>Veckoappen är kalendern som hjälper dig att planera din vardag på ett enkelt och naturligt sätt.</p>
        <p>Vi kallar det <b>casual planning.</b> En balans mellan ordning och frihet som bättre passar hur livet faktiskt ser ut.</p>
        <p>Vårt mål är att minska den vardagliga stressen och samtidigt hjälpa dig att få mer gjort.</p>
        <p>I Veckoappen är det enkelt att flytta en aktivitet du inte hinner med till nästa dag eller vecka, och i översikten ser du snabbt de dagar där du inte blev klar med allt du hade planerat.</p>
        <p>Planera, genomför och rensa. Precis så enkelt som planering borde vara.</p>
        <p>Veckoappen finns till iOS i <a href="https://apps.apple.com/se/app/veckoappen/id418040769">App Store.</a></p>
      </div>
      <div className="Content-AppStore">
        <a href="https://apps.apple.com/se/app/veckoappen/id418040769">
          <img src={app_store_se_svg} />
        </a>
      </div>
    </div>
  )
}