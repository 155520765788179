import { createInterfaceClass } from 'component-registry';
var Interface = createInterfaceClass('influence-app-entities');
import { Schema, BoolField, TextField, SelectField, ListField, ObjectField, i18n, DateField } from 'isomorphic-schema';
import { ImageField } from '../customFields/ImageField';
import { IAccountEmail } from './AccountEmail';
var IUser = new Interface({
  name: 'IUser',
  schema: new Schema('User Schema', {
    avatarUrl: new ImageField({
      label: i18n('IUser-avatarUrl-label', 'Profile Image'),
      placeholder: i18n('IUser-avatarUrl-placeholder', 'Drag and drop...'),
      help: i18n('IUser-avatarUrl-help', 'Something reasonable please')
    }),
    firstName: new TextField({
      label: i18n('IUser-firstName-label', 'First Name'),
      required: true
    }),
    lastName: new TextField({
      label: i18n('IUser-lastName-label', 'Last Name'),
      required: true
    }),
    accounts: new ListField({
      label: i18n('IUser-accounts-label', 'Kopplade Konton'),
      valueType: new ObjectField({
        label: i18n('IUser-accountsEmail-label', 'E-mail account'),
        objectFactoryName: 'AccountEmail',
        interface: IAccountEmail
      }),
      required: true
    }),
    roles: new ListField({
      label: i18n('IUser-roles-label', 'Systemroller'),
      valueType: new SelectField({
        options: [{
          name: 'admin',
          title: i18n('IUser-roles-option-admin', 'Admin')
        }, {
          name: 'customerService',
          title: i18n('IUser-roles-option-customerService', 'Community Manager')
        }, {
          name: 'member',
          title: i18n('IUser-roles-option-member', 'Member')
        }],
        valueType: new TextField({})
      }),
      minItems: 1,
      required: true
    })
  })
});
export { IUser };