import { createElement} from 'inferno-create-element'

export const utilityToCard = (utility) => {
  return {
    name: utility._name,
    type: utility.type,
    render: (props) => createElement(utility.RenderComponent, props)
  }
}

export const utilityToAtom = (utility) => {
  return {
    name: utility._name,
    type: utility.type,
    component: utility.RenderComponent,
    render: (props) => createElement(utility.RenderComponent, props)
  }
}
