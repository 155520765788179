import { globalRegistry, Adapter } from 'component-registry'

import { Link } from 'inferno-router'
import {
  Row,
  Col,
  Nav,
  NavLink
} from 'inferno-bootstrap'
import { Thumbnail } from '../../widgets/ResponsiveImage'
import { safeJoin } from 'safe-utils'
import { i18n } from '../../i18n'

import { IApiClient } from '../../interfaces/app'
import { IListItem, INotificationManager } from '../../interfaces/presentation'
import { IUser } from 'influence-app-entities/lib/interfaces/User'
import { IUserWorkflow } from 'influence-app-entities/lib/interfaces/workflows'
import '../common/ListItem.scss'
import './ListItem.scss'

const workflowStates = IUserWorkflow.schema.workflowStates

function doChangeWorkflowState (obj, newState) {
  obj._workflows.userWorkflow = newState
  new IApiClient().update({
    URI: `/content/Invitation/${obj._id}`,
    data: obj,
    invalidate: `/content/Invitation`
  }).then(({data}) => {
    // Show success indicator at center of screen
    new INotificationManager().showSuccessMessage()
  })
}

function WorkflowActions ({ obj }, context) {
  
  return (
    <Nav className="actions" pills horizontal="end">
      {Object.keys(workflowStates).map((key) => {
        // Don't return the current state since we don't want to transition to ourself
        const isActive = (obj._workflows && obj._workflows.userWorkflow) === key
        if (isActive) return null

        return (
          <NavLink href="#" active={isActive} onClick={(e) => doChangeWorkflowState(obj, key)}>
            {i18n('WorkflowAction-to', 'till ') + i18n(workflowStates[key].title)}
          </NavLink>
        )
      })}
    </Nav>
  )
}


function ListItem ({ context: user }) {
  return (
    <Row className="list-item">
      <Col className="list-item-body media">
        <Thumbnail className="avatar mr-3" image={user.avatarUrl} />
        <div className="media-body list-item-body">
          <Link to={`/admin/User/${user._id}`}>{safeJoin([user.firstName, user.lastName], ' ')}</Link>
          <h3>{safeJoin([user._id, safeJoin(user.roles, ', ')], ' - ')}</h3>
        </div>
      </Col>
      <Col className="list-item-workflow">        
        <h2 className="status">
          {user._workflows && user._workflows.userWorkflow}
        </h2>
        <WorkflowActions obj={user} />
      </Col>
    </Row>
  )
}

new Adapter({
  implements: IListItem,
  adapts: IUser,
  Component: ListItem
})