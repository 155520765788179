import './config'
import { Component } from 'inferno'
import { Utility } from 'component-registry'
import { i18n } from './i18n'
import classnames from 'classnames'
import { Switch, Redirect, Route, Link, matchPath } from 'inferno-router'
import { RestishRoute } from './utils/RestishRoute'
import { LayeredSwitch } from './LayeredSwitch'
import querystring from 'querystring'
import doAllAsyncBefore from './utils/doAllAsyncBefore'
import ApiClient from './utils/ApiClient'
import { IApiClient, ISessionManager, IPageManager } from './interfaces/app'
import { INotificationManager } from './interfaces/presentation'

/**
 * Register the translation utility
 */
import './i18n'
import './registerServiceWorker';

/**
 * Import data entity pages
 */
import './entities'

/**
 * General pages
 */ 
import './App.scss';
import ErrorPage from './pages/Error'
import StartPage from './pages/public/Start'
import TermsAndCondtionsPage from './pages/onboarding/RegisterStep2'
import LicenseAgreement from './pages/public/LicenseAgreement'
import ContactPage from './pages/public/Contact'
import PrivacyPage from './pages/public/Privacy'

/**
 * User onboarding Pages
 */  
import RegisterStep1 from './pages/onboarding/RegisterStep1'
import RegisterStep2 from './pages/onboarding/RegisterStep2'
import RegisterStep3 from './pages/onboarding/RegisterStep3'

/**
 * Admin Pages
 */   
import NullPage from './pages/Null'
import DashboardPage from './pages/admin/Dashboard'
import EditPage from './pages/admin/Edit'
import { ListPage } from './pages/admin/List'
import { ListPage as FixedContentList } from './pages/admin/List-Page'
import { CreatePage } from './pages/admin/CreateAdmin'
import ImportPage from './pages/admin/Import'

/**
 * Some general widgets
 */
import { Animated } from 'inferno-animation'
import LoginWidget from './widgets/LoginWidget'
import { Placeholder } from './widgets/Placeholder'

import IconOk from './widgets/icon_ok'
import { IDeserialize } from 'influence-interfaces/object'

/**
 * Set up env vars
 */
let _env_ = (typeof window !== 'undefined' && window.__env__ ? window.__env__ : process.env )
const { API_URI } = _env_

/**
 * Debug animations (development only)
 */
if (typeof window !== 'undefined' && _env_.NODE_ENV !== 'production') {
  // Activate this to debug
  // window.debugAnimations = true
}

/**
 * Deetermine if the page is public and what section it belongs to
 * @param {*} props 
 * @param {*} context 
 */
function _getPublicSection (props, context) {
  const pathname = context.router.route.location.pathname
  const routes = props.children
  
  // TODO: Need to update currentRoleManager
  let exactMatchFound = false
  const matches = routes.map((route) => {
    const match = matchPath(pathname, route.props)
    // Don't match 
    if (exactMatchFound) return null

    if (route.props.exact) {
      if (match && match.isExact) {
        exactMatchFound = true
      }
      else {
        return null
      }
    }

    return {
      match,
      asyncBefore: route.props.asyncBefore,
      public: route.props.public,
      section: route.props.section
    }
  }).filter((route) => route && route.match)

  const match = (Array.isArray(matches) ? matches[0] : undefined)

  return {
    currentPageIsPublic: match && match.public,
    currentSection: match && match.section,
  }
}

/**
 * This is the actual admin root page component
 */
class App extends Component {
  constructor (props, context) {
    super(...arguments)

    const { registry, restishCache, serverState, browserEnv, ...staticContext } = (typeof window === 'undefined' ? context.router.staticContext : new IDeserialize('admin-api').deserialize({
      restishCache: window.__restishCache__,
      serverState: window.__hydratedState__,
      browserEnv: window.__env__
    }))

    const { currentUser, currentRoleManager, fetchDataCache, ...data } = serverState || {}
    const { currentPageIsPublic, currentSection } = _getPublicSection(props, context)

    this.state = {
      isFirstRender: true,
      context: Object.assign(staticContext, context), // TODO: Cherrypick from staticContext?
      currentUser,
      currentRoleManager,
      currentPageIsPublic,
      currentSection,
      data,
      fetchDataCache,
      showSuccessMessage: false
    }

    if (registry) {
      this._registry = registry
    }

    this.forceUpdate = this.forceUpdate.bind(this)
    
    if (typeof window !== 'undefined') {
      this._createNotificationManager()
      this._createApiClientUtility(restishCache)
      this._createSessionManagerUtility()
      this._createPageManagerUtility()
    
      if (context.router.route.location.search.match(/[&?]code=([^&\s]*)/)) {
        return this.performLogin(context.router)
      }

      if (window.__hydratedState__ === undefined) {
        // If we weren't rendered by SSR we need to update the user
        this.updateCurrentUser()
      }
    }
  }

  _createNotificationManager = () => {
    new Utility({
      implements: INotificationManager,
      showSuccessMessage: this._showSuccessMessage //.bind(this)
    })
  }

  _createSessionManagerUtility = () => {
    new Utility({
      implements: ISessionManager,
      getCurrentUser: () => this.state.currentUser,
      refreshCurrentUser: this.updateCurrentUser // .bind(this)
    })
  }

  _createPageManagerUtility = () => {
    new Utility({
      implements: IPageManager,
      getMetaData: () => this.state.pageMetaData,
      setMetaData: (metaData) => {
        this.setState({
          pageMetaData: metaData
        })
        document.title = metaData.title || document.title
      }
    })
  }

  _createApiClientUtility = (restishCache) => {
    const apiClient = new ApiClient({
      API_URI,
      forceUpdate: this.forceUpdate //.bind(this)
    })
    apiClient.rehydrate(restishCache)

    new Utility({
      implements: IApiClient,
      invalidateCache: apiClient.invalidateCache.bind(apiClient),
      query: apiClient.query.bind(apiClient),
      create: apiClient.create.bind(apiClient),
      update: apiClient.update.bind(apiClient),
      delete: apiClient.delete.bind(apiClient)
    })
  }

  performLogin = (router) => {
    const { route } = router
    const { search } = route.location
    // Hide the Instagram login code
    let tmp = search.replace(/(&*code=[0-9a-f&]*)/, '')
    // Remove trailing ?
    tmp = (tmp === '?' ? '' : tmp)

    if (typeof window !== 'undefined') {
      window.history.replaceState(undefined, document.title, window.location.href.replace(search, tmp))
    }

    if (!this.state.currentUser) {
      // Only perform login if we have beeen logged out
      setTimeout(() => {
        // This code can also be found in server.js for SSR
        const searchParams = new URLSearchParams(search)
        const code = searchParams.get('code')

        const stateObj = {}
        if (searchParams.has('state')) {
          searchParams.get('state').split(',').forEach((tmp) => { let t = tmp.split('='); stateObj[t[0]] = t[1] })
        }

        if (stateObj.type === 'facebook') {
          return new IApiClient().create({
            URI: '/session',
            data: { authMethod: 'AccountFacebook', code },
            invalidate: '*'
          }).then(({ data }) => {
            this.setState({
              'currentUser': data
            })
            this._fetchData(this.props, this.context)
          })
        }
        else {
          return new IApiClient().create({
            URI: '/session',
            data: { authMethod: 'AccountInstagram', code },
            invalidate: '*'
          }).then(({ data }) => {
            this.setState({
              'currentUser': data
            })
            this._fetchData(this.props, this.context)
          })
        }
      }, 1)
    }
    else {
      return Promise.resolve()
    }
  }

  updateCurrentUser = async (check) => {
    const res = await new IApiClient().query({
      URI: '/session',
      cache: false
    })

    const { data } = res

    this.setState({
      currentUser: data
    })

    // Check that we are still logged in
    // TODO: Find other way to check if logged in
    /*
    const nextCheckIn = data ? data.nextCheckAt - Date.now() : 60 * 1000
    if (nextCheckIn) {
      setTimeout(async () => {
        // await new IApiClient().delete({
        //  URI: '/session'
        // })
        this.updateCurrentUser(true)
      }, nextCheckIn)
    }
    */

    if (!check) {
      this._fetchData(this.props, this.context)
    }
  }

  _showSuccessMessage = () => {
    this.setState({
      showSuccessMessage: true
    })

    // Update fetched data to sync all views if needed
    this._fetchData(this.props, this.context)

    setTimeout(() => {
      this.setState({
        showSuccessMessage: false
      })
    }, 800)
  }

  _fetchData = async (nextProps, nextContext) => {
    let search
    if (nextContext.router.route.location.search) {
      search = querystring.parse(nextContext.router.route.location.search.replace(/^\?/, ''))
    }
    // TODO: Refactor fetchData. Suggestion
    // location = Object.assign({}, nextContext.router.route.location, { search })
    // const data = fetchData({ context, location, match, router })
    // 
    const matches = await doAllAsyncBefore ({
      registry: this._registry,
      pathname: nextContext.router.route.location.pathname,
      location: Object.assign({}, nextContext.router.route.location, { search }),
      router: nextContext.router,
      routes: nextProps.children,
      matchCallback: () => {}
    })

    const newCache = matches.map((match) => { return { fetchData: match.res } })

    const { currentPageIsPublic, currentSection } = _getPublicSection(nextProps, nextContext)

    this.setState({
      context: nextContext,
      fetchDataCache: newCache,
      currentSection
    })
    
    // We update live/edit on next frame so everything has been mounted
    return requestAnimationFrame(() => this.setState({
      currentPageIsPublic
    }))
  }

  componentWillReceiveProps (nextProps, nextContext) {
    if (nextContext.router.route.location.key !== this.context.router.route.location.key
          || Object.keys(this.state.context).length === 0) {
      this._fetchData(nextProps, nextContext)
    }
  }

  componentDidMount () {
    this.setState({
      isFirstRender: false
    })
  }

  getChildContext = () => {
    return {
      registry: this._registry,
      forceUpdate: this.forceUpdate,
      // asyncBeforeData is a list of objects who's props will be assigned as props for the rendered component. This is done by LayeredSwitch in combination with RestishRoute
      asyncBeforeData: this.state.fetchDataCache,
      data: this.state.data,
      store: {
        setState: (data) => this.setState(Object.assign(this.state.data, data))
      },
      ...this.state.context
    }
  }

  renderSuccessMessage () {
    if (!this.state.showSuccessMessage) return null

    return (
      <Animated key="success" prefix="SucessAnimation">
        <div className="SuccessMessage">
          <IconOk />
        </div>
      </Animated>
    )
  }

  render () {
    const loggedIn = this.state.currentUser !== undefined
    const showLogin = !loggedIn && !this.state.currentPageIsPublic
    const cls = {
      'InfernoAnimation--noAnim': this.state.isFirstRender,
      login: showLogin,
      public: this.state.currentPageIsPublic,
      [`section-${this.state.currentSection}`]: this.state.currentSection,
      'isLive': this.state.currentPageIsPublic,
      signedIn: this.state.currentUser ? true : false
    }

    return (
      <div className={classnames('App', cls)}>
        {showLogin &&
          <Animated key='login' prefix="FadeIn">
            <div className="login-page">
              <LoginWidget onLogin={this.forceUpdate}/>
            </div>
          </Animated>
        }
        <div key="mainContent" className="content">
          {!showLogin && <LayeredSwitch>{this.props.children}</LayeredSwitch>}
        </div>
        {this.renderSuccessMessage()}
      </div>
    )
  }
};

function injectType (type, fn) {
  return (namedArgs) => {
    const { match } = namedArgs
    match.params.type = type
    return fn.call(fn, namedArgs)
  }
}

function appFactory () {

  return (
      <App>
        <RestishRoute public exact path={`/`} component={ StartPage } asyncBefore={StartPage.fetchData} />
        <RestishRoute public exact path={`/error/:errorCode`} component={ ErrorPage } asyncBefore={ErrorPage.fetchData} />
        
        <RestishRoute public exact section="onboarding" exact path={`/invite/:id`} component={ RegisterStep1 } asyncBefore={ injectType('Invitation', RegisterStep1.fetchData) } />
        <RestishRoute exact section="onboarding" path={`/register_2`} component={ RegisterStep2 } asyncBefore={ RegisterStep2.fetchData } />
        <RestishRoute exact section="onboarding" path={`/register_3`} component={ RegisterStep3 } />
        
        {/*<RestishRoute public exact path={`/privacy-policy`} component={ LicenseAgreement } />*/}
        <RestishRoute public exact path={`/license-agreement`} component={ LicenseAgreement } asyncBefore={ LicenseAgreement.fetchData } />
        <RestishRoute public exact path={`/contact`} component={ ContactPage } asyncBefore={ ContactPage.fetchData } />
        <RestishRoute public exact path={`/privacy`} component={ PrivacyPage } asyncBefore={ PrivacyPage.fetchData } />
        
        <RestishRoute exact section="admin" path={`/admin/:type/create`} component={ CreatePage } asyncBefore={ CreatePage.fetchData } />
        <RestishRoute exact section="admin" path={`/admin/Page`} component={ FixedContentList } asyncBefore={ injectType('Page', FixedContentList.fetchData) } />
        <RestishRoute exact section="admin" path={`/admin/dashboard`} component={ DashboardPage } asyncBefore={ DashboardPage.fetchData } />
        
        <RestishRoute exact section="admin" path={`/admin/import/:type`} component={ ImportPage } asyncBefore={ ImportPage.fetchData } />
        <RestishRoute section="admin" path={`/admin/:type`} component={ ListPage } asyncBefore={ ListPage.fetchData } />
        <RestishRoute section="admin" path={`/admin/:type/:id`} component={ EditPage } asyncBefore={ EditPage.fetchData } />
        <RestishRoute exact section="admin" path={`/admin`} component={ DashboardPage } asyncBefore={ DashboardPage.fetchData } />
        
        <Redirect to="/" />
      </App>
  )
}

export { 
  appFactory,
  doAllAsyncBefore,
  ApiClient
}