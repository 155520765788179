import './masonry.scss'
/**
 * 
 * @param {DOMel} el - element containing masonry item
 * @param {function} onUpdate(rowSpan) – callback when row span has changed
 * @example
 * 
 * class Item extends Component {
 *  state = {
 *     rowSpan: 15 // Set a good starting point to improve layout speed
 *  }
 * 
 *  didUpdateMasonry = (rowSpan) => this.setState({ rowSpan, sizeSet: true }
 * 
 *  componentDidMount () {
 *    recalcMasonryItemHeight.call(this, this._masonryItemEl, this.didUpdateMasonry)
 *  }
 * 
 *  render () {
 *    return (
 *      <div style={{ "grid-row-end": `span ${this.state.rowSpan}` }}>
 *        <div ref={(el) => this._masonryItemEl = el}>...</div>
 *      </div>
 *    )
 *  }
 * }
 * 
 * Set rowSpan to a reasonable default to get improved layout speed.
 * 
 * In the above example you would also use sizeSet to animate opacity in order to get a fade in effect.
 */
function recalcMasonryItemHeight (el, onUpdate, _oldRowSpan) {
  // Only allow a single masonry recalc to be running and make sure it
  // hasn't been unmounted
  // TODO: Should we check if the element has changed and cancel the anim frame instead?
  if (this._recalcMasonryItemHeightAnimFrame || this.$UN) return

  const $this = this
  this._recalcMasonryItemHeightAnimFrame = requestAnimationFrame(() => {
    const { rowGap, rowHeight } = $this.props
    /*
     * Spanning for any brick = S
     * Grid's row-gap = G
     * Size of grid's implicitly create row-track = R
     * Height of item content = H
     * Net height of the item = H1 = H + G
     * Net height of the implicit row-track = T = G + R
     * S = H1 / T
    */
   let rowSpan
    if (el.getBoundingClientRect) {
      rowSpan = Math.round((el.getBoundingClientRect().height + rowGap)/(rowHeight + rowGap))
  
      /* Set the spanning as calculated above (S) */
      if (rowSpan !== _oldRowSpan) {
        onUpdate.call($this, rowSpan)
      }
    }

    // Continue updating on each animation frame
    $this._recalcMasonryItemHeightAnimFrame = undefined
    recalcMasonryItemHeight.call($this, el, onUpdate, rowSpan)
  })
}

/**
 * 
 * @param {DOMel} el - masonry container
 * @param {function} onUpdate(rowGap, rowHeight, el) - callback fired when grid values are changed. The element allows you to add infinity loading etc.
 */
function recalcMasonryDimensions (el, onUpdate, _old) {
  // Only allow a single masonry recalc to be running and make sure it
  // hasn't been unmounted
  // TODO: Should we check if the element has changed and cancel the anim frame instead?
  if (this._recalcMasonryDimensionsAnimFrame || this.$UN) return

  _old = _old || {}
  const $this = this
  this._recalcMasonryDimensionsAnimFrame = requestAnimationFrame(() => {
    let rowGap, rowHeight
    if (el.getBoundingClientRect) {
      rowGap = parseInt(window.getComputedStyle(el).getPropertyValue('grid-row-gap'))
      rowHeight = parseInt(window.getComputedStyle(el).getPropertyValue('grid-auto-rows'));

      if (!isNaN(rowGap) && !isNaN(rowHeight)
        && (rowGap !== _old.rowGap || rowHeight !== _old.rowHeight)) {
          onUpdate.call($this, rowGap, rowHeight, el)
      }
    }

    // Continue updating on each animation frame
    $this._recalcMasonryDimensionsAnimFrame = undefined
    recalcMasonryDimensions.call($this, el, onUpdate, { rowGap, rowHeight })
  })
}

/**
 * 
 * @param {DOMel} el - the masonry container, requires `display: grid;`
 * @param {int} endZone - pixels at bottom of masonry where load more should be triggered
 * @param {function} onUpdate() - callback when we have reached the end zone 
 */
function loadMoreMasonry (el, endZone, onUpdate) {
  if (this._loadMoreMasonryAnimFrame || this.$UN) return

  const $this = this
  this._loadMoreMasonryAnimFrame = requestAnimationFrame(() => {
    // if (!this._queryIsLoading && this._queryLastNrofResults !== 0
    //   && el.scrollHeight <= el.clientHeight) {
    //   // Load some more
    //   this._query(this._lastLoadedPage + 1)
    // }
    const scrollY = window.scrollY
    const scrollBottomY = window.innerHeight + scrollY
    const topY = el.offsetTop
    const height = el.offsetHeight
    const bottomY = topY + height
    if ((scrollBottomY + endZone) > bottomY) {
      onUpdate.call($this)
    }
    
    $this._loadMoreMasonryAnimFrame = undefined
    loadMoreMasonry.call($this, el, endZone, onUpdate)
  })
}

export {
  recalcMasonryItemHeight,
  recalcMasonryDimensions,
  loadMoreMasonry
}