import { createInterfaceClass } from 'component-registry';
var Interface = createInterfaceClass('influence-app-entities');
import { Schema, DateTimeField, i18n } from 'isomorphic-schema';
export var ITimedPublishing = new Interface({
  // Interface for PublishWorkflow
  name: 'ITimedPublishing',
  schema: new Schema('TimedPublishing', {
    publishingStartsAt: new DateTimeField({}),
    publishingEndsAt: new DateTimeField({})
  })
});