import { Image } from 'inferno-bootstrap'
import classnames from 'classnames'

import './Hero.scss'

function HeroBackgroundImage (props) {

  let imgStyle
  if (props.imgOffset) {
    imgStyle = {
      "margin-top": props.imgOffset
    }
  }

  return (
    <div style={{ height: props.height, 'max-height': props.maxHeight || 'auto', overflow: 'hidden' }}>
      {props.imgSrc && <Image className="Hero-BackgroundImage" responsive={props.responsive} src={props.imgSrc} style={imgStyle} />}
    </div>
  )
}

function Hero (props) {

  let imgStyle
  if (!props.responsive) {
    imgStyle = {
      "background-image": `url(${props.imgSrc})`
    }
  }

  return (
    <div className={classnames(props.className, 'HeroContainer')} style={imgStyle}>
      {props.responsive && <HeroBackgroundImage {...props} />}
      {props.children}
    </div>
  )
}

function HeroBody (props) {
  return (
    <div className={classnames(props.className, 'HeroBody')}>
      {props.children}
    </div>
  )
}

export {
  Hero,
  HeroBody
}