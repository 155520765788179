import { Component } from 'inferno'
import { Adapter } from 'component-registry'
import classnames from 'classnames'

import { Link } from 'inferno-router'
import {
  Container, Col, Row,
  Button,
  ButtonGroup,
  Image,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter } from 'inferno-bootstrap'
import { Thumbnail, ResponsiveImage } from '../../widgets/ResponsiveImage'
import { i18n } from '../../i18n'

import { IApiClient, ISessionManager } from '../../interfaces/app'
import { ILiveListItem } from '../../interfaces/presentation'
import { IMedia } from 'influence-app-entities/lib/interfaces/Media'
import './LiveListItem.scss'
import { recalcMasonryItemHeight } from '../common/masonry'
import MultiImageIcon from '../../img/icons/multi-image.js'

class Lightbox extends Component {
  constructor() {
    super(...arguments)

    this.state = {
      posts: []
    }
  }

  async _fetchRelatedPosts() {
    // { "body.cards.type": "Media", "body.cards.id": "5ddd83824f6923faeaf9814f" }

    const query = { 
      query: {
        "body.cards.type": "Media",
        "body.cards.id": this.props.image._id,
        
      }, 
      stateFilter: 'publishWorkflow.published'
    }

    const { data } = await new IApiClient().query({
      URI: `/content/Page`,
      query,
      page: 1,
      limit: 5
    })

    this.setState({
      posts: data
    })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isOpen && !this._fetchDone) {
      this._fetchRelatedPosts()
      this._fetchDone = true
    }
  }

  renderRelatedPosts () {
    let outp = []
    return outp
  }

  render (props, state, context) {
    return (
      <Modal className="MediaLightbox Lightbox" isOpen={props.isOpen} toggle={props.onToggle}>
        <ButtonGroup className="Actions modal-header">
            <Button color="link" className="CloseButton" onClick={props.onToggle}>X Close</Button>
        </ButtonGroup>
        <ModalBody>
          <ResponsiveImage className="lightbox-image" {...props.image} onClick={this.doOpen} />
        </ModalBody>
        <ModalFooter>
          <Container>
              <Row className="col">
                <p>
                  {props.image.shortDescription}
                  {' '}
                  {this.renderRelatedPosts()}
                </p>
              </Row>
            </Container>
        </ModalFooter>
      </Modal>
    )
  }
}

class ListItem extends Component {
  constructor() {
    super(...arguments)

    this.state = {
      lightboxOpen: false
    }

    this.doOpen = this.doOpen.bind(this)
    this.toggle = this.toggle.bind(this)
  }

  doOpen (e) {
    e.preventDefault()

    this.setState({
      lightboxOpen: true
    })
  }

  toggle () {
    this.setState({
      lightboxOpen: !this.state.lightboxOpen
    })
  }

  render (props, state, context) {
    return (
      <Row className="list-item media">
        <Thumbnail {...props.context} onClick={this.doOpen}/>
        <Lightbox image={props.context} isOpen={this.state.lightboxOpen} onToggle={this.toggle} />
      </Row>
    )
  }
}

new Adapter({
  implements: ILiveListItem,
  adapts: IMedia,
  Component: ListItem
})