import { IDeserialize } from 'influence-interfaces/object'
import Client from 'koa-restish/dist/restish-client'

class ApiClient {

  constructor ({ API_URI, forceUpdate, axios }) {
    console.log(`[ApiClient] Connecting to client @ ${API_URI}`)
    this.client = new Client({ API_URI, axios })
    this.forceUpdate = forceUpdate
  }

  _generic (res) {
    if (typeof res.then === 'function') {
      return res.then((res) => {
        return {
          data: new IDeserialize('admin-api').deserialize(res.data)
        }
      })
    } else {
      return Promise.resolve({
        data: new IDeserialize('admin-api').deserialize(res.data)
      })
    }
  }

  hydrate () {
    return this.client.hydrate.apply(this.client, arguments)
  }

  rehydrate () {
    this.client.rehydrate.apply(this.client, arguments)
  }

  invalidateCache (pathExp) {
    this.client.invalidateCache(pathExp)
    this.forceUpdate()
  }

  query () {
    const tmp = this.client.query.apply(this.client, arguments)
    return this._generic(tmp)
  }

  create () {
    const tmp = this.client.create.apply(this.client, arguments)
    return this._generic(tmp)
  }

  update () {
    const tmp = this.client.update.apply(this.client, arguments)
    return this._generic(tmp)
  }

  delete () {
    const tmp = this.client.delete.apply(this.client, arguments)
    return this._generic(tmp)
  }
}

export default ApiClient