import { Component } from 'inferno'
import { safeGet } from 'safe-utils'
import { i18n } from '../i18n'

import { IMobileDocCardButton } from '../interfaces/formlib'

import {
  Col, Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  DropdownMenu
} from 'inferno-bootstrap'

/* These are required for card buttons to work properly */
import { ImageButton } from './cards/MediaCard'
import { VideoButton } from './cards/VideoCard'

import './CardPanel.scss'

function CardButton ({ utilName, ...props }) {
  const { Component } = new IMobileDocCardButton(utilName)
  return (
    <Component {...props} />
  )
}

class CardPanel extends Component {

  render() {
    const {
      range,
      onInsert,
      isOpen,
      toggle
    } = this.props

    const buttonProps = {
      range,
      toggle,
      onInsert,
      editor: this.context.editor
    }

    return (
      <Modal className="CardPanel" isOpen={isOpen} toggle={toggle}>
        <ModalHeader>CARDS</ModalHeader>
        <ModalBody>
          <div className="CardPanel-Group">
            <h3>Content</h3>
            <p>Use content cards to enrich your posts with images and videos. The cards will be visible in your image feed.</p>
            <CardButton utilName="MediaCard" {...buttonProps} />
            <CardButton utilName="VideoCard" {...buttonProps} />
          </div>
        </ModalBody>
        <ModalFooter>
          <Row className="col"><p>Cards are structured content to enrich your posts. Place your text editing cursor where you want your card to be inserted and choose one from the selection above.</p></Row>
        </ModalFooter>
      </Modal>
    )
  }
}

export {
  CardPanel
}