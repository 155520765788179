import { Component } from 'inferno'
import { Container } from 'inferno-bootstrap'
import { Animated } from 'inferno-animation'
import { IEditForm } from '../../interfaces/presentation'
import { IApiClient } from '../../interfaces/app'
import './Create.scss'

export default class Page extends Component {

    static async fetchData ({registry, match, location}) {
        const { data } = await new IApiClient({ registry }).query({
            URI: `/content/${match.params.type}/${match.params.id}`
        })

        return data
    }
    
    componentDidMount() {
      window.scroll(0, 0)
    }

    render () {
        const data = this.props.fetchData

        let EditForm = null
        if (data) {
            EditForm = new IEditForm(data).Component
        }

        if (!EditForm) return null

        let showTitle
        switch (data._type) {
          case 'Page':
          case 'LicenseAgreement':
          case 'Guide':
            showTitle = false;
            break;
          default:
            showTitle = true;
        }

        return (
          <Animated key="me" className="EditPage" prefix="PageNav">
            <Container fluid>
                {showTitle && <h1>Edit {data._type}</h1>}
                <EditForm value={data} {...this.props} />
            </Container>
          </Animated>
        )
    }
}

