import { Adapter } from 'component-registry'
import { i18n } from '../../i18n'

import {
  Card,
  CardBody,
  CardFooter,
  CardImg,
  Row,
  Col
} from 'inferno-bootstrap'
import { ResponsiveImage } from '../../widgets/ResponsiveImage'

import { FormRows } from 'inferno-formlib'

import { IMediaSelectDetails } from '../../interfaces/presentation'
import { IMedia } from 'influence-app-entities/lib/interfaces/Media'

// <Button onClick={linkEvent(props, onSelect)}>Select</Button>
function MediaSelectDetails (props) {
  return (
    <Card className="MediaSelectDetails">
      <ResponsiveImage className="card-img-top" width="100%" {...props.context} />
      <CardBody>
        <FormRows
          schema={props.context._implements[0].schema}
          value={props.context}
          selectFields="shortDescription,fileType"
          onChange={() => null} />
      </CardBody>
      {props.children && <CardFooter>
        {props.children}
      </CardFooter>}
    </Card>
  )
}

new Adapter({
  implements: IMediaSelectDetails,
  adapts: IMedia,
  Component: MediaSelectDetails
})
