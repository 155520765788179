'use strict'
import { createInterfaceClass, createObjectPrototype } from 'component-registry'
import { TextField, i18n } from 'isomorphic-schema'
const Interface = createInterfaceClass('AdminFront')

export const ISearchField = new Interface({
  name: 'ISearchField'
})

ISearchField.prototype.validate = function () {}
ISearchField.prototype.fromString = function () {}
ISearchField.prototype.toFormattedString = function () {}

export const SearchField = createObjectPrototype({
    implements: [ISearchField],
    extends: [TextField],
})
