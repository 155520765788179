export function renderTimedPublishingString(startsAt, endsAt) {
  const outp = []
  if (startsAt instanceof Date && !isNaN(startsAt.getTime())) {
    const tmp = startsAt.toLocaleString()
    outp.push(`${tmp.substr(0, 10)} ${tmp.substr(11, 5)} `)
  }
  if (endsAt instanceof Date && !isNaN(endsAt.getTime())) {
    const tmp = endsAt.toLocaleString()
    outp.push(` ${tmp.substr(0, 10)} ${tmp.substr(11, 5)}`)
  }

  if (outp[0] || outp[1]) {
    return `${outp[0] || ''}-${outp[1] || ''}`
  }
}

export function hasTimedPublishing(obj) {
  return (obj.publishingStartsAt instanceof Date) || (obj.publishingEndsAt instanceof Date)
}