import { i18n } from '../i18n'
import { matchPath } from 'koa-restish/dist/matchPath'
import { IApiClient, ISessionManager } from '../interfaces/app'

async function doAllAsyncBefore ({
  registry,
  pathname,
  location,
  routes,
  router,
  matchCallback
}) {
  // const pathname = renderProps.props.location
  // const { routerStore } = renderProps.props.children.props
  // const routes = renderProps.props.children.props.children.props.children.props.children
  let exactMatchFound = false
  const matches = routes.map((route) => {
    const {
      path,
      exact = false, // Prefix matches are required to stack layers
      sensitive,
      exclude 
    } = route.props
    const match = matchPath(pathname, {
      path,
      exact,
      sensitive,
      exclude 
    })
    // Don't match 
    if (exactMatchFound) return null

    if (route.props.exact) {
      if (match && match.isExact) {
        exactMatchFound = true
      }
      else {
        return null
      }
    }

    return {
      match,
      asyncBefore: route.props.asyncBefore,
      public: route.props.public,
      section: route.props.section
    }
  }).filter((route) => route && route.match) //  && route.match.isExact)

  // Get role manager
  console.log('Get role manager...')
  // TODO: We need to call the match callback should once since we might need to resolve multiple matches
  if (typeof matchCallback === 'function') {
    await matchCallback(matches)
  }
  console.log('...done!')

  console.log(`Find all asyncbfore for ${matches.length} matches`)
  const outp = await Promise.all(matches.map(matchedRoute => {
    const { public: _public, section } = matchedRoute
    if (matchedRoute.asyncBefore) {
      return matchedRoute.asyncBefore({
        registry,
        match: matchedRoute.match,
        location,
        router
      })
        .then((res) => {
          const match = Object.assign({
            public: _public,
            section
          }, matchedRoute.match)
          
          // const cacheKey = matchedRoute.match.path
          return { res, match }
        })
    }
    else {
      const match = Object.assign({
        public: _public,
        section
      }, matchedRoute.match)
      return Promise.resolve({ match })
    }
  }))
  console.log(`All async before complete`)

  return outp
}

export default doAllAsyncBefore
