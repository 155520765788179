import { i18n } from '../i18n'
import { Adapter } from 'component-registry'

import {
  Nav,
  NavLink
} from 'inferno-bootstrap'

import { IGalleryLayoutField } from 'influence-app-entities/lib/interfaces/fields'

import galleryOne from '../img/icons/gallery_one'
import galleryTwo from '../img/icons/gallery_two'
import galleryOneTwo from '../img/icons/gallery_one_two'
import galleryOneThree from '../img/icons/gallery_one_three'
import galleryOneTwoHorizontal from '../img/icons/gallery_one_two_horizontal'
import galleryOneFourHorizontal from '../img/icons/gallery_one_four_horizontal'
import './GalleryLayoutField.scss'
import { interfaces } from 'inferno-formlib'
const { IInputFieldWidget } = interfaces

const icons = {
  one: galleryOne,
  two: galleryTwo,
  one_two: galleryOneTwo,
  one_three: galleryOneThree,
  one_two_horizontal: galleryOneTwoHorizontal,
  one_four_horizontal: galleryOneFourHorizontal
}

function renderIcon (name) {
  const Icon = icons[name]
  return <Icon />
}

export default function GalleryLayoutFieldWidget (props) {
  const field = props.adapter.context

  return (
      <Nav pills className="GalleryLayoutField">
          {field.options.map((opt) => <NavLink active={props.value === opt.name} onClick={(e) => {
            e.preventDefault()
            props.onChange(props.propName, opt.name)
          }}>{renderIcon(opt.name)}</NavLink>) }
      </Nav>
  )

}

new Adapter({
  implements: IInputFieldWidget,
  adapts: IGalleryLayoutField,
  Component: GalleryLayoutFieldWidget
})

export {
  IGalleryLayoutField, 
  GalleryLayoutFieldWidget
}
