import {
  MarkupButton,
  SectionButton,
  LinkButton,
  SectionSelect
} from 'inferno-mobiledoc-editor'
import { i18n } from '../../i18n'
import { ButtonGroup, Button } from 'inferno-bootstrap'
import { ImageButton } from '../../widgets/cards/MediaCard'

const Toolbar = (props) =>
  <ButtonGroup {...props}>
    <MarkupButton className="btn btn-secondary" tag='strong' />
    <MarkupButton className="btn btn-secondary" tag='em' />
    <LinkButton className="btn btn-secondary" />
    <SectionSelect tags={["h1", "h2", "h3", "p"]} title="Other..." />
    <SectionButton className="btn btn-secondary" tag='blockquote' />
    <SectionButton className="btn btn-secondary" tag='ul'>UL</SectionButton>
    <SectionButton className="btn btn-secondary" tag='ol'>OL</SectionButton>
    <ImageButton />
  </ButtonGroup>

export default Toolbar