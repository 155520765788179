import { Utility } from 'component-registry'
import { Component, Fragment } from 'inferno'
import classnames from 'classnames'
import { CardButtonBase, getPostId, getPostSlug, insertCard, maxImages } from './common'
import { i18n } from '../../i18n'
import { IMediaCard } from 'influence-app-entities/lib/interfaces/cards/MediaCard';
import { IMedia } from 'influence-app-entities/lib/interfaces/Media';
import { IImage } from 'influence-app-entities/lib/interfaces/Image';
import { ILightbox } from '../../interfaces/presentation'

import 'influence-app-entities/lib/cards/MediaCard';
import { IObjectPrototypeFactory } from 'influence-interfaces/object'
import { IApiClient, ISessionManager } from '../../interfaces/app'
import '../../formlib/ImageField'
import '../../formlib/GalleryLayoutField'
import IconMediaCard from '../../img/icons/image'
import IconArrow from '../../img/icons/arrow'


import { Link } from 'inferno-router'
import {
  Container, Row,
  Form,
  Button,
  ButtonGroup,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Media,
  MediaBody,
  Image } from 'inferno-bootstrap'
import { FormRows } from 'inferno-formlib'
import { ResponsiveImage } from '../ResponsiveImage'

import { IMobileDocCardUtil, IMobileDocCardButton } from '../../interfaces/formlib'
import './common.scss'
import './MediaCard.scss'

function Icon(props) {
  return <img {...props} />
}

/**
 * Component-based cards are rendered with these props:
 *
 * - `payload` The payload for this card. Note: the payload object is
 *    disconnected from the card's payload in the serialized mobiledoc.
 *    To update the mobiledoc payload, use the `save` callback.
 * - `edit` A callback for toggling this card into edit mode (no-op
 *    if the card is already in edit mode).
 * - `remove` A callback for removing this card.
 * - `save` A callback accepting new payload for the card, then
 *    saving that payload and toggling this card into display mode.
 *    Can optionally be passed an extra `false` argument to avoid
 *    toggling to display mode.
 * - `cancel` A callback for toggling this card to display mode
 *    without saving (a no-op if the card is already in display mode).
 * - `name` The name of this card.
 * - `postModel` A reference to this card's model in the editor's
 *    abstract tree. This may be necessary to do programmatic editing.
 * - `isEditing` A bool indicating if the card is in Edit mode or not.
 * - `isInEditor` A bool indicating if the card is displayed inside an
 *    editor interface or not.
 */

class ImageRender extends Component {

  constructor (props) {
    super(...arguments)

    this.state = {
      lightboxOpen: false
    }

    this.doOpen = this.doOpen.bind(this)
    this.toggle = this.toggle.bind(this)
  }

  doOpen (e) {
    e.preventDefault()

    if (this.props.isInEditor) {
      this.props.edit(e)
    }
    else {
      this.setState({
        lightboxOpen: true
      })
    }

  }

  toggle () {
    this.setState({
      lightboxOpen: !this.state.lightboxOpen
    })
  }

  renderImages({ payload }) {
    if (payload.multiple) {
      return (
        <div className={classnames('Gallery', payload.layoutType, { 'cover': payload.allowCrop })}>
          <ResponsiveImage {...payload} limit={maxImages[payload.layoutType] || 3} onClick={this.doOpen} />
        </div>
      )
    }
    else {
      return <ResponsiveImage {...payload} onClick={this.doOpen} />
    }
  }

  render ({ isInEditor, payload, edit }) {
    const Lightbox = new ILightbox('MediaCard').Component

    return (
      <Media vertical className="MediaCard">
        {this.renderImages({ isInEditor, payload, edit })}
        <MediaBody>
          <small>{payload.shortDescription || payload.caption}</small>
        </MediaBody>
        {isInEditor && <Button className="CardEditButton" color="link" onClick={edit} style={{color: 'white'}}>Edit</Button>}
        <Lightbox image={payload} isOpen={this.state.lightboxOpen} onToggle={this.toggle} />
      </Media>
    )
  }
}


class ImageEdit extends Component {

  constructor ({ payload }, context) {
    super(...arguments)

    this.didChange = this.didChange.bind(this)
    this.doSubmit = this.doSubmit.bind(this)
    this.doCancel = this.doCancel.bind(this)

    this.state = {
      validationErrors: undefined,
      value: payload || new IObjectPrototypeFactory('MediaCard').getObject({}),
      isOpen: true
    }

    // Prevents animations on mounting of form
    this.isMounted = false
  }

  componentDidMount () {
    this.isMounted = true
  }
  
  componentWillUnmount () {
    console.log('leaving world')
  }

  didChange (propName, value) {
    const newValue = Object.assign({}, this.state.value)
    newValue[propName] = value
    
    this.setState({
      value: newValue
    })
  }

  doCancel () {
    // TODO: Check if dirty
    this.setState({ isOpen: false })
    setTimeout(() => this.props.cancel(), 300)
  }

  doSubmit (e) {
    e.preventDefault()
    const validationErrors = IMediaCard.schema.validate(this.state.value)
    this.setState({
      validationErrors
    })

    if (validationErrors === undefined) {
      // TODO: If using placeholder, update MediaCard with proper Image (should be used by Unsplash)
      
      // Submit changes
      this.setState({ isOpen: false })
      setTimeout(() => this.props.save(this.state.value), 300)
    } else {
      // TODO: Show error message
    }
  }

  render () {
    return (
      <Fragment>
        {this.props.children /* This is the actual widget so it is displayed during editing*/}

        <Modal className="MediaCardModal EditModal" isOpen={this.state.isOpen} toggle={this.doCancel}>
          <Form onSubmit={this.doSubmit}>
            <ModalHeader>
              Edit Media Card
            </ModalHeader>
            <ModalBody>
              <FormRows
                value={this.state.value}
                schema={IMediaCard.schema}
                omitFields="fileType"
                validationErrors={this.state.validationErrors}
                isMounted={this.isMounted}

                onChange={this.didChange} />
            </ModalBody>
            <ModalFooter>
              <Button onClick={this.doCancel} color="link">Cancel</Button>
              <Button type="submit" color="success">Save</Button>
            </ModalFooter>
          </Form>
              
        </Modal>

      </Fragment>
    )
  }
}

new Utility({
  implements: IMobileDocCardUtil,
  name: 'MediaCard',
  type: 'dom',
  RenderComponent: ImageRender,
  EditComponent: ImageEdit
})

new Utility({
  implements: IMobileDocCardUtil,
  name: 'MediaCard',
  type: 'dom',
  RenderComponent: ImageRender,
  EditComponent: ImageEdit
})

export class ImageButton extends CardButtonBase {
  name = 'MediaCard'

  render () {
    return (
      <Media className="mt-1" onClick={this.doShowModal}>
        {this.state.showModal && <ImageEdit save={this.doSubmit} cancel={this.doHideModal} />}
        <IconMediaCard />
        <MediaBody>
          <h4>Image and Gallery <IconArrow className="arrow" /></h4>
          <p>An image card allows you to add a single image or a gallery to your post.</p>
        </MediaBody>
      </Media>
    )
  }
}

new Utility({
  implements: IMobileDocCardButton,
  name: 'MediaCard',
  Component: ImageButton
})